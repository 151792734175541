import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Card, CardBody,  Col,  } from 'reactstrap';

import Spinners from 'Components/Common/Spinner';
import SmartTable from 'Components/Common/SmartTable';


export const ProspectList = ({prospects, isLoading}: any) => {

    const [objects, setObjects] = useState<any>();


    useEffect(() => {
      setObjects(prospects);
    }, [prospects]);

  
 
    const columns = useMemo(
        () => [
          {
            Header: "First Name",
            accessor: "firstName",
            Filter: false,
            filterable: true,
          },
          {
            Header: "Last Name",
            accessor: "lastName",
            Filter: false,
            filterable: true,
          },
          {
            Header: "Spouse",
            accessor: "spouse",
            Filter: false,
            filterable: true,
          },
         
          {
            Header: "Meeting Date",
            accessor: "meetingDate",
            Filter: false,
            Cell: (props) => {
              return props.row.original.meetingDate ? props.row.original.meetingDate : <Badge color="danger bg-opacity-50 px-2 fs-6">Pending</Badge>
            }
          },
          {
            Header: "MeetingStatus",
            accessor: "meeting.name",
            Filter: false,
            filterable: true,
          },
          
          {
            Header: "Advisor",
            accessor: "advisor.fullName",
            Filter: false,
            filterable: true,
            Cell: (cellProps: any) => {
              if (cellProps?.row.original.advisor.fullName !== "null null")
                return <span>{cellProps?.row.original.advisor.fullName}</span>;
              else
                return <span>{cellProps?.row.original.advisor.username}</span>;
            },
          },
        ],
        []
      );
    return (
        <React.Fragment>
            <Col xl={12}>
            {isLoading ? (
                <Spinners  />
              ) : (
                <Card>
                  
                  <CardBody>
                    <SmartTable
                      columns={columns}
                      data={objects || []}
                      customPageSize={8}
                      isGlobalFilter={true}
                      isCustomFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    ></SmartTable>
                  </CardBody>
                </Card>
              )}
            </Col>
            
        </React.Fragment>
    );
}
