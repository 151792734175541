import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

//import components
import Breadcrumbs from "Components/Common/Breadcrumb";


import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
import ReferralTypeForm from "./ReferralType";
import { useFetchReferralTypes, useDisableReferralType, useEnableReferralType } from "api";

function ReferralTypeList() {
  //meta title
  document.title = "Referral Type List | Ridgeline Metrics Dashboard ";
  const {data: objects, isLoading, refetch} = useFetchReferralTypes();

  const disableReferralTypeMutation = useDisableReferralType();
  const enableReferralTypeMutation = useEnableReferralType();

  const [modal, setModal] = useState<boolean>(false);
  const [disableModal, setDisableModal] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(null);
  const [typeId, setTypeId] = useState<any>(null);
  const [isDisable, setIsDisable] = useState<any>(true);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrent(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const toggleDisable = useCallback(() => {
    if (disableModal) {
      setDisableModal(false);
    } else {
      setDisableModal(true);
    }
  }, [disableModal]);

  const toggleEnable = useCallback(() => {
    if (disableModal) {
      setDisableModal(false);
    } else {
      setDisableModal(true);
    }
  }, [disableModal]);

  const handleEdit = useCallback(
    (arg: any) => {
      const job = arg;
      setCurrent(job);
      toggle();
    },
    [toggle]
  );

  const handleDisable = () => {
    if (typeId) {
      disableReferralTypeMutation.mutate({
        "id": typeId,
      })
    }
    
    toggleDisable();
  };

  const handleEnable = () => {
    if (typeId) {
      enableReferralTypeMutation.mutate({
        "id": typeId,
      })
    }
    
    toggleDisable();
  };

  const handleCreate = () => {
    setCurrent(null);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Firm",
        accessor: "firm.name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          if (cellProps.row.original.isActive) {
            return (
              <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const row = cellProps.row.original;
                      handleEdit(row);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      setTypeId(cellProps.row.original.id);
                      setIsDisable(true);
                      toggleDisable();
                    }}
                  >
                    <i className="mdi mdi-close-circle-outline" id={`disabletooltip-${cellProps.row.original.id}`} />
                    <UncontrolledTooltip placement="top" target={`disabletooltip-${cellProps.row.original.id}`}>
                      Disable
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            );
          } else {
            return (
              <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const row = cellProps.row.original;
                      handleEdit(row);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-info"
                      onClick={() => {
                        setTypeId(cellProps.row.original.id);
                        setIsDisable(false);
                        toggleEnable();
                      }}
                    >
                      <i className="mdi mdi-check-circle-outline" id={`enabletooltip-${cellProps.row.original.id}`} />
                      <UncontrolledTooltip placement="top" target={`enabletooltip-${cellProps.row.original.id}`}>
                        Enable
                      </UncontrolledTooltip>
                    </Link>
                  </li>
              </ul>
            ); 
          }
        },
      },
    ],
    [handleEdit, toggleEnable, toggleDisable]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Referral Type List" />
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <Spinners />
              ) : (
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <CardTitle tag="h5" className="mb-0 flex-grow-1">
                        Referral Type List
                      </CardTitle>
                      <div className="flex-shrink-0">
                        <Link
                          to="#"
                          onClick={() => handleCreate()}
                          className="btn btn-success me-1"
                        >
                          {" "}
                          <i className="bx bx-plus-circle"></i> Create
                        </Link>
                        <Link to="#" onClick={() => refetch()} className="btn btn-light me-1">
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <SmartTable
                      columns={columns}
                      data={objects || []}
                      customPageSize={8}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isCustomFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      handleCreate={handleCreate}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    ></SmartTable>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!current ? "Edit Referral Type" : "Add Referral Type"}
            </ModalHeader>
            <ModalBody>
            <ReferralTypeForm current={current} toggle={toggle} />
            </ModalBody>
          </Modal>
          <Modal isOpen={disableModal} toggle={toggleDisable}>
            <ModalHeader toggle={toggleDisable} tag="h4">
              Are you sure you want to enable/disable this Type?
            </ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-evenly">
                <Button color="success" className="save-user" onClick={isDisable ? handleDisable : handleEnable}>
                  <i className="bx bx-save"></i>{" "}
                  Yes
                </Button>
                <Button color="danger" className="save-user" onClick={toggleDisable}>
                  <i className="bx bx-window-close"></i>{" "}
                  No
                </Button>
              </div>
            </ModalBody>
        </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReferralTypeList;
