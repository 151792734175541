import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Col, Row, Button } from "reactstrap";
import { SmartInput } from "Components/Common/SmartInput";
import { SmartCurrency } from "Components/Common/SmartCurrency";
import { SmartCheck } from "Components/Common/SmartCheck";
import { SmartDatePicker } from "Components/Common/SmartDatePicker";
import { useFetchClients, useCreateClient } from "api";

interface Client {
  id: number;
  fullName: string;
}

function ClientMeetingForm({ toggle, advisor }) {
  const createClientMutation = useCreateClient()
  const {data:fetchedClients, isLoading: clients_loading} = useFetchClients()
  const [clients, setClients] = useState<Client[]>([]);

  const initialValues = {
    clientName: "",
    meetingDate: null,
    assets: 0,
    prospect: null,
    delivery: false,
  };

  const validationSchema = Yup.object({
    clientName: Yup.number().required("Client Name is Required"),
    meetingDate: Yup.date().nullable().required("Revenue Date Required"),
    assets: Yup.number().when("clientName", {
      is: (value) => {
        return value === "0";
      },
      then: Yup.number().required("New Assets is Required"),
      otherwise: Yup.number().notRequired(),
    }),
  });

  useEffect(() => {
    if (fetchedClients) {
      const advisor_clients = fetchedClients?.filter((client) => client.advisor.id == advisor);
      setClients([{id: 0, fullName: "Current Client"}, ...advisor_clients]);
    }
  }, [fetchedClients, advisor]);

  const handleSubmit = (values) => {
    const clientInfo = fetchedClients.find(client => client.id === Number(values.clientName));
    values.prospect = clientInfo;

    createClientMutation.mutate(values, {
      onSuccess: () => {
        toggle();
      },
    });
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <SmartInput
              isLoading={clients_loading}
              as="select"
              name="clientName"
              label="Client Name"
              value={values.clientName}
              onChange={(e) => {
                const { value } = e.target;
                setFieldValue('clientName', value);
              }}
            >
              <option value="">Select Client Name</option>
              {clients?.map((client) => (
                <option key={client.id} value={client.id}>{client.fullName}</option>
              ))}
            </SmartInput>
            <SmartDatePicker name="meetingDate" label="Date Paid" />
            {values.clientName === "0" && <SmartCurrency name="assets" label="New Assets" />}
            {values.clientName !== "0" && <SmartCheck name="delivery" label="Wrap Up/Delivery Meeting" />}
            <Row>
              <Col>
                <div className="text-end">
                  <Button type="submit" color="success" className="save-user">
                    <i className="bx bx-save"></i>{" "}
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default ClientMeetingForm;