import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

import {
  Col,
  Row,
  Card,
  CardBody
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
// import ReferralForm from "./ReferralForm";
import { useFetchAdvisorProspectPipelineMetrics, useFetchAdvisorProspects, useFetchReferrals, currencyFormat } from "api";
import PageHeader from "Components/Common/PageHeader";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";

function AdvisorProspectLists() {
  //meta title
  document.title = "Advisor Prospect Lists | Ridgeline Metrics Dashboard ";

  const navigate = useNavigate();

  const query = useFetchReferrals()

  const { data: metrics } = useFetchAdvisorProspectPipelineMetrics();
  const { data: prospects } = useFetchAdvisorProspects();
  const [modal, setModal] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(null);
  const [activeProspects, setActiveProspects] = useState<any>([]);

  const notificationData = [
    {
      title: 'Total First Meetings Requested',
      value: metrics?.firstSet || 0
    },
    {
      title: 'Total First Meetings Kept',
      value: metrics?.firstKept || 0
    },
    {
      title: 'Total Active Prospects',
      value: activeProspects?.length || 0
    },
    {
      title: 'Clients Made',
      value: metrics?.clientMade || 0
    }
  ];

  useEffect(() => {
    if (prospects) {
      const activeProspects = prospects.filter((prospect) => prospect.isActive === true);
      
      setActiveProspects(activeProspects);
    }
  }, [prospects]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrent(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // const handleEdit = useCallback(
  //   (arg: any) => {
  //     const job = arg;
  //     setCurrent(job);


  //     toggle();
  //   },
  //   [toggle]
  // );

  // const handleCreate = () => {
  //   setCurrent(null);
  //   toggle();
  // };

  const handleProspectDetail = useCallback(
      (prospect: any) => {
          navigate(`/advisor/prospect/${prospect.id}/detail`);
      },
      [navigate]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Last Name",
        accessor: "lastName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
            return (
                <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={(e) => {
                        e.preventDefault();
                        const row = cellProps.row.original;
                        handleProspectDetail(row);
                    }}
                >
                    {cellProps.row.original.lastName}
                </Link>
            )
          }
      },
      {
        Header: "Source",
        accessor: "event.marketingSource.name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Type",
        accessor: "event.eventType.name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Advisor",
        accessor: "advisor.fullName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          if (cellProps?.row.original.advisor.fullName !== "null null")
            return <span>{cellProps?.row.original.advisor.fullName}</span>;
          else
            return <span>{cellProps?.row.original.advisor.username}</span>;
        },
      },
      {
        Header: "Investible Assets",
        accessor: "newMoney",
        Filter: false,
        Cell: ({ value }) => {
          return <span>{currencyFormat(value)}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: false,
      },
    ],
    []
  );

  function setActive(e) {
    e.target.checked ? setActiveProspects(prospects.filter((prospect) => prospect.isActive === true)) : setActiveProspects(prospects)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <PageHeader title="Advisor Prospects" />
          <Row>
            {
              notificationData.map((data, index) => {
                return <NotificationMiniCard title={data.title} content={data.value} key={index} size={3} />
              })
            }
          </Row>
          <Row>
            <Col lg={12}>
              {query.isLoading ? (
                <Spinners />
              ) : (
                <Card>
                  {/* <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <CardTitle tag="h5" className="mb-0 flex-grow-1">
                        Referral List
                      </CardTitle>
                      <div className="flex-shrink-0">
                        <Link
                          to="#"
                          onClick={() => handleCreate()}
                          className="btn btn-success me-1"
                        >
                          {" "}
                          <i className="bx bx-plus-circle"></i> Create
                        </Link>
                        <Link to="#" onClick={() => query.refetch()} className="btn btn-light me-1">
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <SmartTable
                      columns={columns}
                      data={query.data}
                      customPageSize={8}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isCustomFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      handleCreate={handleCreate}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    ></SmartTable>
                  </CardBody> */}

                  <Row>
                    <Card>
                      <CardBody>
                      <div>
                        <h2 style={{marginRight: "50px"}}>All Prospect</h2>
                        <div style={{fontSize: "1rem", padding: "0.5%"}}>
                          <label>Active</label>
                          <input type="checkbox" style={{marginLeft: "10px"}} onChange={setActive}/>
                        </div>
                      </div>
                        <SmartTable
                          columns={columns}
                          data={activeProspects || []}
                          customPageSize={30}
                          isGlobalFilter={true}
                          isPagination={true}
                          isShowingPageLength={true}
                          customPageSizeOption={true}
                          tableClass="table-bordered align-middle nowrap mt-2"
                          paginationDiv="col-12"
                          pagination="pagination justify-content-end pagination-rounded"
                        ></SmartTable>
                      </CardBody>
                    </Card>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
          {/* <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!current ? "Edit Marketing Referral" : "Add Marketing Referral"}
            </ModalHeader>
            <ModalBody>
            <ReferralForm current={current} />
            </ModalBody>
          </Modal> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdvisorProspectLists;
