import "toastr/build/toastr.min.css";
import toastr from "toastr";

function showToast(message, title, toastType) {


    //position class
    let positionClass = "toast-top-right";
  

    toastr.options = {
      positionClass: positionClass,
      timeOut: 3000,
      extendedTimeOut: 3000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: "fadeIn",
      hideMethod: 'fadeOut',
      showDuration: 800,
      hideDuration: 1000
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  export const successToast = (message, title= 'Success') => {
    showToast(message, title, 'success')
  }

  export const errorToast = (message, title= 'Error') => {
    showToast(message, title, 'error')
  }

  export const warnToast = (message, title= 'Warning') => {
    showToast(message, title, 'warning')
  }

  export default showToast;