import NotificationMiniCard from "Components/Common/NotificationMiniCard";
import PageHeader from "Components/Common/PageHeader";
import SmartTable from "Components/Common/SmartTable";
import { useFetchWeeklyRevenue, useFetchYearlyRevenue, useFetchAdvisors, useFetchAumsByWeek, currencyFormat } from "api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import RevenueForm from "./RevenueForm";
import AumForm from "../Aum/AumForm";
import { format, startOfWeek, endOfWeek, subWeeks, addWeeks, isAfter, endOfDay } from "date-fns";

const RevenueWeeklyReport = () => {

    // Get current date
    const [currentDate, setCurrentDate] = useState(new Date());

    // Find the start and end of the current week
    const startDate = format(startOfWeek(currentDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'); // 1 means Monday
    const endDate = format(endOfWeek(currentDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');

    const { data: revenueObjects, refetch } = useFetchWeeklyRevenue(format(currentDate, 'yyyy-MM-dd'));
    const { data: revenueYear } = useFetchYearlyRevenue(format(currentDate, 'yyyy-MM-dd'));
    const { data: advisors } = useFetchAdvisors();
    const { data: aums } = useFetchAumsByWeek(format(currentDate, 'yyyy-MM-dd'));

    const [modal, setModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [current, setCurrent] = useState<any>(null);

    const [modalAum, setModalAum] = useState<boolean>(false);
    const [currentAum, setCurrentAum] = useState<any>(null);

    const [aumRevenueSum, setAumRevenueSum] = useState(0);
    const [aumRevenueList, setAumRevenueList] = useState();

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setCurrent(null);
        } else {
            setModal(true);
        }
    }, [modal]);

    const toggleAum = useCallback(() => {
        if (modalAum) {
            setModalAum(false);
            setCurrentAum(null);
        } else {
            setModalAum(true);
        }
    }, [modalAum]);

    const movePrevWeek = () => {
        setCurrentDate(prevDate => subWeeks(prevDate, 1));
    };
    
    const moveNextWeek = () => {
        setCurrentDate(prevDate => {
            const nextWeekDate = addWeeks(prevDate, 1);
            const today = endOfDay(new Date());
        
            if (isAfter(nextWeekDate, today)) return currentDate;
            else return nextWeekDate;
        });
    };

    const moveCurrWeek = () => {
        setCurrentDate(new Date());
    };

    const handleCreate = () => {
        setIsEdit(false);
        setCurrent(null);
        toggle();
    };

    const handleCreateAum = () => {
        setIsEdit(false);
        setCurrentAum(null);
        toggleAum();
    };

    const handleEdit = useCallback(
        (arg: any) => {
            const job = arg;

            if (!job.prospect) {
                if ( job.clientName === "Current Client" )
                    job.prospect = { id: 0, fullName: "Current Client" }
                else if ( job.clientName === "Other Source" )
            	    job.prospect = { id: -1, fullName: "Other Source" };
	        }
            
            setCurrent(job);

            setIsEdit(true);

            toggle();
        },
        [toggle]
    );

    useEffect(() => {
        let aumSum = 0;
        let aumsByAdvisorId = {};
        
        if (aums) {
            aumsByAdvisorId = aums?.reduce((acc, aum) => {
                    aumSum += aum.balance; // Sum AUM revenues
                    acc[aum.advisor.id] = (acc[aum.advisor.id] || 0) + aum.balance; // Sum by advisor
                return acc;
            }, {});
    
            setAumRevenueSum(aumSum); // Set total AUM revenue sum
        }

        if (advisors) {
            const aumSumList = advisors.map(advisor => ({
                fullName: advisor.fullName,
                username: advisor.username,
                balance: aumsByAdvisorId[advisor.id] || 0,
            }));    
            setAumRevenueList(aumSumList); // Set AUM revenue list by advisor
        }
    }, [currentDate, aums, advisors]);

    const notificationData = [
        {
            title: "Week of",
            value:
                startDate.replaceAll("-", "/") +
                " - " +
                endDate.replaceAll("-", "/"),
        },
        {
            title: "AUM Balance",
            value: currencyFormat(aumRevenueSum),
        },
        {
            title: "Total Revenue YTD",
            value: currencyFormat(revenueYear?.Total)
        },
        {
            title: "Total Annuity Premium YTD",
            value: currencyFormat(revenueYear?.Annuity)
        },
    ];

    const aumColumns = useMemo(
        () => [
            {
                Header: "Advisor",
                accessor: "fullName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    if (cellProps?.row.original.fullName !== "null null")
                      return <span>{cellProps?.row.original.fullName}</span>;
                    else
                      return <span>{cellProps?.row.original.username}</span>;
                },
            },
            {
                Header: "Balance",
                accessor: "balance",
                Filter: false,
                Cell: ({ value }) => {            
                    return <span>{currencyFormat(value)}</span>;
                },
            },
        ],
        []
    );

    const columns = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "revenueDate",
                Filter: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    return (
                      <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={(e) => {
                          e.preventDefault();
                          const row = cellProps.row.original;
                          handleEdit(row);
                        }}
                      >
                        {cellProps.row.original.revenueDate}
                      </Link>
                    )
                }
            },
            // {
            //     Header: "Last Name",
            //     accessor: "lastName",
            //     Filter: false,
            //     filterable: true,
            // },
            {
                Header: "Type",
                accessor: "revenueType",
                Filter: false,
                filterable: true,
            },
            {
                Header: "Client Name",
                accessor: "clientName",
                Filter: false,
                filterable: true,
            },
            {
                Header: "Advisor",
                accessor: "advisor.fullName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    if (cellProps?.row.original.advisor?.fullName !== "null null")
                      return <span>{cellProps?.row.original.advisor?.fullName}</span>;
                    else
                      return <span>{cellProps?.row.original.advisor?.username}</span>;
                },
            },
            {
                Header: "Premium",
                accessor: "premium",
                Filter: false,
                Cell: (cellProps: any) => {
                    if (cellProps.row.original.revenueType !== "AUM")
                        return <span>{currencyFormat(cellProps.row.original.premium)}</span>;
                    else
                        return "";
                }
            },
            {
                Header: "Revenue",
                accessor: "revenue",
                Filter: false,
                Cell: ({ value }) => {            
                    return <span>{currencyFormat(value)}</span>;
                },
            },
            {
                Header: "Company",
                accessor: "company",
                Filter: false,
            },
            {
                Header: "Product",
                accessor: "product",
                Filter: false,
            },
            {
                Header: "Desired Advisor Comp Split",
                accessor: "split",
                Filter: false,
                Cell: ({ value }) => {            
                    if (value > 0)
                        return <span>{value}%</span>;
                    else
                        return "";
                },
            },
            {
                Header: "Detail",
                accessor: "detail",
                Filter: false,
            }
            // {
            //     Header: "Meeting Type",
            //     accessor: "meetingType",
            //     Filter: false,
            //     filterable: true,
            //     Cell: (props) => {
            //         return props.row.original.meetingType ? props.row.original.meetingType : <Badge color="soft-secondary">Pending</Badge>
            //     }
            // },
            // {
            //     Header: "Meeting Date",
            //     accessor: "meetingDate",
            //     Filter: false,
            //     filterable: true,
            //     Cell: (props) => {
            //         return props.row.original.meetingDate ? props.row.original.meetingDate : <Badge color="soft-secondary">Pending</Badge>
            //     }
            // }
        ],
        [handleEdit]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeader title="Revenue Weekly Report" onPrevWeek={movePrevWeek} onNextWeek={moveNextWeek} onCurrWeek={moveCurrWeek} />
                    <Row>
                        {
                            notificationData.map((data, index) => {
                                return <NotificationMiniCard title={data.title} content={data.value} key={index} size={3} />
                            })
                        }
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <CardTitle tag="h5" className="mb-0 flex-grow-1">
                                        AUM Balance
                                    </CardTitle>
                                    <div className="flex-shrink-0">
                                        <Link
                                            to="#"
                                            onClick={() => handleCreateAum()}
                                            className="btn btn-success me-1"
                                        >
                                            {" "}
                                            <i className="bx bx-plus-circle"></i> Add AUM Balance
                                        </Link>
                                        <Link onClick={() => refetch()} to="#" className="btn btn-light me-1">
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>
                            </CardBody>
                            <CardBody>
                                <SmartTable
                                    columns={aumColumns}
                                    data={aumRevenueList || []}
                                    isAddOptions={false}
                                    customPageSize={30}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    isShowingPageLength={true}
                                    customPageSizeOption={true}
                                    tableClass="table-bordered align-middle nowrap mt-2"
                                    paginationDiv="col-12"
                                    pagination="pagination justify-content-end pagination-rounded"
                                ></SmartTable>
                            </CardBody>
                        </Card>
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <CardTitle tag="h5" className="mb-0 flex-grow-1">
                                        Revenue Paid
                                    </CardTitle>
                                    <div className="flex-shrink-0">
                                        <Link
                                            to="#"
                                            onClick={() => handleCreate()}
                                            className="btn btn-success me-1"
                                        >
                                            {" "}
                                            <i className="bx bx-plus-circle"></i> Add Revenue
                                        </Link>
                                        <Link onClick={() => refetch()} to="#" className="btn btn-light me-1">
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>
                            </CardBody>
                            <CardBody>
                                <SmartTable
                                    columns={columns}
                                    data={revenueObjects || []}
                                    isAddOptions={false}
                                    customPageSize={30}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    isShowingPageLength={true}
                                    customPageSizeOption={true}
                                    tableClass="table-bordered align-middle nowrap mt-2"
                                    paginationDiv="col-12"
                                    pagination="pagination justify-content-end pagination-rounded"
                                ></SmartTable>
                            </CardBody>
                        </Card>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Revenue" : "Add Revenue"}
                        </ModalHeader>
                        <ModalBody>
                            <RevenueForm current={current} toggle={toggle} />
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modalAum} toggle={toggleAum}>
                        <ModalHeader toggle={toggleAum} tag="h4">
                            {!!isEdit ? "Edit AUM Balance" : "Add AUM Balance"}
                        </ModalHeader>
                        <ModalBody>
                            <AumForm current={currentAum} toggle={toggleAum} />
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RevenueWeeklyReport;