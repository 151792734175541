import React from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";

//redux

import { Col, Row, Button } from "reactstrap";

import { SmartInput } from "Components/Common/SmartInput";
import { SmartNumber } from "Components/Common/SmartNumber";
import { SmartDatePicker } from "Components/Common/SmartDatePicker";
import { SmartCreatable } from "Components/Common/SmartCreatable";
import { SmartSelect } from "Components/Common/SmartSelect";
import { SmartCheck } from "Components/Common/SmartCheck";
import { SmartCurrency } from "Components/Common/SmartCurrency";
import {
  useCreateEvent,
  useUpdateEvent,
  useCreateEventType,
  useFetchEnableEventTypes,
  useCreateEventLocation,
  useFetchEventLocations,
  useCreateMarketingSource,
  useFetchMarketingSource,
  useFetchUsers
} from "api";

function EventForm({ current, toggle }) {
  const createEventLocationMutation = useCreateEventLocation();
  const createMarketSourceMutation = useCreateMarketingSource();
  const createEventTypeMutation = useCreateEventType();
  const createEventMutation = useCreateEvent();
  const updateEventMutation = useUpdateEvent();

  const { data: marketing_sources, isLoading: market_source_loading } = useFetchMarketingSource();
  const { data: event_types, isLoading: event_type_loading } = useFetchEnableEventTypes();
  const { data: event_locations, isLoading: event_location_loading } = useFetchEventLocations();
  const { isLoading: advisors_loading, data: advisors } = useFetchUsers();

  const handleSubmit = (values) => {
    if (current) {
      values.id = current.id;
      updateEventMutation.mutate(values);
    } else {
      createEventMutation.mutate(values);
    }

    toggle();
  };
  const initialValues = {
    name: (current && current.name) || "",
    eventDate: (current && current.eventDate) || "",
    marketingSource: (current && current.marketingSource) || null,
    eventType: (current && current.eventType) || null,
    location: (current && current.location) || null,
    presenter: (current && current.presenter) || null,
    // topic: (current && current.topic) || null,
    // marketingCost: (current && current.marketingCost) || 0,
    venueCost: (current && current.venueCost) || "",
    mailerCount: (current && current.mailerCount) || 0,
    mailerCost: (current && current.mailerCost) || 0,
    assignAll: (current && current.assignAll) || false,
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Name"),
    eventDate: Yup.date().nullable().required("Please Event Date"),
    marketingSource: Yup.object().nullable().notRequired(),
    eventType: Yup.object().nullable().notRequired(),
    presenter: Yup.object().nullable().notRequired(),
    // topic: Yup.object().nullable().notRequired(),
    location: Yup.object().nullable().notRequired(),
    // marketingCost: Yup.number().nullable().notRequired().default(0),
    venueCost: Yup.number().nullable().notRequired().default(0),
    mailerCount: Yup.number().nullable().notRequired().default(0),
    mailerCost: Yup.number().nullable().notRequired().default(0),
    assignAll: Yup.boolean().default(false),
  });

  const handleCreateMarketSource = (value) => {
    createMarketSourceMutation.mutate({ name: value });
  };

  const handleCreateEventType = (value) => {
    createEventTypeMutation.mutate({ name: value });
  };

  const handleCreateEventLocation = (value) => {
    createEventLocationMutation.mutate({ name: value });
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        {formik => (
        <Form>
          <Row>
            <SmartInput name="name" label="Name" />
            <SmartDatePicker name="eventDate" label="Event Date" />
            <SmartCreatable
              isDisabled={market_source_loading}
              isLoading={market_source_loading}
              handleCreate={(value) => handleCreateMarketSource(value)}
              isClearable={true}
              options={marketing_sources}
              label="Marketing Source"
              name="marketingSource"
            />
            {/* <SmartCreatable
              isDisabled={topic_loading}
              isLoading={topic_loading}
              handleCreate={(value) => handleCreateTopic(value)}
              isClearable={true}
              options={topics}
              label="Topic"
              name="topic"
            /> */}
            <SmartCreatable
              isDisabled={event_type_loading}
              isLoading={event_type_loading}
              handleCreate={(value) => handleCreateEventType(value)}
              isClearable={true}
              options={event_types}
              label="Event Type"
              name="eventType"
            />
            <SmartCreatable
              isDisabled={event_location_loading}
              isLoading={event_location_loading}
              handleCreate={(value) => handleCreateEventLocation(value)}
              isClearable={true}
              options={event_locations}
              label="Event Location"
              name="location"
            />
            <SmartSelect
              isDisabled={advisors_loading}
              isLoading={advisors_loading}
              isClearable={true}
              options={advisors}
              label="Presenter"
              name="presenter"
              optionLabelFn={(option) => option.fullName}
            />
            <SmartCheck label="Assign all to Presenter" name="assignAll" />
            {/* <SmartInput
              type="number"
              name="marketingCost"
              label="Marketing Cost"
              size="6"
            /> */}
            <SmartCurrency
              size="6" 
              label="Mailer Cost" 
              name="mailerCost"
            />
            <SmartNumber
              type="number"
              name="mailerCount"
              label="No. of Mailers"
              size="6"
            />
            <SmartCurrency
              size="6" 
              label="Venue Cost" 
              name="venueCost"
            />
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <Button type="submit" color="success" className="save-user">
                  {" "}
                  <i className="bx bx-save"></i>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default EventForm;
