import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Label } from "reactstrap";

export const SmartNumber = ({
  handleButtonClick,
  buttonText,
  size = "12",
  label,
  type,
  format,
  ...props
}: any) => {
  const [field, meta, helpers] = useField(props);
  const [displayValue, setDisplayValue] = useState('');

  const formatCurrency = (value: number) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const formatWithCommas = (value: number) => {
    return value.toLocaleString('en-US');
  };

  const formatWithPercent = (value: number) => {
    return value.toLocaleString('en-US') + "%";
  };

  useEffect(() => {
    if ( format === '$' )
      setDisplayValue(formatCurrency(field.value || 0));
    else if ( format === '%' )
      setDisplayValue(formatWithPercent(field.value || 0));
    else
      setDisplayValue(formatWithCommas(Number(field.value || 0)));
  }, [field.value, format]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.-]/g, '');
    
    helpers.setValue(Number(value));
    if ( format === '$' )
      setDisplayValue(formatCurrency(Number(value)));
    else if ( format === '%' )
      setDisplayValue(formatWithPercent(Number(value)));
    else
      setDisplayValue(formatWithCommas(Number(value)));
  };

  return (
    <Col md={size}>
      <FormGroup className="mb-3">
        <Label htmlFor={props.id || props.name || label}>{label}</Label>
        <div className={
              meta.touched && meta.error
                ? "input-group is-invalid"
                : "input-group"
            }>
            {props.append && <span className="input-group-append"><span className="input-group-text">{props.append}</span></span>}

            <input
              {...field}
              {...props}
              value={displayValue}
              onChange={handleChange}
              className={
                meta.touched && meta.error
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id={props.id || props.name || label}
              maxLength={12}
            />
            {handleButtonClick && (
              <Button onClick={handleButtonClick} color="primary" id={`btn_${props.id || props.name}`}>
                {buttonText || <i className="fa fa-plus-circle"></i>}
              </Button>
            )}
        </div>

        {meta.touched && meta.error ? (
          <FormFeedback type="invalid">{meta.error}</FormFeedback>
        ) : null}
      </FormGroup>
    </Col>
  );
};
