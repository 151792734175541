import React, { useEffect, useState } from "react";
import {

  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";

import {ProspectList} from "./ProspectList";
import { Link } from "react-router-dom";

// import { useFetchEventStatistics } from 'api/EventApi';
// import { useFetchProspectsByEvent } from 'api/ProspectApi';

export const MarketingStats = ({title, prospects, isLoading}) => {

  const [objects, setObjects]= useState<any>([])

  const [details, setDetails] = React.useState<any>();

  useEffect(() => {
    const attended = prospects?.filter((prospect) => prospect.attended === true);
    const attendedLength = attended?.length || 0;
    const total = prospects?.length || 0;
    const attendanceRate = total === 0 ? 0 : ((attendedLength/ total) * 100).toFixed(2);
    const yes = prospects?.filter((prospect) => prospect.attended === true && prospect.attendStatus === 'YES_AT_EVENT');
    const yesLength = yes?.length || 0;
    const yesRate =  attendedLength ===0 ? 0 :((yesLength / attendedLength ) * 100).toFixed(2);
    const noShows = prospects?.filter((prospect) => prospect.attended === false)?.length || 0;
    const noAtEvent = prospects?.filter((prospect) => prospect.attended === true && prospect.attendStatus === 'NO_AT_EVENT')?.length || 0;
    setDetails({
      attendanceRate,
      yesRate,
      noShows,
      noAtEvent
    })
  },[prospects])

  useEffect(() => {
    if (prospects) {
      const attended = prospects.filter((prospect) => prospect.attended === true);
      setObjects(attended);
    }
  }, [prospects])


  const onAttendanceRateClick = (e) => {
    e.preventDefault();
    const attended = prospects.filter((prospect) => prospect.attended === true);
    setObjects(attended)
  }

  const onYesRateClick = (e) => {
    e.preventDefault();
    const yes = prospects.filter((prospect) => prospect.attended === true && prospect.attendStatus === 'YES_AT_EVENT');
    setObjects(yes)
  }


  const onNoShowClick = (e) => {
    e.preventDefault();
    const noShows = prospects.filter((prospect) => prospect.attended === false);
    setObjects(noShows)
  }

  const onNoAtEventClick = (e) => {
    e.preventDefault();
    const noAtEvent = prospects.filter((prospect) => prospect.attended === true && prospect.attendStatus === 'NO_AT_EVENT');
    setObjects(noAtEvent)
  }



  return (
    <React.Fragment>
     <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col md={4}>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                      
                        <h5 className="mb-1">{title}</h5>
  
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs={3}>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          <Link to='#' onClick={onAttendanceRateClick}> Attendance Rate </Link>
                          </p>
                          <h5 className="mb-0">{details?.attendanceRate || 0} %</h5>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          <Link to='#' onClick={onYesRateClick}> Yes Rate </Link>
                          </p>
                          <h5 className="mb-0">{details?.yesRate || 0} %</h5>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                           <Link to='#' onClick={onNoShowClick}> No Shows </Link>
                          </p>
                          <h5 className="mb-0">{details?.noShows || 0}</h5>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          <Link to='#' onClick={onNoAtEventClick}> No At Event </Link>
                          </p>
                          <h5 className="mb-0">{details?.noAtEvent || 0}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
          <Row>
            <ProspectList
              prospects={objects}
              isLoading={isLoading}
            />
          </Row>
    </React.Fragment>
  );
};