import { createSelector } from 'reselect';

// Assuming you have a simple state structure like this:
// state = {
//   users: {
//     list: [{id: 1, name: 'John'}, {id: 2, name: 'Jane'}]
//   }
// }

// Step 1: Create input selectors
const getUserState = (state) => state.Login;

// Step 2: Create a memoized selector
export const getUserInfo = createSelector(
  [getUserState], // You can list multiple input selectors here
  (user) => user.user
);

// Now, getUsersList is a memoized selector that only recomputes when users.list changes.