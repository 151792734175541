

import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/advisor/prospects`

const key = 'prospects'
const messageKey = 'Prospect'


const fetchWeeklyProspects = async (date, advisor) => await api.get(`${url}/weekly/${advisor}/${date}`, { requestApi: "weekly" });
const fetchYearlyProspects = async (advisor) => await api.get(`${url}/yearly/${advisor}`, { requestApi: "yearly" });
const fetchAdvisorProspects = async (advisor) => await api.get(`${url}/${advisor}`, null);

export const useFetchAdvisorProspects = (advisor?) => {
    return useQuery({
        queryKey: [key, 'advisor-' + advisor],
        queryFn: () => fetchAdvisorProspects(advisor || 0),
    })
}

export const useFetchWeeklyProspects = (current_date, advisor?) => {
    return useQuery({
        queryKey: [key, current_date + advisor],
        queryFn: () => fetchWeeklyProspects(current_date, (advisor || 0)),
        onError: (error) => {
            errorToast(error);
        }
    })
}

export const useFetchYearlyProspects = (advisor?) => {
    return useQuery({
        queryKey: [key, 'yearly-' + advisor],
        queryFn: () => fetchYearlyProspects(advisor || 0),
        onError: (error) => {
            errorToast(error);
        }
    })
}