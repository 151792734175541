import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
import ProspectMeetingForm from "./ProspectMeetingForm";

const EventProspectList = ({ prospects, isLoading }: any) => {
  const [objects, setObjects] = useState<any>();

  const [current, setCurrent] = useState<any>(null);

  const [modal, setModal] = React.useState(false);

  const navigate = useNavigate()

  const toggle = () => {
    setModal(!modal);
  };

  const updateMeeting = (prospect) => {
    setCurrent(prospect);
    toggle();
  };

  useEffect(() => {
    setObjects(prospects);
  }, [prospects]);

  const handleProspectDetail = useCallback(
    (prospect: any) => {
        navigate(`/marketing/prospect/${prospect.id}/detail`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Last Name",
        accessor: "lastName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          return (
              <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={(e) => {
                      e.preventDefault();
                      const row = cellProps.row.original;
                      handleProspectDetail(row);
                  }}
              >
                  {cellProps.row.original.lastName}
              </Link>
          )
        }
      },
      {
        Header: "First Name",
        accessor: "firstName",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Advisor",
        accessor: "advisor.fullName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          if (cellProps?.row.original.advisor?.fullName !== "null null")
            return <span>{cellProps?.row.original.advisor?.fullName}</span>;
          else
            return <span>{cellProps?.row.original.advisor?.username}</span>;
        },
      },
      {
        Header: "Investible Assets",
        accessor: "newMoney",
        Filter: false,
        filterable: true,
        Cell: ({ value }) => {
          const formattedValue = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }).format(value);
  
          return <span>{formattedValue}</span>;
      },
      },
      {
        Header: "Meeting Date",
        accessor: "meetingDate",
        Filter: false,
        filterable: true,
      },
      // {
      //   Header: "Spouse",
      //   accessor: "spouse",
      //   Filter: false,
      //   filterable: true,
      // },
      // {
      //   Header: "Meeting Date",
      //   accessor: "meetingDate",
      //   Filter: false,
      //   Cell: (props) => {
      //     return props.row.original.meetingDate ? (
      //       props.row.original.meetingDate
      //     ) : (
      //       <Badge color="danger bg-opacity-50 px-2 fs-6">Pending</Badge>
      //     );
      //   },
      // },
      {
        Header: "Meeting Status",
        accessor: "status",
        Filter: false,
        filterable: true,
      },
      // {
      //   Header: "Actions",
      //   accessor: "id",
      //   Filter: false,
      //   Cell: (props) => {
      //     const row = props.row.original;
      //     return (
      //       <div className="d-flex gap-3">
      //         <Button
      //           className="btn btn-sm btn-primary"
      //           onClick={() => updateMeeting(row)}
      //         >
      //           Update Meeting
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );
  return (
    <React.Fragment>
      <Col xl={12}>
        {isLoading ? (
          <Spinners />
        ) : (
          <Card>
            <CardBody>
              <SmartTable
                columns={columns}
                data={objects || []}
                customPageSize={8}
                isGlobalFilter={true}
                isCustomFilter={true}
                isPagination={true}
                isShowingPageLength={true}
                customPageSizeOption={true}
                tableClass="table-bordered align-middle nowrap mt-2"
                paginationDiv="col-sm-12 col-md-7"
                pagination="pagination justify-content-end pagination-rounded"
              ></SmartTable>
            </CardBody>
          </Card>
        )}
      </Col>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {"Edit Meeting Detail"}
        </ModalHeader>
        <ModalBody>
          <ProspectMeetingForm current={current} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EventProspectList;
