import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";


import {
  Col,
  Row,
  Button,
} from "reactstrap";

import { SmartInput } from "Components/Common/SmartInput";
import { SmartSelect } from "Components/Common/SmartSelect";

import { useCreateFirm, useUpdateFirm, useFetchFirms } from "api";

function FirmForm({current, toggle}) {

  const {data: firms, isLoading: firm_loading} = useFetchFirms()

  const createFirmMutation = useCreateFirm();
  const updateFirmMutation = useUpdateFirm()

  const initialValues = {
    name: (current && current.name) || "",
    description: (current && current.description) || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });


  const handleSubmit = (values) => {
    if (current) {
      values.id = current.id;
      updateFirmMutation.mutate(values);
    } else {
      createFirmMutation.mutate(values);
    }

    toggle();
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        <Form>
          <SmartInput name="name" label="Firm" />
          <SmartInput name="description"  label="Description" />
          <Row>
            <Col>
              <div className="text-end">
                <Button type="submit" color="success" className="save-user">
                  {" "}
                  <i className="bx bx-save"></i>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Formik>
    </React.Fragment>
  );
}

export default FirmForm;