import { APIClient } from "helpers/api_helper";
import { useQuery } from "@tanstack/react-query";


const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/marketing`

const fetchProspectsByLocationId = async (id) => await api.get(`${url}/locations/${id}/prospects`, null);
const fetchProspectsByEventTypeId = async (id) => await api.get(`${url}/event-types/${id}/prospects`, null);
const fetchProspectsByTopicId = async (id) => await api.get(`${url}/topics/${id}/prospects`, null);
const fetchProspectsByMarketingSourceId = async (id) => await api.get(`${url}/marketing-sources/${id}/prospects`, null);
const fetchProspectsByPresenterId = async (id) => await api.get(`${url}/presenters/${id}/prospects`, null);
const fetchProspectsById = async (id) => await api.get(`${url}/prospects/${id}`, null);

const fetchMetrics = async (type) => await api.get(`${url}/metrics/${type}`, null);

const fetchDashboardMetrics = async () => await api.get(`${url}/dashboard`, null);

const fetchProspectPipelineMetrics = async () => await api.get(`${url}/prospect-pipeline/metrics`, null);

export const useFetchMarketingDashboardMetrics = () => {
  return useQuery({
      queryKey: ['prospects', 'dashboard'],
      queryFn: () => fetchDashboardMetrics(),
  });
}

export const useFetchProspectPipelineDashboard = () => {
  return useQuery({
      queryKey: ['prospect-pipeline', 'metrics'],
      queryFn: () => fetchProspectPipelineMetrics(),
  });
}

export const useFetchMetrics = (type) => {
  return useQuery({
      queryKey: ['prospects', 'metrics', type],
      queryFn: () => fetchMetrics(type),
      enabled: !!type
  });
}

export const useFetchProspectsByPresenterId = (id) => {
  return useQuery({
      queryKey: ['prospects', 'presenter', id],
      queryFn: () => fetchProspectsByPresenterId(id),
      enabled: !!id
  });
}

export const useFetchProspectsByMarketingSourceId = (id) => {
  return useQuery({
      queryKey: ['prospects', 'marketing-source', id],
      queryFn: () => fetchProspectsByMarketingSourceId(id),
      enabled: !!id
  });
}


export const useFetchProspectsByLocationId = (id) => {
    return useQuery({
        queryKey: ['prospects', 'location', id],
        queryFn: () => fetchProspectsByLocationId(id),
        enabled: !!id
    });
}

export const useFetchProspectsByEventTypeId = (id) => {
  return useQuery({
      queryKey: ['prospects', 'event-types', id],
      queryFn: () => fetchProspectsByEventTypeId(id),
      enabled: !!id
  });
}

export const useFetchProspectsByTopicId = (id) => {
  return useQuery({
      queryKey: ['prospects', 'event-types', id],
      queryFn: () => fetchProspectsByTopicId(id),
      enabled: !!id
  });
}

export const useFetchProspectsById = (id) => {
  return useQuery({
      queryKey: ['prospects', 'by-id', id],
      queryFn: () => fetchProspectsById(id),
      enabled: !!id
  });
}