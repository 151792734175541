import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/settings/result-types`

const fetchObjects = async () => await api.get(`${url}`, null);
const fetchObject = async (id) => await api.get(`${url}/${id}`, null);
const createObject = async (obj) => await api.create(url, obj);
const updateObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);

const key = 'result-types'
const messageKey = 'Result Type'

export const useFetchResultTypeById = (id) => {
  return useQuery({
    queryKey: [key, id],
    queryFn: () => fetchObject(id),
    enabled: !!id
  });
}


export const useFetchResultTypes = () => {
    return useQuery([key], fetchObjects);
}

export const useCreateResultType = () => {
    const queryClient = useQueryClient();
    return useMutation(createObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} created Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useUpdateResultType = () => {
    const queryClient = useQueryClient();
    return useMutation(updateObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} updated Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}
