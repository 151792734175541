import PageHeader from "Components/Common/PageHeader";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useFetchEventMetricsByType, currencyFormat, numberFormat, numberDecimalFormat } from "api";

const EventBy = ({ pageTitle, options }) => {
    interface SelectItem {
        label: any;
        value: any;
    };

    const [selectOpts, setSelectOpts] = useState<any>([]);

    const [firstSelectOpts, setFirstSelectOpts] = useState<any>();
    const [secondSelectOpts, setSecondSelectOpts] = useState<any>();
    
    const { data: firstEventData } = useFetchEventMetricsByType(firstSelectOpts?.value ? firstSelectOpts.value : 0, pageTitle);
    const { data: secondEventData } = useFetchEventMetricsByType(secondSelectOpts?.value ? secondSelectOpts.value : 0, pageTitle);
    // const { data: events } = useFetchEvents();

    useEffect(() => {
        if (options) {
            const optionArray = createSelectOptionItem(options, pageTitle);

            const seenLabels = new Set();
            const uniqueArray = optionArray.filter(obj => {
                if (seenLabels.has(obj.label)) {
                    return false;
                }
                seenLabels.add(obj.label);
                return true;
            });

            setSelectOpts([...uniqueArray]);
            if (optionArray.length) {
                if (optionArray.length === 1) {
                    setFirstSelectOpts(optionArray[0]);
                    setSecondSelectOpts(optionArray[0]);
                }
                else {
                    setFirstSelectOpts(optionArray[0]);
                    setSecondSelectOpts(optionArray[1]);
                }
            }

        }
    }, [options, pageTitle]);

    const createSelectOptionItem = (options: any, title: string) => {

        // console.log(options)
        const opts: SelectItem[] = [];

        var fieldString = "";
        var subFieldString = "";

        if (title === "Event By Type") {
            fieldString = "eventType";
            subFieldString = "name";
        }
        else if (title === "Event By Source") {
            fieldString = "marketingSource";
            subFieldString = "name";
        }
        else if (title === "Event By Location") {
            fieldString = "location";
            subFieldString = "name";
        }
        else {
            fieldString = "presenter";
            subFieldString = "fullName";
        }

        if ( fieldString === "presenter" ) {
            options?.map((option: any) => {
                const selectItem: SelectItem = {
                    label: option?.fullName !== "null null" ? option?.fullName : option?.username,
                    value: option.id
                };
    
                opts.push(selectItem);
            });
        } else {
            options?.map((option: any) => {
                const selectItem: SelectItem = {
                    label: option?.[subFieldString],
                    value: option.id
                };
    
                opts.push(selectItem);
            });
        }        

        return opts;
    }

    const eventFirstData = [
        {
            title: 'Registrants/Leads',
            value: numberFormat(firstEventData?.registrants)
        },
        {
            title: 'Registration Rate',
            value: (firstEventData?.registrationRate || 0) + '%'
        },
        {
            title: 'Households Registered',
            value: numberFormat(firstEventData?.hhRegistered)
        },
        {
            title: 'Households Attended',
            value: numberFormat(firstEventData?.eventAtBats)
        },
        {
            title: 'Attendance Rate',
            value: (firstEventData?.attendanceRate || 0) + '%'
        },
        {
            title: 'First Meetings Requested',
            value: numberFormat(firstEventData?.firstMeetingsSet)
        },
        {
            title: 'Yes Rate',
            value: (firstEventData?.yesRate || 0) + '%'
        },
        {
            title: 'First Meeting Dates Past',
            value: numberFormat(firstEventData?.totalInOfficeOpportunities)
        },
        // {
        //     title: 'Qualified in Office Opps',
        //     value: firstEventData?.qualifiedInOfficeOpps || 0
        // },
        {
            title: 'First Meetings Kept',
            value: numberFormat(firstEventData?.totalInOfficeAtBats)
        },
        {
            title: 'Stick Rate',
            value: (firstEventData?.qualifiedStickRate || 0) + '%'
        },
        {
            title: 'Cost per First Meeting Kept',
            value: currencyFormat(firstEventData?.costPerFirstKept)
        },
        // {
        //     title: 'Close Rate',
        //     value: firstEventData?.grassStickRate || 0
        // },
        {
            title: '2nd Meetings Scheduled',
            value: numberFormat(firstEventData?.secondMeetingScheduled)
        },
        {
            title: '2nd Meeting Dates Past',
            value: numberFormat(firstEventData?.secondMeetingOpportunities)
        },
        {
            title: '2nd Meetings Kept',
            value: numberFormat(firstEventData?.secondMeetingKept)
        },
        {
            title: '2nd Stick Rate',
            value: (firstEventData?.secondStickRate || 0) + '%'
        },
        {
            title: 'Total Completed',
            value: numberFormat(firstEventData?.totalCompleteInProcess)
        },
        {
            title: 'Total Signed',
            value: numberFormat(firstEventData?.totalSigned)
        },
        {
            title: 'Gross Close Rate',
            value: (firstEventData?.closeRate || 0) + '%'
        },
        {
            title: 'Cost per Client',
            value: currencyFormat(firstEventData?.costPerClient)
        },
        {
            title: 'New Assets',
            value: currencyFormat(firstEventData?.newMoney)
        },
        {
            title: 'Avg New Assets',
            value: currencyFormat(firstEventData?.avgIPA)
        },
        // {
        //     title: 'Gross Comp',
        //     value: currencyFormat(firstEventData?.grossComp)
        // },
        // {
        //     title: 'Comp Per Client',
        //     value: currencyFormat(firstEventData?.compPerClient)
        // },
        {
            title: 'Total cost',
            value: currencyFormat(firstEventData?.totalCost)
        },
        {
            title: 'Return on Investment',
            value: numberDecimalFormat(firstEventData?.roi, 4) + "%"
        }
    ];

    const eventSecondData = [
        {
            title: 'Registrants/Leads',
            value: numberFormat(secondEventData?.registrants)
        },
        {
            title: 'Registration Rate',
            value: (secondEventData?.registrationRate || 0) + '%'
        },
        {
            title: 'Households Registered',
            value: numberFormat(secondEventData?.hhRegistered)
        },
        {
            title: 'Households Attended',
            value: numberFormat(secondEventData?.eventAtBats)
        },
        {
            title: 'Attendance Rate',
            value: (secondEventData?.attendanceRate || 0) + '%'
        },
        {
            title: 'First Meetings Requested',
            value: numberFormat(secondEventData?.firstMeetingsSet)
        },
        {
            title: 'Yes Rate',
            value: (secondEventData?.yesRate || 0) + '%'
        },
        {
            title: 'First Meeting Dates Past',
            value: numberFormat(secondEventData?.totalInOfficeOpportunities)
        },
        // {
        //     title: 'Qualified in Office Opps',
        //     value: secondEventData?.qualifiedInOfficeOpps || 0
        // },
        {
            title: 'First Meetings Kept',
            value: numberFormat(secondEventData?.totalInOfficeAtBats)
        },
        {
            title: 'Stick Rate',
            value: (secondEventData?.qualifiedStickRate || 0) + '%'
        },
        {
            title: 'Cost per First Meeting Kept',
            value: currencyFormat(secondEventData?.costPerFirstKept)
        },
        // {
        //     title: 'Close Rate',
        //     value: secondEventData?.grassStickRate || 0
        // },
        {
            title: '2nd Meetings Scheduled',
            value: numberFormat(secondEventData?.secondMeetingScheduled)
        },
        {
            title: '2nd Meeting Dates Past',
            value: numberFormat(secondEventData?.secondMeetingOpportunities)
        },
        {
            title: '2nd Meetings Kept',
            value: numberFormat(secondEventData?.secondMeetingKept)
        },
        {
            title: '2nd Stick Rate',
            value: (secondEventData?.secondStickRate || 0) + '%'
        },
        {
            title: 'Total Completed',
            value: numberFormat(secondEventData?.totalCompleteInProcess)
        },
        {
            title: 'Total Signed',
            value: numberFormat(secondEventData?.totalSigned)
        },
        {
            title: 'Gross Close Rate',
            value: (secondEventData?.closeRate || 0) + '%'
        },
        {
            title: 'Cost per Client',
            value: currencyFormat(secondEventData?.costPerClient)
        },
        {
            title: 'New Assets',
            value: currencyFormat(secondEventData?.newMoney)
        },
        {
            title: 'Avg New Assets',
            value: currencyFormat(secondEventData?.avgIPA)
        },
        // {
        //     title: 'Gross Comp',
        //     value: currencyFormat(secondEventData?.grossComp)
        // },
        // {
        //     title: 'Comp Per Client',
        //     value: currencyFormat(secondEventData?.compPerClient)
        // },
        {
            title: 'Total cost',
            value: currencyFormat(secondEventData?.totalCost)
        },
        {
            title: 'Return on Investment',
            value: numberDecimalFormat(secondEventData?.roi, 4) + "%"
        }
    ];

    const updateFirstSection = (e) => {
        setFirstSelectOpts(e);
    }

    const updateSecondSection = (e) => {
        setSecondSelectOpts(e);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeader title={pageTitle} />
                    <Row>
                        <Col xs={12} sm={12} md={6}>
                            <Card>
                                <CardBody>
                                    <Select
                                        isClearable={true}
                                        options={selectOpts ? selectOpts : []}
                                        placeholder={pageTitle}
                                        onChange={(e) => updateFirstSection(e)}
                                        value={firstSelectOpts}
                                    />
                                    <Row className="mt-4">
                                        {
                                            eventFirstData.map((data, index) => {
                                                return (
                                                    <Row className="mb-2" key={index}>
                                                        <Col xs={6} sm={6}>
                                                            <h6>{data.title}</h6>
                                                        </Col>
                                                        <Col xs={6} sm={6}>
                                                            <h6 className="text-end">{data.value}</h6>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <Card>
                                <CardBody>
                                    <Select
                                        isClearable={true}
                                        options={selectOpts ? selectOpts : []}
                                        placeholder={pageTitle}
                                        onChange={(e) => updateSecondSection(e)}
                                        value={secondSelectOpts}
                                    />
                                    <Row className="mt-4">
                                        {
                                            eventSecondData.map((data, index) => {
                                                return (
                                                    <Row className="mb-2" key={index}>
                                                        <Col xs={6} sm={6}>
                                                            <h6>{data.title}</h6>
                                                        </Col>
                                                        <Col xs={6} sm={6}>
                                                            <h6 className="text-end">{data.value}</h6>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EventBy;