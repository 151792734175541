import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFetchMarketingDashboardMetrics, useFetchProspects, useFetchEvents, currencyFormat } from 'api';
import Chart from "react-apexcharts";
import { ApexOptions } from 'apexcharts';
import SmartTable from 'Components/Common/SmartTable';
import PageHeader from 'Components/Common/PageHeader';
import DashboardNotificationCard from 'Components/Common/DashboardNotificationCard';
import DashboardTooltip from 'Components/Common/DashboardTooltip';
import Spinners from 'Components/Common/Spinner';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getUserInfo } from '../../../selectors';

export const MarketingDashboard = () => {
    document.title = "Marketing Dashboard";

    const navigate = useNavigate()

    const userInfo = useSelector(getUserInfo);
    const defaultImage = "female.png";
    const imagePath = `/assets/${userInfo?.profileImage || defaultImage}`;

    const [userName, setUserName] = useState<string>('');
    const { data } = useFetchMarketingDashboardMetrics();
    const { data: events, isLoading: event_loading } = useFetchEvents();
    const { data: prospects, isLoading } = useFetchProspects();
    const [activeProspects, setActiveProspects] = useState<any>([]);
    const state = {
        series: [{
            name: 'Yes Rate',
            data: data?.rateGraph[0]
        }, {
            name: 'Stick Rate',
            data: data?.rateGraph[1]
        }, {
            name: 'Close Rate',
            data: data?.rateGraph[2]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: Array.from(
                    new Set(
                        events?.sort((a, b) => {
                          if (a.eventDate < b.eventDate) return -1;
                          if (a.eventDate > b.eventDate) return 1;
                          
                          return a.id - b.id;
                        })
                        .map(item => item.name)
                    )
                )
            },
            yaxis: {
                title: {
                    text: 'Percentage(%)'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + '%'
                    }
                }
            }
        }
    };

    const handleProspectDetail = useCallback(
        (prospect: any) => {
            navigate(`/marketing/prospect/${prospect.id}/detail`);
        },
        [navigate]
    );

    const columns = useMemo(
        () => [
            {
                Header: "Last Name",
                accessor: "lastName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    return (
                        <Link
                            to="#"
                            className="btn btn-sm btn-soft-info"
                            onClick={(e) => {
                                e.preventDefault();
                                const row = cellProps.row.original;
                                handleProspectDetail(row);
                            }}
                        >
                            {cellProps.row.original.lastName}
                        </Link>
                    )
                }
            },
            {
                Header: "First Name",
                accessor: "firstName",
                Filter: false,
                filterable: true,
            },
            {
                Header: "Event Name",
                accessor: "event.name",
                Filter: false,
                filterable: true,
            },
            {
                Header: "Advisor",
                accessor: "advisor.fullName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    if (cellProps?.row.original.advisor?.fullName !== "null null")
                      return <span>{cellProps?.row.original.advisor?.fullName}</span>;
                    else
                      return <span>{cellProps?.row.original.advisor?.username}</span>;
                },
            },
            {
                Header: "New Assets",
                accessor: "newMoney",
                Filter: false,
                Cell: ({ value }) => {            
                    return <span>{currencyFormat(value)}</span>;
                },
            },
            {
                Header: "Meeting Type",
                accessor: "meeting.name",
                Filter: false,
                filterable: true,
                Cell: (props) => {
                    return props.row.original.meeting?.name ? props.row.original.meeting?.name : <Badge color="soft-secondary">Pending</Badge>
                }
            },
            {
                Header: "Meeting Date",
                accessor: "meetingDate",
                Filter: false,
                filterable: true,
                Cell: (props) => {
                    return props.row.original.meetingDate ? props.row.original.meetingDate : <Badge color="soft-secondary">Pending</Badge>
                }
            }
        ],
        []
    );

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser") || "");
            setUserName(obj.firstName + ' ' + obj.lastName);
        }
    });

    const userData = {
        name: userName,
        avatar: imagePath
    };

    const notificationData = [
        {
            title: 'Total Seminars',
            content: data?.seminarCount || 0
        },
        {
            title: 'Households Attended Events',
            content: data?.householdsAttended || 0
        },
        {
            title: 'First Meeting Requested',
            content: data?.firstSet || 0
        },
        {
            title: 'Clients Signed',
            content: data?.clientMade || 0
        }
    ];

    const displayData = [
        {
            title: 'Yes Rate',
            value: (data?.yesRate || 0) + '%'
        },
        {
            title: 'First Meetings Kept',
            value: (data?.firstKept || 0)
        },
        {
            title: 'Stick Rate',
            value: (data?.stickRate || 0) + '%'
        },
        {
            title: 'Close Rate',
            value: (data?.closeRate || 0) + '%'
        },
        {
            title: 'Total Cost',
            value: (data?.totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || 0)
        },
        {
            title: 'Return on Investment',
            value: data?.roi.toFixed(2) || 0
        },
        {
            title: 'Average New Assets',
            value: data?.avgIPA.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || 0
        }
    ];

    useEffect(() => {
        if (prospects) {
            const activeProspects = prospects.filter((prospect) => prospect.attended === "YES_AT_EVENT" && prospect.isActive);
            setActiveProspects(activeProspects);
        }
    }, [prospects]);

    return (
        <div className="page-content">
            <Container fluid>
                <PageHeader title="Marketing Dashboard" />
                <DashboardNotificationCard user={userData} notificationData={notificationData} />
                <Row>
                    <Col className="mt-4 mt-xs-4 mt-sm-4" sm="12" md="12" lg="9">
                        <h3 className='text-center'>Yes Rate, Stick Rage, Close Rate</h3>
                        <Chart
                            options={state.options as ApexOptions}
                            series={state.series}
                            type="bar"
                            height="350"
                        />
                    </Col>
                    <DashboardTooltip title="Year to Date Stat" displayData={displayData} size={3} />
                </Row>
                <Row>
                    {isLoading ? (
                        <Spinners />
                    ) :
                        <Card>
                            <CardBody>
                                <h2>Active Prospect</h2>
                                <SmartTable
                                    columns={columns}
                                    data={activeProspects || []}
                                    customPageSize={30}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    isShowingPageLength={true}
                                    customPageSizeOption={true}
                                    tableClass="table-bordered align-middle nowrap mt-2"
                                    paginationDiv="col-12"
                                    pagination="pagination justify-content-end pagination-rounded"
                                />
                            </CardBody>
                        </Card>
                    }
                </Row>
            </Container>
        </div>
    );
}

