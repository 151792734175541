import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

//import components
import Breadcrumbs from "Components/Common/Breadcrumb";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
import { MeetingForm } from "./MeetingForm";
import { useFetchMeetings } from "api";


export const  MeetingList = () => {
  //meta title
  document.title = "Meeting List | Ridgeline Metrics Dashboard ";

  const {isLoading, data: objects, refetch} = useFetchMeetings()


  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(null);


  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrent(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleEdit = useCallback(
    (arg: any) => {
      const job = arg;
      setCurrent(job);

      setIsEdit(true);

      toggle();
    },
    [toggle]
  );

  const handleCreate = () => {
    setIsEdit(false);
    setCurrent(null);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const row = cellProps.row.original;
                    handleEdit(row);
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleEdit]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Meeting List" />
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <Spinners  />
              ) : (
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <CardTitle tag="h5" className="mb-0 flex-grow-1">
                        Meeting List
                      </CardTitle>
                      <div className="flex-shrink-0">
                        <Link
                          to="#"
                          onClick={() => handleCreate()}
                          className="btn btn-success me-1"
                        >
                          {" "}
                          <i className="bx bx-plus-circle"></i> Create
                        </Link>
                        <Link onClick={() => refetch()} to="#" className="btn btn-light me-1">
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <SmartTable
                      columns={columns}
                      data={objects || []}
                      customPageSize={8}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isCustomFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      handleCreate={handleCreate}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    ></SmartTable>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Meeting" : "Add Meeting"}
            </ModalHeader>
            <ModalBody>
            <MeetingForm current={current} />
            </ModalBody>
          </Modal>
        </div>
      </div>
      
    </React.Fragment>
  );
}

