import PageHeader from "Components/Common/PageHeader";
import React, { useCallback, useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, ListGroup, ListGroupItem } from "reactstrap";
import Select from "react-select";
import { SmartSelect } from "Components/Common/SmartSelect";
import {
    useFetchMarketingSource,
    useCreateMarketingSource,
    useFetchEventTypes,
    useCreateEventType,
    useFetchEventLocations,
    useCreateEventLocation,
    useFetchReferralTypes,
    useCreateReferralType,
    useFetchResultTypes,
    useCreateResultType
} from "api";

const MarketingLists = () => {

    document.title = "Marketing Lists | Ridgeline Metrics Dashboard ";

    const createMarketingSoureMutation = useCreateMarketingSource();
    const createEventTypeMutation = useCreateEventType();
    const createEventLocationMutation = useCreateEventLocation();
    const createReferralTypeMutation = useCreateReferralType();
    const createResultTypeMutation = useCreateResultType();

    const { data: marketingSourceObjects } = useFetchMarketingSource();
    const { data: eventTypeObjects } = useFetchEventTypes();
    const { data: eventLocationObjects } = useFetchEventLocations();
    const { data: referralTypeObjects } = useFetchReferralTypes();
    const { data: resultTypeObjects } = useFetchResultTypes();
    const [modalTitle, setModalTitle] = useState('');
    const [label, setLabel] = useState('');
    const [inputName, setInputName] = useState('');
    const [modal, setModal] = useState<boolean>(false);
    const [inputtedData, setInputtedData] = useState('');

    const marketingSourceOptions = (marketingSourceObjects || [])?.map((obj) => ({
        value: obj.id,
        label: obj.name
    }));

    const eventTypeOptions = (eventTypeObjects || [])?.map((obj) => ({
        value: obj.id,
        label: obj.name
    }));

    const eventLocationOptions = (eventLocationObjects || [])?.map((obj) => ({
        value: obj.id,
        label: obj.name
    }));

    const referralTypeOptions = (referralTypeObjects || [])?.map((obj) => ({
        value: obj.id,
        label: obj.name
    }));

    const resultTypeOptions = (resultTypeObjects || [])?.map((obj) => ({
        value: obj.id,
        label: obj.name
    }));

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);

    const openModal = (title, label, inputName) => {
        setModalTitle(title);
        setLabel(label);
        setInputName(inputName);
        setModal(true);
    }

    const saveData = () => {
        if (inputtedData) {
            if (label === 'Marketing Source') {
                createMarketingSoureMutation.mutate({ name: inputtedData })
            }

            if (label === 'Event Type') {
                createEventTypeMutation.mutate({ name: inputtedData });
            }

            if (label === 'Location') {
                createEventLocationMutation.mutate({ name: inputtedData });
            }

            if (label === 'Referral Type') {
                createReferralTypeMutation.mutate({ name: inputtedData })
            }

            if (label === 'Results') {
                createResultTypeMutation.mutate({ name: inputtedData });
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeader title="Marketing Lists" />
                    <Row className="mb-4">
                        <Col sm={12} md={6} className="mb-4 mb-xs-4 mb-sm-4">
                            <h6>Marketing Source</h6>
                            <Row>
                                <Col xs={8} md={9}>
                                    {/* <select className="form-control form-select select2 mb-3 mb-xxl-0">
                                        {
                                            marketingSourceOptions.map((opt, i) => {
                                                return <option value={opt.value} key={i}>{opt.label}</option>
                                            })
                                        }
                                    </select> */}
                                    <ListGroup>
                                    {
                                        marketingSourceOptions.map((opt, i) => {
                                            return <ListGroupItem key={i}>{opt.label}</ListGroupItem>
                                        })
                                    }
                                    </ListGroup>
                                    
                                </Col>
                                <Col xs={4} md={3}>
                                    <Button
                                        type="button"
                                        color="success"
                                        className="mb-2 me-2"
                                        onClick={() => openModal('Add New Marketing Source', 'Marketing Source', 'marketingSource')}
                                    >
                                        {/* <i className="mdi mdi-plus me-1" /> */}
                                        Add New
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={6}>
                            <h6>Event Type</h6>
                            <Row>
                                <Col xs={8} md={9}>
                                    {/* <select className="form-control form-select select2 mb-3 mb-xxl-0">
                                        {
                                            eventTypeOptions.map((opt, i) => {
                                                return <option value={opt.value} key={i}>{opt.label}</option>
                                            })
                                        }
                                    </select> */}
                                    <ListGroup>
                                    {
                                        eventTypeOptions.map((opt, i) => {
                                            return <ListGroupItem key={i}>{opt.label}</ListGroupItem>
                                        })
                                    }
                                    </ListGroup>
                                </Col>
                                <Col xs={4} md={3}>
                                    <Button
                                        type="button"
                                        color="success"
                                        className="mb-2 me-2"
                                        onClick={() => openModal('Add New Event Type', 'Event Type', 'eventType')}
                                    >
                                        {/* <i className="mdi mdi-plus me-1" /> */}
                                        Add New
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={12} md={6} className="mb-4 mb-xs-4 mb-sm-4">
                            <h6>Location</h6>
                            <Row>
                                <Col xs={8} md={9}>
                                    {/* <select className="form-control form-select select2 mb-3 mb-xxl-0">
                                        {
                                            eventLocationOptions.map((opt, i) => {
                                                return <option value={opt.value} key={i}>{opt.label}</option>
                                            })
                                        }
                                    </select> */}
                                    <ListGroup>
                                    {
                                        eventLocationOptions.map((opt, i) => {
                                            return <ListGroupItem key={i}>{opt.label}</ListGroupItem>
                                        })
                                    }
                                    </ListGroup>
                                </Col>
                                <Col xs={4} md={3}>
                                    <Button
                                        type="button"
                                        color="success"
                                        className="mb-2 me-2"
                                        onClick={() => openModal('Add New Location', 'Location', 'location')}
                                    >
                                        {/* <i className="mdi mdi-plus me-1" /> */}
                                        Add New
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="6">
                            <h6>Referral Type</h6>
                            <Row>
                                <Col xs={8} md={9}>
                                    {/* <select className="form-control form-select select2 mb-3 mb-xxl-0">
                                        {
                                            referralTypeOptions.map((opt, i) => {
                                                return <option value={opt.value} key={i}>{opt.label}</option>
                                            })
                                        }
                                    </select> */}
                                    <ListGroup>
                                    {
                                        referralTypeOptions.map((opt, i) => {
                                            return <ListGroupItem key={i}>{opt.label}</ListGroupItem>
                                        })
                                    }
                                    </ListGroup>
                                </Col>
                                <Col xs={4} md={3}>
                                    <Button
                                        type="button"
                                        color="success"
                                        className="mb-2 me-2"
                                        onClick={() => openModal('Add New Referral Type', 'Referral Type', 'referralType')}
                                    >
                                        {/* <i className="mdi mdi-plus me-1" /> */}
                                        Add New
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={12} md={6}>
                            <h6>Results</h6>
                            <Row>
                                <Col xs={8} md={9}>
                                    {/* <select className="form-control form-select select2 mb-3 mb-xxl-0">
                                        {
                                            resultTypeOptions.map((opt, i) => {
                                                return <option value={opt.value} key={i}>{opt.label}</option>
                                            })
                                        }
                                    </select> */}
                                    <ListGroup>
                                    {
                                        resultTypeOptions.map((opt, i) => {
                                            return <ListGroupItem key={i}>{opt.label}</ListGroupItem>
                                        })
                                    }
                                    </ListGroup>
                                </Col>
                                <Col xs={4} md={3}>
                                    <Button
                                        type="button"
                                        color="success"
                                        className="mb-2 me-2"
                                        onClick={() => openModal('Add New Results', 'Results', 'results')}
                                    >
                                        {/* <i className="mdi mdi-plus me-1" /> */}
                                        Add New
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {modalTitle}
                        </ModalHeader>
                        <ModalBody>
                            <Label className="form-label" for={inputName}>{label}</Label>
                            <Input className="form-control" name={inputName} type="text" value={inputtedData} onChange={(e) => setInputtedData(e.target.value)} />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type="button"
                                color="success"
                                className="mb-2 me-2"
                                onClick={() => saveData()}
                            >
                                {/* <i className="mdi mdi-plus me-1" /> */}
                                Save
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MarketingLists;