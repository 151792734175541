import React, { useState } from "react";
import EventBy from "Components/Common/EventBy";
import { useFetchEventTypes } from "api";

const EventByType = () => {

    const { data: options } = useFetchEventTypes();

    return (

        <EventBy pageTitle="Event By Type" options={options} />
    )
}

export default EventByType;