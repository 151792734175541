import React from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";

//redux

import { Col, Row, Button } from "reactstrap";


import { SmartDatePicker } from "Components/Common/SmartDatePicker";

import { SmartSelect } from "Components/Common/SmartSelect";
import {
  useFetchMeetings,
  useFetchAdvisors,
  useUpdateProspect
} from "api";

function ProspectMeetingForm({ current }) {
 
  const updateProspectMutation = useUpdateProspect();

  const { data: meetings, isLoading: meetingLoading } =
    useFetchMeetings();
    const {data: advisors, isLoading: advisor_loading} = useFetchAdvisors()

  const handleSubmit = (values) => {
    if (current) {
      current.meetingDate = values.meetingDate;
      current.advisor= values.advisor;
      current.meeting = values.meeting;
      updateProspectMutation.mutate(current);
    }
  };
  const initialValues = {
    advisor: (current && current.advisor) || null,
    meetingDate: (current && current.meetingDate) || null,
    meeting: (current && current.meeting) || null,
  };
  const validationSchema = Yup.object({
    advisor: Yup.object().nullable(),
    meetingDate: Yup.date().nullable().required("Please Event Date"),
    meeting: Yup.object().nullable().required("Please Select Meeting"),
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        <Form>
          <Row>
            <SmartDatePicker name="meetingDate" label="Meeting Date" />
            <SmartSelect
              isDisabled={advisor_loading}
              isLoading={advisor_loading}
              options={advisors}
              optionLabelFn={(option) => option.fullName}
              label="Advisor"
              name="advisor"
            />
            <SmartSelect
              isDisabled={meetingLoading}
              isLoading={meetingLoading}
              options={meetings}
              label="Meeting"
              name="meeting"
            />
            
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <Button type="submit" color="success" className="save-user">
                  {" "}
                  <i className="bx bx-save"></i>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Formik>
    </React.Fragment>
  );
}

export default ProspectMeetingForm;
