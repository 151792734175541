import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";


import {
  Col,
  Row,
  Button,
} from "reactstrap";

import { SmartInput } from "Components/Common/SmartInput";
import { SmartSelect } from "Components/Common/SmartSelect";

import { useFetchFirms, useFetchRoles } from "api";
import { useCreateUser, useUpdateUser, useUpdateSubUser, useUploadProfileImage, useUploadSubProfileImage } from "api";

import { useSelector, shallowEqual } from "react-redux";
import { getUserInfo } from '../../../selectors';

function UserForm({current, toggle}) {
  const userInfo = useSelector(getUserInfo, shallowEqual);

  const bSuperPerm = userInfo?.roles?.some(role => role.name === "SUPER");

  const {data: firms, isLoading: firm_loading} = useFetchFirms()
  const {data: roles, isLoading: role_loading} = useFetchRoles()

  const defaultImage = "female.png";
  const imagePath = `/assets/${current?.profileImage || defaultImage}`;

  const createUserMutation = useCreateUser();
  const updateUserMutation = useUpdateUser();
  const updateUserSubMutation = useUpdateSubUser();
  const uploadImageMutation = useUploadProfileImage();
  const uploadSubImageMutation = useUploadSubProfileImage();

  const [userFirms, setUserFirms] = useState<any>([]);
  const [userRoles, setUserRoles] = useState<any>([]);
  const [previewSource, setPreviewSource] = useState<string | null>(null);

  const initialValues = {
    firm: (current && current.firm) || "",
    firstName: (current && current.firstName) || "",
    lastName: (current && current.lastName) || "",
    username: (current && current.username) || "",
    email: (current && current.email) || "",
    password: (current && current.password) || "",
    roles: (current && current.roles) || [],
    // profileImage: (current && current.profileImage) || "",
  };

  const validationSchema = Yup.object({
    firm: Yup.object().required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    username: Yup.string().required("Required"),
    password: current ? Yup.string().nullable().notRequired() : Yup.string().required("Required"),
    roles: Yup.array().required("Required"),
  });

  const handleSubmit = (values) => {
    if (current) {
      values.id = current.id;

      if (userInfo.id == current.id)
        updateUserMutation.mutate(values);
      else
        updateUserSubMutation.mutate(values);
    } else {
      createUserMutation.mutate(values);
    }

    toggle();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Show preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewSource(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);

      if (userInfo.id == current.id)
        uploadImageMutation.mutate({ id: current?.id, file: file });
      else
        uploadSubImageMutation.mutate({ id: current?.id, file: file });
    }
  };

  useEffect(() => {
    if (firms) {
      if ( !bSuperPerm ) {
        const filteredFirms = firms.filter(item => item.id === userInfo.firm.id);
        setUserFirms(filteredFirms);
      } else {
        setUserFirms(firms);
      }
    }
  }, [firms]);

  useEffect(() => {
    if (roles) {
      if (bSuperPerm) {
        setUserRoles(roles);
      } else {
        const filteredRoles = roles.filter(item => item.name !== "SUPER");
        setUserRoles(filteredRoles);
      }
    }
  }, [roles]);

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        <Form>
          {current && <div className="col-md-12 mb-3">
              <div className="input-group align-items-center justify-content-center">
                <div>
                    <img
                      src={previewSource || imagePath || ''}
                      alt="Profile"
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        objectFit: 'cover',
                        border: '2px solid #ddd'
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 20, verticalAlign: 'bottom' }}>
                    <input id="user-image-input" className="hidden-file-input" type="file" onChange={handleImageChange} />
                    <Button type="button" color="success" className="save-user" onClick={() => {document.getElementById('user-image-input')?.click();}}>
                      <i className="bx bx-save"></i>
                      Upload Image
                    </Button>
                  </div>
              </div>
          </div>
          }
          <SmartSelect name='firm' label='Firm' options={userFirms} isLoading={firm_loading} />
          <SmartInput name="firstName" label="First Name" />
          <SmartInput name="lastName"  label="Last Name" />
          <SmartInput name="email"  label="Email" />
          <SmartInput name="username"  label="Username" />
          <SmartInput name="password" type='password'  label="Password" />
          <SmartSelect name='roles' isDisabled={current?.id == 1} isMulti={true} label='Roles' options={userRoles} isLoading={role_loading} />
          <Row>
            <Col>
              <div className="text-end">
                <Button type="submit" color="success" className="save-user">
                  {" "}
                  <i className="bx bx-save"></i>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Formik>
    </React.Fragment>
  );
}

export default UserForm;