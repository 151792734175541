import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/csuite/firms`

const fetchFirmObjects = async () => await api.get(`${url}`, {});
const createFirmObject = async (obj) => await api.create(url, obj);
const updateFirmObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);
const deleteFirmObject = async (obj) => await api.delete(`${url}/${obj.id}`, null);

const key = 'firms'
const messageKey = 'Firm'

export const useFetchFirms = () => useQuery([key], fetchFirmObjects);

export const useCreateFirm = () => {
    const queryClient = useQueryClient();
    return useMutation(createFirmObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} created Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useUpdateFirm = () => {
    const queryClient = useQueryClient();
    return useMutation(updateFirmObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} updated Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useDeleteFirm = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteFirmObject, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} updated Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}