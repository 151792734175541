import React from 'react'
import Breadcrumbs from 'Components/Common/Breadcrumb';
import { useParams } from 'react-router-dom';

import { useFetchEventLocationById, useFetchProspectsByLocationId } from 'api';
import { MarketingStats } from 'Components/Common/StatDetailPage';

export const EventLocationStats = () => {
    document.title = "Event Location Stats | Ridgeline Metrics Dashboard ";
    const {id} = useParams()
    const { data: location} = useFetchEventLocationById(id ? parseInt(id) : 0)
    const {data, isLoading} = useFetchProspectsByLocationId(id ? parseInt(id) : 0)


  return (
    <React.Fragment>
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Settings" breadcrumbItem="Event Location Stats" />
        <MarketingStats isLoading={isLoading} prospects={data} title= {location?.name}/>
      </div>
    </div>
  </React.Fragment>
  )
}
