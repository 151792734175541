import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "Components/Common/Breadcrumb";
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
import FirmForm from "./FirmForm";
import { useFetchFirms, useUpdateFirm, useDeleteFirm } from "api";

function FirmList() {
  //meta title
  document.title = "Firm List | Ridgeline Metrics Dashboard ";

  const {data: objects, isLoading, refetch} = useFetchFirms();
  const updateFirmMutation = useUpdateFirm();
  const deleteFirmMutation = useDeleteFirm();

  const [modal, setModal] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(null);

  const [firmId, setFirmId] = useState(0);
  const [delModal, setDelModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrent(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const toggleDel = useCallback(() => {
    if (delModal) {
        setDelModal(false);
    } else {
      setDelModal(true);
    }
  }, [delModal]);

  const handleEdit = useCallback(
    (arg: any) => {
      const job = arg;
      setCurrent(job);
      toggle();
    },
    [toggle]
  );

  const handleEnable = (values) => {
    values.isActive = true;
    updateFirmMutation.mutate(values);
  };

  const handleDisable = (values) => {
    values.isActive = false;
    updateFirmMutation.mutate(values);
  };

  const handleCreate = () => {
    setCurrent(null);
    toggle();
  };

  const handleUserDelSubmit = () => {
    if (firmId) {
      deleteFirmMutation.mutate({
        "id": firmId,
      })
    }
    
    toggleDel();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Firm",
        accessor: "name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          if ( cellProps.row.original.isActive ) {
            return (
              <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const row = cellProps.row.original;
                      handleEdit(row);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const row = cellProps.row.original;
                      handleDisable(row);
                    }}
                  >
                    <i className="mdi mdi-close-circle" id="disabletooltip" />
                    <UncontrolledTooltip placement="top" target="disabletooltip">
                      Disable
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      setFirmId(cellProps.row.original.id);
                      toggleDel();
                    }}
                  >
                    <i className="mdi mdi-close" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            );
          } else {
            return (
              <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const row = cellProps.row.original;
                      handleEdit(row);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const row = cellProps.row.original;
                      handleEnable(row);
                    }}
                  >
                    <i className="mdi mdi-check-circle" id="enabletooltip" />
                    <UncontrolledTooltip placement="top" target="enabletooltip">
                      Enable
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      setFirmId(cellProps.row.original.id);
                      toggleDel();
                    }}
                  >
                    <i className="mdi mdi-close" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            );
          }          
        },
      },
    ],
    [handleEdit, toggleDel]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Firm List" />
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <Spinners  />
              ) : (
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <CardTitle tag="h5" className="mb-0 flex-grow-1">
                        Firm List
                      </CardTitle>
                      <div className="flex-shrink-0">
                        <Link
                          to="#"
                          onClick={() => handleCreate()}
                          className="btn btn-success me-1"
                        >
                          {" "}
                          <i className="bx bx-plus-circle"></i> Create
                        </Link>
                        <Link onClick={() => refetch()} to="#" className="btn btn-light me-1">
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <SmartTable
                      columns={columns}
                      data={objects || []}
                      customPageSize={8}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isCustomFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      handleCreate={handleCreate}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    ></SmartTable>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!current ? "Edit Firm" : "Add Firm"}
            </ModalHeader>
            <ModalBody>
            <FirmForm current={current} toggle={toggle} />
            </ModalBody>
          </Modal>
          <Modal isOpen={delModal} toggle={toggleDel}>
            <ModalHeader toggle={toggleDel} tag="h4">
              Are you sure you want to delete this Firm?
            </ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-evenly">
                <Button color="success" className="save-user" onClick={handleUserDelSubmit}>
                  <i className="bx bx-save"></i>{" "}
                  Yes
                </Button>
                <Button color="danger" className="save-user" onClick={toggleDel}>
                  <i className="bx bx-window-close"></i>{" "}
                  No
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FirmList;