import React from "react";
import { Link } from "react-router-dom";

//import components
import SidebarContent from "./SidebarContent";

//import images
import logo from "../../assets/images/logo.svg";
import logoDark from "../../assets/images/logo-dark.png";

import { useSelector } from 'react-redux';
import { getUserInfo } from '../../selectors';

const Sidebar = (props: any) => {
  const userInfo = useSelector(getUserInfo);

  const bCsuitePerm = userInfo?.roles?.some(role => role.name === "CSUITE");
  const bMarketPerm = userInfo?.roles?.some(role => role.name === "MARKETING");

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          { bCsuitePerm && 
          <Link to="/" className="logo logo-light">
            <span className="text-lg fs-3 text-white">Ridgeline</span>
          </Link>
          }
          { !bCsuitePerm && bMarketPerm && 
          <Link to="/marketing/dashboard" className="logo logo-light">
            <span className="text-lg fs-3 text-white">Ridgeline</span>
          </Link>
          }
          { !bCsuitePerm && !bMarketPerm && 
          <Link to="/advisor/dashboard" className="logo logo-light">
            <span className="text-lg fs-3 text-white">Ridgeline</span>
          </Link>
          }
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;