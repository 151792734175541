import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import {
  Col,
  Row,
  Button,
} from "reactstrap";

import { SmartInput } from "Components/Common/SmartInput";
import { SmartCurrency } from "Components/Common/SmartCurrency";
import { SmartDatePicker } from "Components/Common/SmartDatePicker";
import { SmartSelect } from "Components/Common/SmartSelect";
import { useFetchAdvisors, useFetchClients } from "api";
import { useCreateRevenue, useUpdateRevenue } from "api";
// import { useFetchGoals } from 'api/CSuite/GoalApi';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../selectors';

interface Client {
  id: number;
  fullName: string;
}

function RevenueForm({ current, toggle }) {
  const createRevenueMutation = useCreateRevenue()
  const updateRevenueMutation = useUpdateRevenue()
  const {data:advisors, isLoading: advisors_loading} = useFetchAdvisors()
  const {data:fetchedClients, isLoading: clients_loading} = useFetchClients()
  const [clients, setClients] = useState<Client[]>([]);

  const userInfo = useSelector(getUserInfo);

  const initialValues = {
    revenueType: (current && current.revenueType) || "Annuity",
    clientName: (current && current.prospect) || "",
    revenueDate: (current && current.revenueDate) || null,
    premium: (current && current.premium) || 0.0,
    revenue: (current && current.revenue) || 0.0,
    company: (current && current.company) || "",
    product: (current && current.product) || "",
    advisor: (current && current.advisor) || null,
    split: (current && current.split) || 0,
    detail: (current && current.detail) || "",
    prospect: null,
  };

  const validationSchema = Yup.object({
    revenueType: Yup.string().required("Select a Revenue Type"),
    clientName: Yup.object().when("revenueType", {
      is: (value) => {
        return value !== "AUM";
      },
      then: Yup.object().nullable().required("Client Name is Required"),
      otherwise: Yup.object().notRequired(),
    }),
    revenueDate: Yup.date().nullable().required("Revenue Date Required"),
    premium: Yup.number().when("revenueType", {
      is: (value) => {
        return value === "Annuity";
      },
      then: Yup.number().required("Total Premium is Required"),
      otherwise: Yup.number().notRequired(),
    }),
    revenue: Yup.number().when("revenueType", {
      is: (value) => {
        return (value === "Annuity" || value === "AUM");
      },
      then: Yup.number().required("Total Revenue is Required"),
      otherwise: Yup.number().notRequired(),
    }),    
    advisor: Yup.object().when("clientName", {
      is: (value) => {
        return (value?.id >= 0);
      },
      then: Yup.object().nullable().required("Select an Advisor"),
      otherwise: Yup.object().nullable().notRequired(),
    }),
    split: Yup.number().when("revenueType", {
      is: (value) => {
        return value === "MISC";
      },
      then: Yup.number().required("Desired Advisor Comp Split is Required"),
      otherwise: Yup.number().notRequired(),
    }),
    detail: Yup.string().when("revenueType", {
      is: (value) => {
        return value === "MISC";
      },
      then: Yup.string().nullable().required("Detail is Required"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  useEffect(() => {
    if (fetchedClients) {
            setClients([
                { id: 0, fullName: "Current Client" },
                { id: -1, fullName: "Other Source" },
                ...fetchedClients,
            ]);
    }
  }, [fetchedClients]);

  const handleSubmit = (values) => {
    if (values.revenueType === "Annuity" || values.revenueType === "MISC") {
      if (values.clientName.id > 0) {
        const clientInfo = fetchedClients.find(client => client.id === values.clientName.id);
        values.clientName = clientInfo.fullName;
        values.prospect = clientInfo;
      } else {
        values.clientName = values.clientName.fullName;
      }
    
      // const goalInfo = goals.find(goal => goal.advisor.id == values.advisor.id);
      // values.revenue = Math.round(values.premium * goalInfo.commission / 100);
    }
    values.firm = userInfo.firm;

    if (current) {
      values.id = current.id
      updateRevenueMutation.mutate(values, {
        onSuccess: () => {
          toggle(); // Close the modal after successful update
        },
      });
    } else {
      createRevenueMutation.mutate(values, {
        onSuccess: () => {
          toggle(); // Close the modal after successful creation
        },
      });
    }
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <SmartInput
              as="select"
              name="revenueType"
              label="Revenue Type"
              value={values.revenueType}
              onChange={(event) => setFieldValue('revenueType', event.target.value)}
            >
              <option value="">Select Revenue Type</option>
              <option value="Annuity">Annuity</option>
              <option value="AUM">AUM</option>
              <option value="MISC">MISC</option>
              {/* <option value="Life">Life</option>
              <option value="MYGA">MYGA</option> */}
            </SmartInput>
            {/* {values.revenueType !== "AUM" && <SmartInput name="clientName" label="Client Name" />} */}
            {values.revenueType !== "AUM" && <SmartSelect name="clientName" label="Client Name" isDisabled={clients_loading} options={clients} optionLabelFn={ (option) => option.fullName } />}
            <SmartDatePicker name="revenueDate" label="Date Paid" />
            {values.revenueType === "Annuity" && <SmartCurrency name="premium" label="Total Premium" />}
            <SmartCurrency name="revenue" label="Total Revenue" />
            {values.revenueType === "Annuity" && <SmartInput name="company" type="text" label="Company" />}
            {values.revenueType === "Annuity" && <SmartInput name="product" type="text" label="Product" />}
            <SmartSelect name="advisor" label="Advisor" isDisabled={advisors_loading} options={advisors} optionLabelFn={ (option) => option.fullName !== "null null" ? option.fullName : option.username } />
            {values.revenueType === "MISC" && <SmartInput name="split" type='number' label="Desired Advisor Comp Split" append='%' />}
            {values.revenueType === "MISC" && <SmartInput name="detail" type="text" label="Detail" />}
            <Row>
              <Col>
                <div className="text-end">
                  <Button type="submit" color="success" className="save-user">
                    {" "}
                    <i className="bx bx-save"></i>   
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default RevenueForm;
