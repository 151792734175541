export const currencyFormat = (value) => {
    if (!value) value = 0;
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(value);
};

export const numberFormat = (value) => {
    if (!value) value = 0;
    return value.toLocaleString('en-US');
};

export const numberDecimalFormat = (value, decimal) => {
    if (!value) value = 0;
    return value.toFixed(decimal).toLocaleString('en-US');
};