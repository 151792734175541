import { APIClient } from "helpers/api_helper";
import { useQuery } from "@tanstack/react-query";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/advisor/detailedMetrics`

const fetchDetailedMetrics = async (advisor) => await api.get(`${url}/all/${advisor}`, null);

export const useFetchDetailedMetrics = (advisor?) => {
    return useQuery({
        queryKey: ['csuite', 'detailedMetrics-' + advisor],
        queryFn: () => fetchDetailedMetrics(advisor || 0),
    });
}