import PageHeader from "Components/Common/PageHeader";
import React, { useEffect, useMemo, useState } from "react";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import SmartTable from "Components/Common/SmartTable";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import DashboardTooltip from "Components/Common/DashboardTooltip";
import { useFetchMarketingDashboardMetrics, useFetchProspects, useFetchProspectPipelineDashboard } from "api";

const ReferralDashboard = () => {

  document.title = "Referral Dashboard | Ridgeline Metrics Dashboard ";

  const { data } = useFetchMarketingDashboardMetrics();
  const { data: prospects } = useFetchProspects();
  const { data: metrics } = useFetchProspectPipelineDashboard();
  const [activeProspects, setActiveProspects] = useState<any>([]);

  const [outComeData, setOutComedata] = useState<any>([]);

  const notificationData = [
    {
      title: 'Total First Meetings Requested',
      value: data?.firstSet
    },
    {
      title: 'Total First Meetings Kept',
      value: data?.firstKept
    },
    {
      title: 'Total Active Prospects',
      value: activeProspects?.length
    },
    {
      title: 'Clients Made',
      value: data?.clientMade
    }
  ];

  useEffect(() => {
    if (prospects) {
      const activeProspects = prospects.filter((prospect) => prospect.isActive === true);
      
      setActiveProspects(activeProspects);
    }

    if(metrics) {
      const outcome = [metrics?.signed, metrics?.tud, metrics?.wtd, metrics?.hitters, metrics?.sorryLetter, metrics?.cancelledSecond];

      setOutComedata([...outcome]);
    }
  }, [prospects]);

  const columns = useMemo(
    () => [
      {
        Header: "Last Name",
        accessor: "lastName",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Type",
        accessor: "event.eventType.name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Advisor",
        accessor: "advisor.fullName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          if (cellProps?.row.original.advisor.fullName !== "null null")
            return <span>{cellProps?.row.original.advisor.fullName}</span>;
          else
            return <span>{cellProps?.row.original.advisor.username}</span>;
        },
      },
      {
        Header: "Investible Assets",
        accessor: "ipa",
        Filter: false,
      },
      {
        Header: "Meeting Type",
        accessor: "meeting.name",
        Filter: false,
        filterable: true,
        Cell: (props) => {
          return props.row.original.meeting?.name ? props.row.original.meeting?.name : <Badge color="soft-secondary">Pending</Badge>
        }
      },
      {
        Header: "Meeting Date",
        accessor: "meetingDate",
        Filter: false,
        filterable: true,
        Cell: (props) => {
          return props.row.original.meetingDate ? props.row.original.meetingDate : <Badge color="soft-secondary">Pending</Badge>
        }
      }
    ],
    []
  );

  const pieChart1State = {
    series: [7000, 0, 7000, 25000],
    options: {
      chart: {
        height: 250,
        type: 'pie',
      },
      labels: ['500k', '500k~1M', '1M~3M', '3M+'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 235
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      colors: ['#c8daf7', '#a1c2f2', '#689ee8', '#3378d4']
    }
  };

  const pieChart2State = {
    series: outComeData,
    options: {
      chart: {
        height: 480,
        type: 'pie',
      },
      labels: ['Signed', 'TUD', 'WTD', 'Hitters', 'Sorry Letter', 'Cancelled 2nd'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 260
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      colors: ['#3885f0', '#f0443b', '#fc9832', '#1da858', '#ff6e20', '#32bdc5']
    }
  };

  const displayData = [
    {
      title: 'First Meeting Stick',
      value: metrics?.firstStickRate + '%'
    },
    {
      title: 'Second Meeting Stick',
      value: metrics?.secondStickRate + '%'
    },
    {
      title: 'Signing Meeting Stick',
      value: '90%'
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <PageHeader title="Referral Dashboard" />
          <Row>
            {
              notificationData.map((data, index) => {
                return <NotificationMiniCard title={data.title} content={data.value} key={index} size={3} />
              })
            }
          </Row>
          <Row>
            <Card>
              <CardBody>
                <h2>Active Prospect</h2>
                <SmartTable
                  columns={columns}
                  data={activeProspects || []}
                  customPageSize={30}
                  isGlobalFilter={true}
                  isPagination={true}
                  isShowingPageLength={true}
                  customPageSizeOption={true}
                  tableClass="table-bordered align-middle nowrap mt-2"
                  paginationDiv="col-12"
                  pagination="pagination justify-content-end pagination-rounded"
                ></SmartTable>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Col sm={12} md={9}>
              <h3 className="mb-2">Sales Outcomes</h3>
              <Row>
                <Col sm={12} md={6} className="mb-4 mb-xs-4 mb-sm-4 px-md-30">
                  <h5 className="text-center">Closing By IPA</h5>
                  <Chart options={pieChart1State.options as ApexOptions} series={pieChart1State.series} type="pie" />
                </Col>
                <Col sm={12} md={6}>
                  <h5 className="text-center">Prospect Outcome</h5>
                  <Chart options={pieChart2State.options as ApexOptions} series={pieChart2State.series} type="pie" />
                </Col>
              </Row>
            </Col>
            <DashboardTooltip title="Meeting Kept Rates" displayData={displayData} size={3} />
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReferralDashboard;