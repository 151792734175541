import { APIClient } from "helpers/api_helper";
import { useQuery } from "@tanstack/react-query";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/advisor/prospects`
const key = 'dashboard'
const messageKey = 'Advisor Dashboard'

const fetchAdvisorDashboardMetrics = async (advisor) => await api.get(`${url}/dashboard/${advisor}`, null);
const fetchAdvisorProspectPipelineMetrics = async (advisor) => await api.get(`${url}/pipeline/${advisor}`, null);

export const useFetchAdvisorDashboardMetrics = (advisor?) => {
    return useQuery({
        queryKey: ['advisor', 'dashboard-' + advisor],
        queryFn: () => fetchAdvisorDashboardMetrics(advisor || 0),
    });
}

export const useFetchAdvisorProspectPipelineMetrics = (advisor?) => {
    return useQuery({
        queryKey: ['advisor', 'prospect-pipeline-' + advisor],
        queryFn: () => fetchAdvisorProspectPipelineMetrics(advisor || 0),
    });
}