import React from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Col, Row, Button } from "reactstrap";

import { useUpdateProspectSecondCancel } from "api";
import { SmartCheck } from "Components/Common/SmartCheck";

export const MeetingCancelForm = ({ current: prospect, rtoggle: toggle }) => {
  const updateProspectSecondCancel = useUpdateProspectSecondCancel();

  const initialValues = {
    tud: false,
    wtd: false,
  };

  const handleSubmit = (values) => {
    let cancelFlag = "CANCEL";
    if (values.tud)
      cancelFlag = "TUD";
    else if (values.wtd)
      cancelFlag = "WTD";
    
    values.id = prospect;
    updateProspectSecondCancel.mutate({id: prospect, flag: cancelFlag});
    toggle();
  };
  const validationSchema = Yup.object({
    tud: Yup.boolean().test(
      'tud-or-wtd',
      'Either TUD or WTD must be selected',
      function(value) {
        const { wtd } = this.parent;
        if (wtd && value) {
          return false;
        }
        return true;
      }
    ),
    wtd: Yup.boolean().test(
      'tud-or-wtd',
      'Either TUD or WTD must be selected',
      function(value) {
        const { tud } = this.parent;
        // console.log(value);
        if (tud && value) {
          return false;
        }
        return true;
      }
    ),
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Row className="px-md-4">
                <Col>
                  <SmartCheck name="tud" label="TUD" />
                </Col>
                <Col>
                  <SmartCheck name="wtd" label="WTD" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <Button type="submit" color="success" className="save-user">
                      {" "}
                      <i className="bx bx-save"></i>
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
