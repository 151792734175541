import React from 'react'
import Breadcrumbs from 'Components/Common/Breadcrumb';
import { useParams } from 'react-router-dom';

import {  useFetchPresenterById, useFetchProspectsByPresenterId } from 'api';
import { MarketingStats } from 'Components/Common/StatDetailPage';

export const PresenterStats = () => {
    document.title = "Presenter Stats | Ridgeline Metrics Dashboard ";
    const {id} = useParams()
    const { data: obj} = useFetchPresenterById(id ? parseInt(id) : 0)
    const {data, isLoading} = useFetchProspectsByPresenterId(id ? parseInt(id) : 0)


  return (
    <React.Fragment>
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Settings" breadcrumbItem="Presenter Stats" />
        <MarketingStats isLoading={isLoading} prospects={data} title= {obj?.fullName}/>
      </div>
    </div>
  </React.Fragment>
  )
}
