import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

//import components
import Breadcrumbs from "Components/Common/Breadcrumb";


import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
// import ReferralForm from "./ReferralForm";
import { useFetchMarketingDashboardMetrics, useFetchProspects, useFetchReferrals } from "api";
import PageHeader from "Components/Common/PageHeader";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";

function CsuiteProspectLists() {
  //meta title
  document.title = "Csuite All Prospects | Ridgeline Metrics Dashboard ";

  const navigate = useNavigate();

  const query = useFetchReferrals()

  const [modal, setModal] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(null);
  const { data: prospects } = useFetchProspects();
  const [activeProspects, setActiveProspects] = useState<any>([]);

  const { data } = useFetchMarketingDashboardMetrics();

  const notificationData = [
    {
      title: 'Total First Meetings Requested',
      value: data?.firstSet
    },
    {
      title: 'Total First Meetings Kept',
      value: data?.firstKept
    },
    {
      title: 'Total Active Prospects',
      value: activeProspects?.length
    },
    {
      title: 'Clients Made',
      value: data?.clientMade
    }
  ];

  useEffect(() => {
    if (prospects) {
      const activeProspects = prospects.filter((prospect) => prospect.isActive === true);
      
      setActiveProspects(activeProspects);
    }
  }, [prospects]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrent(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleProspectDetail = useCallback(
      (prospect: any) => {
          navigate(`/csuite/prospect/${prospect.id}/detail`);
      },
      [navigate]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Last Name",
        accessor: "lastName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
            return (
                <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={(e) => {
                        e.preventDefault();
                        const row = cellProps.row.original;
                        handleProspectDetail(row);
                    }}
                >
                    {cellProps.row.original.lastName}
                </Link>
            )
          }
      },
      {
        Header: "Source",
        accessor: "event.marketingSource.name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Type",
        accessor: "event.eventType.name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Advisor",
        accessor: "advisor.fullName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          if (cellProps?.row.original.advisor.fullName !== "null null")
            return <span>{cellProps?.row.original.advisor.fullName}</span>;
          else
            return <span>{cellProps?.row.original.advisor.username}</span>;
        },
      },
      {
        Header: "Investible Assets",
        accessor: "ipa",
        Filter: false,
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: false,
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <PageHeader title="Csuite All Prospects" />
          <Row>
            {
              notificationData.map((data, index) => {
                return <NotificationMiniCard title={data.title} content={data.value} key={index} size={3} />
              })
            }
          </Row>
          <Row>
            <Col lg={12}>
              {query.isLoading ? (
                <Spinners />
              ) : (
                <Card>
                  <Row>
                    <Card>
                      <CardBody>
                        <h2>Active Prospect</h2>
                        <SmartTable
                          columns={columns}
                          data={activeProspects || []}
                          customPageSize={30}
                          isGlobalFilter={true}
                          isPagination={true}
                          isShowingPageLength={true}
                          customPageSizeOption={true}
                          tableClass="table-bordered align-middle nowrap mt-2"
                          paginationDiv="col-12"
                          pagination="pagination justify-content-end pagination-rounded"
                        ></SmartTable>
                      </CardBody>
                    </Card>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
          {/* <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!current ? "Edit Marketing Referral" : "Add Marketing Referral"}
            </ModalHeader>
            <ModalBody>
            <ReferralForm current={current} />
            </ModalBody>
          </Modal> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default CsuiteProspectLists;
