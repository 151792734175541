import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import { Col, Row, Button } from "reactstrap";

import { SmartInput } from "Components/Common/SmartInput";

import { useCreatePresenter, useCreateUser, useUpdatePresenter, useUpdateUser } from "api";
import { SmartCheck } from "Components/Common/SmartCheck";

function UserForm({ current }) {
  const createUserMutation = useCreatePresenter();
  const updateUerMutation = useUpdatePresenter();

  const initialValues = {
    firstName: (current && current.firstName) || "",
    lastName: (current && current.lastName) || "",
    username: (current && current.username) || "",
    email: (current && current.email) || "",
    password: (current && current.password) || "",
    hasSplit: (current && current.hasSplit) || false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    username: Yup.string().required("Required"),
    password: current
      ? Yup.string().nullable().notRequired()
      : Yup.string().required("Required"),
      hasSplit: Yup.boolean(),
  });

  const handleSubmit = (values) => {
    if (current) {
      values.id = current.id;
      updateUerMutation.mutate(values);
    } else {
      values.roles = [{ id: null, name: "ADVISOR" }];
      createUserMutation.mutate(values);
    }
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
            <SmartInput name="firstName" label="First Name" />
            <SmartInput name="lastName" label="Last Name" />
            <SmartInput name="email" label="Email" />
            <SmartInput name="username" label="Username" />
            <SmartInput name="password" type="password" label="Password" />
            <SmartCheck name="hasSplit" label="Has Split?" />
            <SmartInput
                  type="number"
                  label="Compensation %"
                  name="compensation"
                />
            {values.hasSplit && (
              <>
                <SmartInput
                  type="number"
                  label="Production %"
                  name="production"
                />
                
              </>
            )}

            <Row>
              <Col>
                <div className="text-end">
                  <Button type="submit" color="success" className="save-user">
                    {" "}
                    <i className="bx bx-save"></i>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default UserForm;
