import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";
import { useNavigate, useLocation } from 'react-router-dom';

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/marketing/events`
const key = 'events'
const messageKey = 'Event'

const fetchObjects = async () => await api.get(`${url}/all`, null);
const createObject = async (obj) => await api.create(url, obj);
const updateObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);
const fetchMarketingDashboardStats = async(data) => await api.get(`${url}/dashboard/statistics`, data);
const fetchObject = async(id) => await api.get(`${url}/${id}`, null);
const fetchEventStatistics = async(id) => await api.get(`${url}/${id}/statistics`, null);
const fetchEventMetrics = async(id) => await api.get(`${url}/${id}/metrics`, null);
const fetchEventMetricsByType = async(id, type) => await api.get(`${url}/${id}/metrics/${type}`, null);
export const fetchEventById = async (id) => await api.get(`${url}/${id}`, null);

export const fetchEventInsideStatsById = async (id:any) => await api.get(`${url}/${id}/inside-stats`, null);

const fetchEventDashboardMetrics= async ()  => await api.get(`${url}/event/statistics`, null);

export const useFetchEventInsideStatsById = (id:number) => {
  return useQuery({
    queryKey: [key, id, 'inside-stats'],
    queryFn: () => fetchEventInsideStatsById(id),
    enabled: !!id
  });
};


export const useFetchEventDashboardMetrics = () => {
  return useQuery({
    queryKey: [key, 'event-dashboard'],
    queryFn: () => fetchEventDashboardMetrics()
  });
};

export const useFetchEventById = (id:number) => {
  return useQuery({
    queryKey: [key, id],
    queryFn: () => fetchEventById(id),
    enabled: !!id
  });
};


export const useFetchMarketingDashboardStats = (data) => {
  return useQuery([key, 'dashboard', data], () => fetchMarketingDashboardStats(data))
}

export const useFetchEventStatistics = (id) => {
  return useQuery([key, 'statistics', id], () => fetchEventStatistics(id), {
    enabled: !!id
  })
}

export const useFetchEvent = (id) => {
  return useQuery([key, id], () => fetchObject(id), {
    enabled: !!id
  })
}
export const useFetchEvents = () => {
    return useQuery([key], fetchObjects);
}

export const useCreateEvent = () => {
    const queryClient = useQueryClient();
    const history = useNavigate();
    return useMutation(createObject, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} created Successfully`);
            history(`/marketing/events/${data.id}/details`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useUpdateEvent = () => {
    const queryClient = useQueryClient();
    return useMutation(updateObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} updated Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useFetchEventMetrics = (id) => {
  return useQuery({
    queryKey: [key, id, 'metrics'],
    queryFn: () => fetchEventMetrics(id),
    enabled: !!id
  });
}

export const useFetchEventMetricsByType = (id, type='event') => {
  if (type == "Event By Source")
    type = "source";
  else if (type == "Event By Type")
    type = "type";
  else if (type == "Event By Location")
    type = "location";
  else if (type == "Event By Advisor")
    type = "advisor";
  else
    type = "event";
  
  return useQuery({
    queryKey: [key, id, 'metrics'],
    queryFn: () => fetchEventMetricsByType(id, type),
    enabled: !!id
  });
}