import { APIClient } from "helpers/api_helper";
import { useQuery } from "@tanstack/react-query";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/csuite/detailedMetrics`

const fetchDetailedMetrics = async () => await api.get(`${url}/all`, null);

export const useFetchDetailedMetrics = () => {
    return useQuery({
        queryKey: ['csuite', 'detailedMetrics'],
        queryFn: () => fetchDetailedMetrics(),
    });
}