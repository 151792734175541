import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { logoutUser } from "../../slices/thunk";

const Logout = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { isUserLogout } = useSelector((state: any) => ({
    isUserLogout: state.Login.isUserLogout,
  }), shallowEqual);

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  useEffect(() => {
    if (isUserLogout) {
      navigate("/login");
    }
  }, [isUserLogout, navigate]);

  return null; // No need to render anything
}

export default Logout;