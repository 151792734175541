import { useField } from "formik";
import React from "react";
import { Col, FormFeedback, FormGroup, Label } from "reactstrap";
import Select from "react-select";
export const SmartSelect = ({
  size = "12",
  label,
  options,
  optionLabelField = "name",
  optionLabelFn = null,
  ...props
}: any) => {
  const [field, meta, helpers] = useField(props);
  const customOptions = options
    ? options.map((option) => ({
        label: optionLabelFn ? optionLabelFn(option) : option[optionLabelField],
        value: option,
      }))
    : [];

    const styles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
  
      menu: base => ({
        ...base,
        borderRadius: '0px',
        marginTop: '0px',
        backgroundColor: '#fff',
        innerHeight: "30px"
      }),
    };

  return (
    <Col md={size}>
      <FormGroup className="mb-3">
        <Label htmlFor={props.id || props.name || label}>{label}</Label>
        <div
          className={
            meta.touched && meta.error
              ? "input-group is-invalid"
              : "input-group"
          }
        >
          <Select
            styles={styles}
            {...field}
            {...props}
            name={props.name ?? props.label}
            placeholder={props.placeholder || `Please select ${label}`}
            id={props.id || props.name || label}
            options={customOptions}
            onChange={(selectedOption: any) => {
              helpers.setValue(
                props.isMulti
                  ? selectedOption?.map((option: any) => option.value)
                  : selectedOption?.value
              );
              helpers.setTouched(true);
            }}
            onBlur={() => helpers.setTouched(true)}
            value={
              props.isMulti
                ? customOptions.filter((option) =>
                    field.value?.some(selectedOption => selectedOption.id === option.value?.id)
                  )
                : customOptions.find(
                    (option) => option.value?.id === field.value?.id
                  )
            }
            className={meta.touched && meta.error ? "is-invalid" : ""}
          />
        </div>

        {meta.touched && meta.error ? (
          <FormFeedback type="invalid">{meta.error}</FormFeedback>
        ) : null}
      </FormGroup>
    </Col>
  );
};
