import React from "react";
import EventBy from "Components/Common/EventBy";
import { useFetchAllPresenters } from "api";

const EventByPresenter = () => {

    const { data: options } = useFetchAllPresenters();

    return (

        <EventBy pageTitle="Event By Advisor" options={options} />
    )
}

export default EventByPresenter;