import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";
import { useDispatch } from "react-redux";
import { loginSuccess, editUser } from "slices/auth/login/reducer";
import { registerUserSuccessful } from "slices/auth/register/reducer";

import { useNavigate } from "react-router-dom";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/csuite/users`

const fetchObjects = async () => await api.get(`${url}/all`, null);
const createObject = async (obj) => await api.create(url, obj);
const updateObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);
const activeObject = async (obj) => await api.put(`${url}/active/${obj.id}`, null);
const deActiveObject = async (obj) => await api.put(`${url}/deactive/${obj.id}`, null);
const deleteObject = async (obj) => await api.delete(`${url}/${obj.id}`, null);
const fetchUsersByRole = async (role) => await api.get(`${url}/by-role`, {role});
const fetchUserByUsername = async (username) => await api.get(`${url}/username`, {username});
const fetchPresenterById = async (id) => await api.get(`${serverUrl}/marketing/presenters/${id}`, null);
const fetchPresenters = async () => await api.get(`${serverUrl}/marketing/presenters`, null);
const fetchAllPresenters = async () => await api.get(`${serverUrl}/marketing/presenters/all`, null);
const createPresenter = async(obj) => await api.create(`${serverUrl}/marketing/presenters/register`, obj);
const updadtePresenter = async(obj) => await api.put(`${serverUrl}/marketing/presenters/update/${obj.id}`, obj);
const fetchClients = async () => await api.get(`${url}/client`, null);
const fetchRoles = async () => await api.get(`${serverUrl}/auth/roles`, null);
const fetchImage = async (filename) => await api.get(`${url}/files/${filename}`, null);

const login = async (obj) => {
  localStorage.removeItem("authUser");
  return api.create(`${serverUrl}/auth/login`, obj);
};

const register = async (obj) => {
  return api.create(`${serverUrl}/auth/register`, obj);
};

const key = 'users'
const messageKey = 'User'

export const useFetchPresenters = () => useQuery({
  queryKey: [key, 'advisor'],
  queryFn: fetchPresenters
});

export const useFetchAllPresenters = () => useQuery({
  queryKey: [key, 'advisor-all'],
  queryFn: fetchAllPresenters
});

export const useFetchPresenterById = (id) => {
  return useQuery({
    queryKey: [key, id],
    queryFn: () => fetchPresenterById(id),
    enabled: !!id
  });
}

export const useFetchUserByUsername = (username) => {
  return useQuery([key, {username}], () => fetchUserByUsername(username));
}

export const useUserLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  return useMutation(login, {
      onSuccess: async (data) => {
        const { username } = data;
        const expirationTime = new Date().getTime() + 3600 * 1000;
        localStorage.setItem("authUser", JSON.stringify({...data, expirationTime}));
        
        // fetch user from db and set to redux
        const user = await fetchUserByUsername(username)
        dispatch(loginSuccess(user))

        const bCsuitePerm = user?.roles?.some(role => role.name === "CSUITE");
        const bMarketPerm = user?.roles?.some(role => role.name === "MARKETING");

        if ( bCsuitePerm )
          navigate('/csuite/dashboard')
        else if ( bMarketPerm )
          navigate('/marketing/dashboard')
        else
          navigate('/advisor/dashboard')
      },
      onError: (error) => {
        errorToast(error);
      }
  });
}

export const useUserRegister = () => {
  const dispatch = useDispatch();
  return useMutation(register, {
      onSuccess: async (data) => {
        const { username } = data;
        const expirationTime = new Date().getTime() + 3600 * 1000;
        localStorage.setItem("authUser", JSON.stringify({...data, expirationTime}));

        // fetch user from db and set to redux
        const user = await fetchUserByUsername(username)
        dispatch(registerUserSuccessful(user));
        
        // const bCsuitePerm = user?.roles?.some(role => role.name === "CSUITE");
        // const bMarketPerm = user?.roles?.some(role => role.name === "MARKETING");

        // if ( bCsuitePerm )
        //   navigate('/csuite/dashboard')
        // else if ( bMarketPerm )
        //   navigate('/marketing/dashboard')
        // else
        //   navigate('/advisor/dashboard')
      },
      onError: (error) => {
        errorToast(error);
      }
  });
}

export const useFetchUsers = () => useQuery([key], fetchObjects);
export const useFetchAdvisors = () => useQuery([key, 'advisor'], () => fetchUsersByRole('advisor'));
export const useFetchClients = () => useQuery([key, 'client'], () => fetchClients());
export const useFetchAllRoles = () => useQuery([key, 'roles'], () => fetchRoles());

export const useFetchImage = (filename) => {
  return useQuery(
    [key, 'profile'],
    () => (filename ? fetchImage(filename) : null),
  );
}

export const useCreateUser = () => {
    const queryClient = useQueryClient();
    return useMutation(createObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} created Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useCreatePresenter = () => {
  const queryClient = useQueryClient();
  return useMutation(createPresenter, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} created Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}

export const useUpdatePresenter = () => {
  const queryClient = useQueryClient();
  return useMutation(updadtePresenter, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} updated Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}

export const useUpdateSubUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateObject, {
      onSuccess: (data) => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} updated Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}

export const useUpdateUser = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(updateObject, {
      onSuccess: (data) => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} updated Successfully`);
          
          if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser") || "");
            obj.firstName = data.firstName;
            obj.lastName = data.lastName;
            obj.fullName = data.fullName;
            obj.email = data.email;
            obj.expirationTime = new Date().getTime() + 3600 * 1000;
            
            localStorage.setItem("authUser", JSON.stringify(obj));
          }

          dispatch(editUser(data));
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}

export const useActiveUser = () => {
  const queryClient = useQueryClient();
  return useMutation(activeObject, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} updated Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}

export const useDeActiveUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deActiveObject, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} updated Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteObject, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} deleted Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}

export const uploadProfileImage = async (data: any): Promise<any> => {
  const formData = new FormData();
  formData.append("file", data.file);
  return await api.upload(
    `${url}/upload?user=${data.id}`,
    formData
  );
};

export const useUploadSubProfileImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: uploadProfileImage,
    onSuccess: (data) => {
      successToast(`Profile Image Uploaded Successfully`);
      queryClient.invalidateQueries([key])
    },
    onError: (error) => {
      errorToast(error);
    }
  })
};

export const useUploadProfileImage = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: uploadProfileImage,
    onSuccess: (data) => {
      successToast(`Profile Image Uploaded Successfully`);
      queryClient.invalidateQueries([key])

      dispatch(editUser(data));
    },
    onError: (error) => {
      errorToast(error);
    }
  })
};