import { useField } from "formik";
import React from "react";
import { Button, Col, FormFeedback, FormGroup, Label } from "reactstrap";
//Import Flatepicker
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

export const SmartDatePicker = ({ size = "12", label, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  return (
    <Col md={size}>
      <FormGroup className="mb-3">
        <Label htmlFor={props.id || props.name || label}>{label}</Label>
        <div
          className={
            meta.touched && meta.error
              ? "input-group is-invalid"
              : "input-group"
          }
        >
          <Flatpickr
            options={{
              altInput: true,
              altFormat: "F j, Y",
              dateFormat: "Y-m-d",
              
            }}
            {...field}
            {...props}
            name={props.name ?? props.label}
            placeholder={props.placeholder || `Please enter ${label}`}
            className="form-control"
            id={props.id || props.name || label}
            onClose={(_, date, instance) => {
              if(meta.touched && meta.error) 
                instance.input.classList.add("is-invalid");
              else
              instance.input.classList.remove("is-invalid");
            

              helpers.setTouched(true);
            }}
            onChange={(_, date) => {
              helpers.setValue(date);
            }}
          />
          <Button color="light" onClick={() => helpers.setValue(null)} style={{ zIndex: 0 }}>X</Button>
        </div>

        {meta.touched && meta.error ? (
          <FormFeedback type="invalid">{meta.error}</FormFeedback>
        ) : null}
      </FormGroup>
    </Col>
  );
};
