import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import { Col, Row, Button } from "reactstrap";

import { SmartCurrency } from "Components/Common/SmartCurrency";
import { SmartSelect } from "Components/Common/SmartSelect";
import { SmartDatePicker } from "Components/Common/SmartDatePicker";
import { useFetchAdvisors } from "api";
import { useCreateAum, useUpdateAum } from "api";
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../selectors';

function AumForm({ current, toggle }) {
  const createAum = useCreateAum();
  const updateAum = useUpdateAum();
  const { data: advisors, isLoading: advisors_loading } = useFetchAdvisors();

  const userInfo = useSelector(getUserInfo);

  const initialValues = {
    advisor: (current && current.advisor) || null,
    balance: (current && current.total) || 0,
    aumDate: (current && current.aumDate) || "",
  };

  const validationSchema = Yup.object({
    advisor: Yup.object().nullable().required("Advisor is Required"),
    balance: Yup.number().required("Aum balance is required"),
    aumDate: Yup.date().nullable().required("Please Select Date"),
  });

  const handleSubmit = (values) => {
    values.firm = userInfo.firm;
    if (current) {
      values.id = current.id;
      updateAum.mutate(values);
    } else {
      createAum.mutate(values);
    }
    toggle();
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        <Form>
          <SmartSelect
            name="advisor"
            label="Advisor"
            isDisabled={advisors_loading}
            options={advisors}
            optionLabelFn={(option) => option.fullName !== "null null" ? option.fullName : option.username }
          />
          <SmartCurrency name="balance" label="Balance" />
          <SmartDatePicker name="aumDate" label="Date" />
          <Row>
            <Col>
              <div className="text-end">
                <Button type="submit" color="success" className="save-user">
                  {" "}
                  <i className="bx bx-save"></i>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Formik>
      {/* <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Add Advisor
        </ModalHeader>
        <ModalBody>
          <UserForm current={null} />
        </ModalBody>
      </Modal> */}
    </React.Fragment>
  );
}

export default AumForm;