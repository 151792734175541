import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/csuite/aums`

const fetchObjects = async () => await api.get(`${url}`, {});
const fetchObjectsByWeek = async (cur_date) => await api.get(`${url}/week/${cur_date}`, null);
const createObject = async (obj) => await api.create(url, obj);
const updateObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);

const key = 'aums'
const messageKey = 'Aum Balance'

export const useFetchAums = () => {
    return useQuery([key], fetchObjects);
}

export const useFetchAumsByWeek = (current_date) => {
  return useQuery({
      queryKey: [key, current_date],
      queryFn: () => fetchObjectsByWeek(current_date),
      onError: (error) => {
          errorToast(error);
      }
  })
}

export const useCreateAum = () => {
    const queryClient = useQueryClient();
    return useMutation(createObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} added Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useUpdateAum = () => {
    const queryClient = useQueryClient();
    return useMutation(updateObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} updated Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}