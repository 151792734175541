import { APIClient } from "helpers/api_helper";
import { useQuery } from "@tanstack/react-query";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/csuite/dashboard`
const key = 'dashboard'
const messageKey = 'Csuite Dashboard'

const fetchCsuiteDashboardMetrics = async () => await api.get(`${url}/metrics`, null);
const fetchCsuiteProspects = async (range) => await api.get(`${url}/prospects/${range}`, null);
const fetchCsuiteWeekProspects = async (date) => await api.get(`${url}/week/${date}`, null);

export const useFetchCsuiteDashboardMetrics = () => {
    return useQuery({
        queryKey: ['csuite', 'dashboard'],
        queryFn: () => fetchCsuiteDashboardMetrics(),
    });
}

export const useFetchCsuiteProspects = (range) => {
    return useQuery({
        queryKey: ['csuite', 'prospect-' + range],
        queryFn: () => fetchCsuiteProspects(range),
    });
}

export const useFetchCsuiteWeekProspects = (current_date) => {
    return useQuery({
        queryKey: ['csuite', current_date],
        queryFn: () => fetchCsuiteWeekProspects(current_date),
    });
}