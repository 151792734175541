import React from 'react';
import { useField } from 'formik';
import CurrencyInput from 'react-currency-input-field';
import { Col, FormFeedback, FormGroup, Label } from "reactstrap";

export const SmartCurrency = ({ 
  label,
  size = "12",
  ...props
}: any) => {
  const [field, meta, helpers] = useField(props);

  const handleValueChange = (value) => {
    helpers.setValue(Number(value));
  };

  return (
    <Col md={size}>
      <FormGroup className="mb-3">
        <Label htmlFor={props.id || props.name || label}>{label}</Label>
        <div className={
          meta.touched && meta.error
            ? "input-group is-invalid"
            : "input-group"
        }>
          <CurrencyInput
            id={props.id || props.name || label}
            className="form-control"
            prefix="$"
            allowDecimals={true}
            decimalsLimit={2}
            onValueChange={handleValueChange} 
            defaultValue={field.value} // Ensuring initial default value matches Formik's field
          />
        </div>
        {meta.touched && meta.error ? (
          <FormFeedback type="invalid">{meta.error}</FormFeedback>
        ) : null}
      </FormGroup>
    </Col>
  );
};