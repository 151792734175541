import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/settings/marketing-sources`
const fetchObjects = async () => await api.get(`${url}`, null);
const fetchEnableObjects = async () => await api.get(`${url}/list`, null);
const fetchObject = async (id) => await api.get(`${url}/${id}`, null);
const createObject = async (obj) => await api.create(url, obj);
const updateObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);
const disableObject = async (obj) => await api.put(`${url}/disable/${obj.id}`, null);
const enableObject = async (obj) => await api.put(`${url}/enable/${obj.id}`, null);

const key = 'marketing-source'
const messageKey = 'Marketing Source'

export const useFetchMarketingSourceById = (id) => {
  return useQuery({
    queryKey: [key, id],
    queryFn: () => fetchObject(id),
    enabled: !!id
  });
}

export const useFetchMarketingSource = () => {
    return useQuery([key], fetchObjects);
}

export const useFetchEnableMarketingSource = () => {
  return useQuery([key], fetchEnableObjects);
}

export const useCreateMarketingSource = () => {
    const queryClient = useQueryClient();
    return useMutation(createObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} created Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useUpdateMarketingSource = () => {
    const queryClient = useQueryClient();
    return useMutation(updateObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} updated Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useDisableMarketingSource = () => {
  const queryClient = useQueryClient();
  return useMutation(disableObject, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} updated Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}

export const useEnableMarketingSource = () => {
  const queryClient = useQueryClient();
  return useMutation(enableObject, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} updated Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}