import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/csuite/ranges`

const fetchRanges = async () => await api.get(`${url}`, null);
const fetchRangeCount = async () => await api.get(`${url}/count`, null);
const createRangeObject = async (obj) => await api.create(url, obj);
const updateRangeObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);
const deleteRangeObject = async (obj) => await api.delete(`${url}/${obj.id}`, null);

const key = 'ranges'
const messageKey = 'Range'

export const useFetchRanges = () => {
    return useQuery([key], fetchRanges);
}

export const useFetchRangeCount = () => {
  return useQuery({
    queryKey: ['ranges', 'count'],
    queryFn: () => fetchRangeCount(),
});
}

export const useCreateRange = () => {
    const queryClient = useQueryClient();
    return useMutation(createRangeObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} created Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useUpdateRange = () => {
    const queryClient = useQueryClient();
    return useMutation(updateRangeObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} updated Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useDeleteRange = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteRangeObject, {
      onSuccess: () => {
          queryClient.invalidateQueries([key])
          successToast(`${messageKey} deleted Successfully`);
        },
        onError: (error) => {
          errorToast(error);
        }
  });
}