import React from "react";
import EventBy from "Components/Common/EventBy";
import { useFetchMarketingSource } from "api";

const EventBySource = () => {

    const { data: options } = useFetchMarketingSource();

    return (
        <EventBy pageTitle="Event By Source" options={options} />
    )
}

export default EventBySource;