import React from "react";
import { Row, Button } from "reactstrap";

interface PageHeaderProps {
    title: string;
    onPrevWeek?: () => void;
    onNextWeek?: () => void;
    onCurrWeek?: () => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, onPrevWeek, onNextWeek, onCurrWeek }) => {
    return (
        <Row className='mb-2'>
            <div className="d-flex justify-content-center text-center position-relative">
                {onPrevWeek && (
                    <Button color="info" className="position-absolute start-0 ms-3 mt-2" onClick={onPrevWeek}>
                        {""}
                        Previous
                    </Button>
                )}
                <h2 className='text-center cursor-pointer' onClick={onCurrWeek}>{title}</h2>
                {onNextWeek && (
                    <Button color="info" className="position-absolute end-0 me-3 mt-2" onClick={onNextWeek}>
                        {""}
                        Next
                    </Button>
                )}
            </div>
        </Row>
    )
}

export default PageHeader;