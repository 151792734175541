import PageHeader from "Components/Common/PageHeader";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import SmartTable from "Components/Common/SmartTable";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import DashboardTooltip from "Components/Common/DashboardTooltip";
import { useFetchMarketingDashboardMetrics, useFetchProspects, useFetchProspectPipelineDashboard } from "api";

import { Link, useNavigate } from "react-router-dom";

interface Asset {
  rangeStr: string;
  value: number;
}

const ProspectPipeline = () => {
  document.title = "Prospect Pipeline | Ridgeline Metrics Dashboard ";

  const navigate = useNavigate();

  const { data } = useFetchMarketingDashboardMetrics();
  const { data: prospects } = useFetchProspects();
  const { data: metrics } = useFetchProspectPipelineDashboard();
  const [activeProspects, setActiveProspects] = useState<any>([]);

  const [outComeData, setOutComedata] = useState<any>([]);
  const [outComeDataType, setOutComedataType] = useState<any>([]);

  const [assetData, setAssetData] = useState<any>([]);
  const [assetDataType, setAssetDataType] = useState<any>([]);

  const [active, setActive] = useState(true);
  const notificationData = [
    {
      title: 'Total First Meetings Requested',
      value: data?.firstSet
    },
    {
      title: 'Total First Meetings Kept',
      value: data?.firstKept
    },
    {
      title: 'Total Active Prospects',
      value: data?.activeProsCnt
    },
    {
      title: 'Clients Made',
      value: data?.clientMade
    }
  ];

  useEffect(() => {
    if (prospects) {
      setActiveProspects(prospects.filter((prospect) => prospect.attended === "YES_AT_EVENT" && prospect.isActive));
    }

    if (metrics) {
      if (metrics.outcome) {
        let outComeType: string[] = [];
        let outCome: number[] = [];
        
        Object.entries(metrics?.outcome).forEach(([key, value]) => {
            outComeType.push(key);
            outCome.push(value as number);
        });

        setOutComedata([...outCome]);
        setOutComedataType([...outComeType]);
      }
      
      if (metrics.assets) {
        let assetType: string[] = [];
        let assets: number[] = [];
        
        Object.entries(metrics?.assets || {}).forEach(([key, asset]) => {
          const typedAsset = asset as Asset;
          assetType.push(typedAsset.rangeStr);
          assets.push(typedAsset.value);
        });

        setAssetData([...assets]);
        setAssetDataType([...assetType]);
      }
    }
  }, [prospects, metrics]);

  const handleProspectDetail = useCallback(
      (prospect: any) => {
          navigate(`/marketing/prospect/${prospect.id}/detail`);
      },
      [navigate]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Last Name",
        accessor: "lastName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
            return (
                <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={(e) => {
                        e.preventDefault();
                        const row = cellProps.row.original;
                        handleProspectDetail(row);
                    }}
                >
                    {cellProps.row.original.lastName}
                </Link>
            )
          }
      },
      {
        Header: "First Name",
        accessor: "firstName",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Type",
        accessor: "eventType.name",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          if (cellProps?.row.original.event !== null)
              return <span>{cellProps?.row.original.event.eventType?.name}</span>;
          else
              return <span>{cellProps?.row.original.eventType?.name}</span>;
        },
      },
      {
        Header: "Advisor",
        accessor: "advisor.fullName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          if (cellProps?.row.original.advisor?.fullName !== "null null")
            return <span>{cellProps?.row.original.advisor?.fullName}</span>;
          else
            return <span>{cellProps?.row.original.advisor?.username}</span>;
        },
      },
      {
        Header: "Investible Assets",
        accessor: "newMoney",
        Filter: false,
        Cell: ({ value }) => {
          const formattedValue = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }).format(value);
  
          return <span>{formattedValue}</span>;
      },
      },
      {
        Header: "Meeting Type",
        accessor: "meeting.name",
        Filter: false,
        filterable: true,
        Cell: (props) => {
          return props.row.original.meeting?.name ? props.row.original.meeting?.name : <Badge color="soft-secondary">Pending</Badge>
        }
      },
      {
        Header: "Meeting Date",
        accessor: "meetingDate",
        Filter: false,
        filterable: true,
        Cell: (props) => {
          return props.row.original.meetingDate ? props.row.original.meetingDate : <Badge color="soft-secondary">Pending</Badge>
        }
      }
    ],
    [handleProspectDetail]
  );

  const pieChart1State = {
    series: assetData,
    options: {
      chart: {
        height: 480,
        type: 'pie',
      },
      labels: assetDataType,
      tooltip: {
        enabled: true,
        y: {
            formatter: function (value) {
                return `$${value}`;
            }
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 300
          },
        }
      }],
      legend: {
        position: 'bottom'
      },
      colors: ['#007bff', '#28a745', '#dc3545', '#fd7e14', '#6f42c1', '#17a2b8']
    }
  };

  const pieChart2State = {
    series: outComeData,
    options: {
      chart: {
        height: 480,
        type: 'pie',
      },
      labels: outComeDataType,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 300
          },
        }
      }],
      legend: {
        position: 'bottom'
      },
      colors: ['#3885f0', '#f0443b', '#fc9832', '#1da858', '#ff6e20', '#32bdc5']
    }
  };

  const displayData = [
    {
      title: 'First Meeting Stick',
      value: data?.stickRate + '%'
    },
    {
      title: 'Second Meeting Stick',
      value: data?.secondStickRate + '%'
    },
    {
      title: 'Signing Meeting Stick',
      value: data?.signStickRate + '%'
    }
  ];

  function handleActiveChange(e) {
    setActive(e.target.checked);
    e.target.checked
      ? setActiveProspects(
        prospects.filter(
          (prospect) =>
            prospect.attended === "YES_AT_EVENT" &&
            prospect.isActive
        )
      )
      : setActiveProspects(
          prospects.filter(
            (prospect) => prospect.attended === "YES_AT_EVENT"
          )
      );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <PageHeader title="Prospect Pipeline" />
          <Row>
            {
              notificationData.map((data, index) => {
                return <NotificationMiniCard title={data.title} content={data.value} key={index} size={3} />
              })
            }
          </Row>
          <Row>
            <Col sm={12} md={9}>
              <h3 className="mb-2">Sales Outcomes</h3>
              <Row>
                <Col sm={12} md={6}>
                  <Card>
                    <CardBody>
                      <h5 className="text-center">Closing By Investible Assets</h5>
                      <Chart options={pieChart1State.options as ApexOptions} series={pieChart1State.series} type="pie" />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} md={6}>
                  <Card>
                    <CardBody>
                      <h5 className="text-center">Prospect Outcome</h5>
                      <Chart options={pieChart2State.options as ApexOptions} series={pieChart2State.series} type="pie" />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <DashboardTooltip title="Meeting Kept Rates" displayData={displayData} size={3} />
          </Row>
          <Row>
            <Card>
              <CardBody>
                <div>
                    <h2 style={{marginRight: "50px"}}>All Prospect</h2>
                    <div style={{fontSize: "1rem", padding: "0.5%"}}>
                      <label>Active</label>
                      <input type="checkbox" style={{marginLeft: "10px"}} checked={active} onChange={handleActiveChange}/>
                    </div>
                </div>
                <SmartTable
                  columns={columns}
                  data={activeProspects || []}
                  customPageSize={30}
                  isGlobalFilter={true}
                  isPagination={true}
                  isShowingPageLength={true}
                  customPageSizeOption={true}
                  tableClass="table-bordered align-middle nowrap mt-2"
                  paginationDiv="col-12"
                  pagination="pagination justify-content-end pagination-rounded"
                ></SmartTable>
              </CardBody>
            </Card>
          </Row>
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProspectPipeline;