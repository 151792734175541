import React from "react";
import { Col, Row } from "reactstrap";

const DashboardTooltip = ({ title, displayData, size }) => {
    return (
        <Col sm={12} md={size} className="mt-4 mt-xs-4 mt-sm-4">
            <h3 className="mb-4">{title}</h3>
            {
                displayData.map((data, index) => {
                    return (
                        <Row className="px-2" key={index}>
                            <Col xs={8} sm={8}>
                                <h5>{data.title}</h5>
                            </Col>
                            <Col className="d-flex align-items-center justify-content-end" xs={4} sm={4}>
                                <h5>{data.value}</h5>
                            </Col>
                        </Row>
                    )
                })
            }
        </Col>
    )
}

export default DashboardTooltip;