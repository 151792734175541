// import EcommerenceProducts from "src/pages/Ecommerence/EcommerenceProducts";
import { Navigate } from "react-router-dom";

//Authentication pages
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register"
import { TopicList } from "pages/Settings/EventTopic/TopicList";
import EventTypeList from "pages/Settings/EventType/EventTypeList";
import MarketingSourceList from "pages/Settings/MarketingSource/MarketingSourceList";
import EventLocationList from "pages/Settings/EventLocation/EventLocationList";
import EventDashboard from "pages/Marketing/Events/EventDashboard";
import MarketCreateEvent from "pages/Marketing/Events/CreateEvent";
import { EventDetails } from "pages/Marketing/Events/InsideEvent";
import ProspectPipeline from "pages/Marketing/Prospects/ProspectPipeline";
import { MeetingList } from "pages/Settings/Meeting/MeetingList";
import AllReferrals from "pages/Marketing/Referrals/AllReferrals";
import ReferralTypeList from "pages/Settings/ReferralType/ReferralTypeList";
import ReferralSourceList from "pages/Settings/ReferralSource/ReferralSourceList";
import AdvisorWeeklyReports from "pages/Advisor/WeeklyEntry/AdvisorWeelyReports";
import RevenueList from "pages/CSuite/Revenue/RevenueList";
import FirmList from "pages/CSuite/Firm/FirmList";
import UserList from "pages/CSuite/User/UserList";
import AumList from "pages/CSuite/Aum/AumList";
import { EventLocationStats } from "pages/Settings/EventLocation/EventLocationStats";
import { EventTypeStats } from "pages/Settings/EventType/EventTypeStats";
import { TopicStats } from "pages/Settings/EventTopic/TopicStats";
import { MarketingSourceStats } from "pages/Settings/MarketingSource/MarketingSourceStats";
import { PresenterStats } from "pages/Marketing/Presenter/PresenterStats";
import PresenterList from "pages/Marketing/Presenter/PresenterList";
import MetricsList from "pages/Marketing/Metrics/MetricsList";
import AdvisorProspectPipeline from "pages/Advisor/Prospects/AdvisorProspectPipeline";
import { MarketingDashboard } from "pages/Marketing/Dashboard";
import ProspectDetail from "pages/Marketing/Prospects/ProspectDetail";
import MarketingLists from "pages/Marketing/Lists/MarketingLists";
import EventByType from "pages/Marketing/Events/EventByType";
import EventBySource from "pages/Marketing/Events/EventBySource";
import EventByLocation from "pages/Marketing/Events/EventByLocation";
import EventByPresenter from "pages/Marketing/Events/EventByPresenter";
import ReferralDashboard from "pages/Marketing/Referrals/ReferralDashboard";
import AddProspect from "pages/Marketing/Prospects/AddProspect";
import AllProspects from "pages/Marketing/Prospects/AllProspects";
import AdvisorDashboard from "pages/Advisor/AdvisorDashboard";
import RevenueWeeklyReport from "pages/CSuite/Revenue/RevenueWeeklyReport";
import AdvisorProspectLists from "pages/Advisor/Prospects/AdvisorProspectLists";
import AdvisorProspectDetail from "pages/Advisor/Prospects/AdvisorProspectDetail";
import AdvisorDetailedMetrics from "pages/Advisor/AdvisorDetailedMetrics";
import CsuiteDashboard from "../pages/CSuite/Dashboard";
import CsuiteWeeklyReport from "pages/CSuite/CsuiteWeeklyReport";
import CsuiteProspectLists from "pages/CSuite/CsuiteProspectLists";
import CsuiteProspectPipeline from "pages/CSuite/CsuiteProspectPipeline";
import CsuitProspectDetail from "pages/CSuite/CsuitProspectDetail";
import CsuiteDetailedMetrics from "pages/CSuite/CsuiteDetailedMetrics";
import CsuiteFirmSetting from "pages/CSuite/CsuiteFirmSetting";

const authProtectedRoutes = [
  { path: "/csuite/dashboard", component: <CsuiteDashboard /> },
  { path: "/csuite/weekly/reports", component: <CsuiteWeeklyReport /> },
  { path: "/csuite/revenue/reports", component: <RevenueWeeklyReport /> },
  { path: "/csuite/prospects/list", component: <CsuiteProspectLists /> },
  { path: "/csuite/prospect/:id/detail", component: <CsuitProspectDetail /> },
  { path: "/csuite/prospects/pipeline", component: <CsuiteProspectPipeline /> },
  { path: "/csuite/prospects/detailedMetrics", component: <CsuiteDetailedMetrics /> },
  { path: "/csuite/firmSetting", component: <CsuiteFirmSetting /> },
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/marketing/events", component: <EventDashboard /> },
  { path: "/marketing/events/bytype", component: <EventByType /> },
  { path: "/marketing/events/bysource", component: <EventBySource /> },
  { path: "/marketing/events/bylocation", component: <EventByLocation /> },
  { path: "/marketing/events/bypresenter", component: <EventByPresenter /> },
  { path: "/marketing/events/:id/details", component: <EventDetails /> },
  { path: "/marketing/prospect/pipeline", component: <ProspectPipeline /> },
  { path: "/marketing/prospect/create", component: <AddProspect /> },
  { path: "/marketing/prospect/all", component: <AllProspects /> },
  { path: "/marketing/prospect/:id/detail", component: <ProspectDetail /> },
  { path: "/marketing/referrals/all", component: <AllReferrals /> },
  { path: "/marketing/referrals/dashboard", component: <ReferralDashboard /> },
  { path: "/marketing/presenters", component: <PresenterList /> },
  { path: "/marketing/dashboard", component: <MarketingDashboard /> },
  { path: "/marketing/events/create", component: <MarketCreateEvent /> },
  { path: "/marketing/lists", component: <MarketingLists /> },
  { path: "/marketing/metrics", component: <MetricsList /> },

  //advisor
  { path: "/advisor/dashboard", component: <AdvisorDashboard /> },
  { path: "/advisor/weekly/reports", component: <AdvisorWeeklyReports /> },
  { path: "/advisor/prospect/pipeline", component: <AdvisorProspectPipeline /> },
  { path: "/advisor/prospects/all", component: <AdvisorProspectLists /> },
  { path: "/advisor/revenue/reports", component: <RevenueWeeklyReport /> },
  { path: "/advisor/prospect/:id/detail", component: <AdvisorProspectDetail /> },
  { path: "/advisor/metrics", component: <AdvisorDetailedMetrics /> },

  { path: "/advisor/dashboard/:advisor", component: <AdvisorDashboard /> },
  { path: "/advisor/weekly/reports/:advisor", component: <AdvisorWeeklyReports /> },
  { path: "/advisor/prospect/pipeline/:advisor", component: <AdvisorProspectPipeline /> },
  { path: "/advisor/metrics/:advisor", component: <AdvisorDetailedMetrics /> },
  
  // CSuite
  { path: "/csuite/firm", component: <FirmList />},
  { path: "/csuite/users", component: <UserList />},
  { path: "/csuite/revenues", component: <RevenueList />},
  { path: "/csuite/aums", component: <AumList />},

  { path: "/settings/event-topics", component: <TopicList /> },
  { path: "/settings/event-types", component: <EventTypeList /> },
  { path: "/settings/event-sources", component: <MarketingSourceList /> },
  { path: "/settings/event-locations", component: <EventLocationList /> },
  { path: "/settings/refferal-types", component: <ReferralTypeList /> },
  { path: "/settings/refferal-sources", component: <ReferralSourceList /> },
  { path: "/settings/meetings", component: <MeetingList /> },

  { path: "/marketing/locations/:id/stats", component: <EventLocationStats />},
  { path: "/marketing/presenters/:id/stats", component: <PresenterStats />},
  { path: "/marketing/event-types/:id/stats", component: <EventTypeStats />},
  { path: "/marketing/topics/:id/stats", component: <TopicStats />},
  { path: "/marketing/marketing-sources/:id/stats", component: <MarketingSourceStats />},


  { path: "/profile", component: <UserProfile /> },
  { path: "/", exact: true, component: <Navigate to="/csuite/dashboard" /> },
  { path: "*", component: <PagesComingsoon /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  { path: "/auth-two-step-verification-2", component: <TwostepVerification2 /> },
]
export { authProtectedRoutes, publicRoutes };
