import React, { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from "Components/Common/Breadcrumb";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
import { useFetchMetrics } from "api";
import TableContainer from "Components/Common/TableContainer";

function MetricsList() {
  //meta title
  document.title = "Metrics List | Ridgeline Metrics Dashboard ";

  const [source, setSource] = useState("event_type");

  const { data, isLoading, refetch } = useFetchMetrics(source);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Registration Rate",
        accessor: "registrationRate",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Attendance Rate",
        accessor: "attendanceRate",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Stick Rate",
        accessor: "stickRate",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Close Rate",
        accessor: "closeRate",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Signed Rate",
        accessor: "signedRate",
        Filter: false,
        filterable: true,
      },
    ],
    []
  );

  const onSourceChange = (e: any) => {
    setSource(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Metrics" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <CardTitle tag="h5" className="mb-0 flex-grow-1">
                      Metrics
                    </CardTitle>
                    <div className="flex-shrink-0">
                      <div className="input-group" onChange={onSourceChange}>
                        <select
                          className="form-control"
                          style={{ minWidth: "300px" }}
                        >
                          <option value="event_type">Event Type</option>
                          <option value="source_type">Marketing Source</option>
                          <option value="topic">Topic</option>
                          <option value="location">Location</option>
                          <option value="presenter">Presenter</option>
                        </select>
                        <Button
                          onClick={() => refetch()}
                          className="btn btn-light me-1"
                        >
                          <i className="mdi mdi-refresh"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              {isLoading ? (
                <Spinners />
              ) : (
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={data}
                      customPageSize={10}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isCustomFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    ></TableContainer>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MetricsList;
