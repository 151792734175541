import React, { useEffect, useRef, useCallback } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../selectors';
import { useFetchAllPresenters } from "api";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const metisMenuRef = useRef<MetisMenu | null>(null);
  const navigate = useNavigate();
  const userInfo = useSelector(getUserInfo);
  const { data: menuAdvisors } = useFetchAllPresenters();

  const bCsuitePerm = userInfo?.roles?.some(role => role.name === "CSUITE");
  const bMarketPerm = userInfo?.roles?.some(role => role.name === "MARKETING");
  const bAdvisorPerm = userInfo?.roles?.some(role => role.name === "ADVISOR");

  useEffect(() => {
    if (!metisMenuRef.current) {
      metisMenuRef.current = new MetisMenu("#side-menu");
    }

    return () => {
      if (metisMenuRef.current) {
        metisMenuRef.current.dispose();
        metisMenuRef.current = null;
      }
    };
  }, [userInfo, bCsuitePerm, bMarketPerm, bAdvisorPerm, menuAdvisors]);

  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback((e) => {
    // const pathName = props.router.location.pathname;
    e.preventDefault();
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    matchingMenuItem = e.target;
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

    navigate(e.target.getAttribute('href'));
  }, [activateParentDropdown, navigate]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            { bCsuitePerm &&
            <>
              <li className="menu-title" key="title-csuite">{props.t("Csuite")} </li>
              <li key="csuite">
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-view-dashboard"></i>
                  <span>{props.t("Csuite Menu")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/csuite/dashboard" onClick={(e) => activeMenu(e)}>
                      {props.t("Csuite Dashboard")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/csuite/weekly/reports" onClick={(e) => activeMenu(e)}>
                      {props.t("Firm Weekly Summary")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/csuite/revenue/reports" onClick={(e) => activeMenu(e)}>
                      {props.t("Revenue Weekly Reports")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/csuite/prospects/pipeline" onClick={(e) => activeMenu(e)}>
                      {props.t("Csuite All Prospects")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/csuite/prospects/detailedMetrics" onClick={(e) => activeMenu(e)}>
                      {props.t("Detailed Metrics")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/csuite/firmSetting" onClick={(e) => activeMenu(e)}>
                      {props.t("Firm Settings")}
                    </Link>
                  </li>
                </ul>
              </li>
            </>
            }

            { bMarketPerm &&
            <>
              <li className="menu-title" key="title-marketing">{props.t("Marketing")} </li>
              <li key="marketing">
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-view-dashboard"></i>
                  <span>{props.t("Marketing Menu")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/marketing/dashboard" onClick={(e) => activeMenu(e)}>
                      {props.t("Marketing Dashboard")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketing/events" onClick={(e) => activeMenu(e)}>
                      {props.t("Event Dashboard")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketing/events/create" onClick={(e) => activeMenu(e)}>
                      {props.t("Add New Event")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketing/prospect/pipeline" onClick={(e) => activeMenu(e)}>
                      {props.t("Prospect Pipeline")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketing/prospect/create" onClick={(e) => activeMenu(e)}>
                      {props.t("Add New Prospect")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="mdi mdi-order-bool-descending"></i>
                      <span>{props.t("Event Comparison")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/marketing/events/bysource" onClick={(e) => activeMenu(e)}>
                          {props.t("Events By Source")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/marketing/events/bytype" onClick={(e) => activeMenu(e)}>
                          {props.t("Events By Type")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/marketing/events/bylocation" onClick={(e) => activeMenu(e)}>
                          {props.t("Events By Location")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/marketing/events/bypresenter" onClick={(e) => activeMenu(e)}>
                          {props.t("Events By Advisor")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </>
            }

            { bCsuitePerm &&
            <>
              <li className="menu-title" key="title-advisor">{props.t("Advisor")} </li>
              {
                menuAdvisors?.map((advisor, index) => {
                  return <>
                    <li key={index + 10000}>
                      <Link to="/#" className="has-arrow">
                        <i className="mdi mdi-view-dashboard"></i>
                        <span>{advisor?.fullName !== "null null" ? advisor?.fullName : advisor?.username}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={`/advisor/dashboard/${advisor.id}`} onClick={(e) => activeMenu(e)}>
                            {props.t("Advisor Dashboard")}
                          </Link>
                        </li>
                        <li>
                          <Link to={`/advisor/weekly/reports/${advisor.id}`} onClick={(e) => activeMenu(e)}>
                            {props.t("Advisor Weekly Reports")}
                          </Link>
                        </li>
                        <li>
                          <Link to={`/advisor/prospect/pipeline/${advisor.id}`} onClick={(e) => activeMenu(e)}>
                            {props.t("Advisor Prospect Pipeline")}
                          </Link>
                        </li>
                        <li>
                          <Link to={`/advisor/metrics/${advisor.id}`} onClick={(e) => activeMenu(e)}>
                            {props.t("Detailed Metrics")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </>
                })
              }
            </>
            }

            { !bCsuitePerm && bAdvisorPerm &&
            <>
              <li className="menu-title" key="title-advisor">{props.t("Advisor")} </li>
              <li key="advisor">
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-view-dashboard"></i>
                  <span>{props.t("Advisor Menu")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/advisor/dashboard" onClick={(e) => activeMenu(e)}>
                      {props.t("Advisor Dashboard")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/advisor/weekly/reports" onClick={(e) => activeMenu(e)}>
                      {props.t("Advisor Weekly Reports")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/advisor/prospect/pipeline" onClick={(e) => activeMenu(e)}>
                      {props.t("Advisor Prospect Pipeline")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/advisor/metrics" onClick={(e) => activeMenu(e)}>
                      {props.t("Detailed Metrics")}
                    </Link>
                  </li>
                </ul>
              </li>
            </>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
