import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logoutUser } from "./thunk";

const useAuthorizationCheck = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthorization = () => {
            if (localStorage.getItem("authUser")) {
                const authUser = JSON.parse(localStorage.getItem("authUser") || "");

                const { expirationTime } = authUser;
                const currentTime = new Date().getTime();

                if (currentTime > expirationTime) {
                    console.log("check authorization - logout")
                    dispatch(logoutUser());
                    navigate("/login");
                }
            }
        };

        // Check authorization every minute
        const intervalId = setInterval(checkAuthorization, 60000);

        // Initial check
        checkAuthorization();

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch, navigate]);
};

export default useAuthorizationCheck;