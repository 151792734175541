import PageHeader from "Components/Common/PageHeader";
import { SmartInput } from "Components/Common/SmartInput";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { SmartSelect } from "Components/Common/SmartSelect";
import { useCreateProspect, useFetchPresenters, useFetchReferralTypes } from "api";
import { SmartCreatable } from "Components/Common/SmartCreatable";
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../selectors';

const AddProspect = () => {
    document.title = "Add Prospect | Ridgeline Metrics Dashboard ";

    const userInfo = useSelector(getUserInfo);

    const createProspectMutation = useCreateProspect();
    const { data: event_types, isLoading: event_type_loading } =  useFetchReferralTypes();

    const { isLoading: advisors_loading, data: advisors } = useFetchPresenters();

    const initialValues = {
        lastName: '',
        firstName: '',
        spouseLastName: '',
        spouseFirstName: '',
        advisor: null,
        eventType: '',
        attended: 'YES_AT_EVENT',
    };

    const validationSchema = Yup.object({
        lastName: Yup.string().required("Please Input Last Name"),
        firstName: Yup.string().required("Please Input First Name"),
        // advisor: Yup.object().required("Please Input Advisor"),
        eventType: Yup.object().required("Please Select Event Type")
    });

    const handleSubmit = (values) => {
        values.firm = userInfo.firm;
        createProspectMutation.mutate(values);
    }    

    return (
        <React.Fragment>
            <div className="page-content">
                <PageHeader title="Add New Prospect" />
                <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values: any) => handleSubmit(values)}
                >
                    <Form>
                        <Row>
                            <SmartInput
                                type="text"
                                name="lastName"
                                label="Last Name"
                                size="6"
                            />
                            <SmartInput
                                type="text"
                                name="firstName"
                                label="First Name"
                                size="6"
                            />
                        </Row>
                        <Row>
                            <SmartInput
                                type="text"
                                name="spouseLastName"
                                label="Spouse Last Name"
                                size="6"
                            />
                            <SmartInput
                                type="text"
                                name="spouseFirstName"
                                label="Spouse First Name"
                                size="6"
                            />
                        </Row>
                        <Row>
                            <SmartSelect
                                isDisabled={advisors_loading}
                                isLoading={advisors_loading}
                                isClearable={true}
                                options={advisors}
                                label="Advisor"
                                name="advisor"
                                optionLabelFn={(option) => option.fullName !== "null null" ? option.fullName : option.username}
                                size="6"
                            />
                            <SmartCreatable
                                isDisabled={event_type_loading}
                                isLoading={event_type_loading}
                                isClearable={true}
                                options={event_types}
                                label="Referral Type"
                                name="eventType"
                                size={6}
                            />
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end mt-5 mb-2">
                                    <Button type="submit" color="success" className="save-user">
                                        <i className="bx bx-save"></i>{" "}
                                        Save
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </div>
        </React.Fragment>
    )
}

export default AddProspect;