import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

//import components
import Switch from "react-switch";

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Label
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";
import { useFetchProspects, useFetchReferrals, currencyFormat, numberFormat } from "api";
import PageHeader from "Components/Common/PageHeader";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";

import { useFetchDetailedMetrics } from "api/Advisor/DetailedMetricsApi";
import { useFetchGoals } from 'api/CSuite/GoalApi';
import { useParams } from 'react-router-dom';

function quarterToString(quarter: number): string {
  const quarterMap: { [key: number]: string } = {
    1: '1st Quarter',
    2: '2nd Quarter',
    3: '3rd Quarter',
    4: '4th Quarter'
  };

  return quarterMap[quarter] || 'Unknown Quarter';
}

function AdvisorDetailedMetrics() {
  //meta title
  document.title = "Csuite All Prospects | Ridgeline Metrics Dashboard ";

  const { advisor } = useParams();
  const { data: metrics, isLoading: data_loading } = useFetchDetailedMetrics(advisor);

  const [newAsset, setNewAsset] = useState<any>(null);
  const [totalRevenue, setTotalRevenue] = useState<any>(null);
  const [clientMade, setClientMade] = useState<any>(null);

  const notificationData = [
    {
      title: 'Current Quarter',
      value: quarterToString(Math.floor(new Date().getMonth() / 3) + 1)
    },
    {
      title: 'Total New Assets',
      value: currencyFormat(newAsset)
    },
    {
      title: 'Total Revenue',
      value: currencyFormat(totalRevenue)
    },
    {
      title: 'Assets Under Management',
      value: currencyFormat(clientMade)
    }
  ];

  useEffect(() => {
    if (metrics && metrics.summary) {
      setNewAsset(metrics.summary[0].newAssets);
      setTotalRevenue(metrics.summary[0].totalRevenue);
      setClientMade(metrics.summary[0].underManage);
    }
  }, [metrics]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <PageHeader title="Detailed Metrics" />
          <Row>
            {
              notificationData.map((data, index) => {
                return <NotificationMiniCard title={data.title} content={data.value} key={index} size={3} />
              })
            }
          </Row>
          <Row>
            <Col lg={12}>
              {data_loading ? (
                <Spinners />
              ) : (
                <Card>
                  <Row>
                    <Card>
                      <CardBody>
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th className="thead col-2"></th>
                              <th className="col-2">Year to Date</th>
                              <th className="col-2">1st Quarter</th>
                              <th className="col-2">2nd Quarter</th>
                              <th className="col-2">3rd Quarter</th>
                              <th className="col-2">4th Quarter</th>  
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="parent-category" colSpan={6}>Written Business</td>
                            </tr>
                            { metrics?.business.map((item, index) =>
                              <tr key={index}>
                                <td style={{paddingLeft: "15px", height: "20px"}}>{item.title}</td>
                                {index % 4 === 2 && index < 8 ?
                                  <>
                                    <td>{(item.year !== "") ? numberFormat(item.year) + "%" : ""}</td>
                                    <td>{(item.quartar1 !== "") ? numberFormat(item.quartar1) + "%" : ""}</td>
                                    <td>{(item.quartar2 !== "") ? numberFormat(item.quartar2) + "%" : ""}</td>
                                    <td>{(item.quartar3 !== "") ? numberFormat(item.quartar3) + "%" : ""}</td>
                                    <td>{(item.quartar4 !== "") ? numberFormat(item.quartar4) + "%" : ""}</td>
                                  </>
                                  :
                                  <>
                                    <td>{(item.year !== "") ? currencyFormat(item.year) : ""}</td>
                                    <td>{(item.quartar1 !== "") ? currencyFormat(item.quartar1) : ""}</td>
                                    <td>{(item.quartar2 !== "") ? currencyFormat(item.quartar2) : ""}</td>
                                    <td>{(item.quartar3 !== "") ? currencyFormat(item.quartar3) : ""}</td>
                                    <td>{(item.quartar4 !== "") ? currencyFormat(item.quartar4) : ""}</td>  
                                  </>
                                }
                              </tr>
                            )}
                            <tr>
                              <td className="parent-category" colSpan={6}>Appointments</td>
                            </tr>
                            <tr key={0}>
                              <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[0].title}</td>
                              <td>{(metrics?.appointment[0].year !== "") ? numberFormat(metrics?.appointment[0].year) : ""}</td>
                              <td>{(metrics?.appointment[0].quartar1 !== "") ? numberFormat(metrics?.appointment[0].quartar1) : ""}</td>
                              <td>{(metrics?.appointment[0].quartar2 !== "") ? numberFormat(metrics?.appointment[0].quartar2) : ""}</td>
                              <td>{(metrics?.appointment[0].quartar3 !== "") ? numberFormat(metrics?.appointment[0].quartar3) : ""}</td>
                              <td>{(metrics?.appointment[0].quartar4 !== "") ? numberFormat(metrics?.appointment[0].quartar4) : ""}</td>
                            </tr>
                            <tr key={1}>
                              <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[1].title}</td>
                              <td>{(metrics?.appointment[1].year !== "") ? numberFormat(metrics?.appointment[1].year) : ""}</td>
                              <td>{(metrics?.appointment[1].quartar1 !== "") ? numberFormat(metrics?.appointment[1].quartar1) : ""}</td>
                              <td>{(metrics?.appointment[1].quartar2 !== "") ? numberFormat(metrics?.appointment[1].quartar2) : ""}</td>
                              <td>{(metrics?.appointment[1].quartar3 !== "") ? numberFormat(metrics?.appointment[1].quartar3) : ""}</td>
                              <td>{(metrics?.appointment[1].quartar4 !== "") ? numberFormat(metrics?.appointment[1].quartar4) : ""}</td>
                            </tr>
                            <tr key={2}>
                              <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[2].title}</td>
                              <td>{(metrics?.appointment[2].year !== "") ? numberFormat(metrics?.appointment[2].year) : ""}</td>
                              <td>{(metrics?.appointment[2].quartar1 !== "") ? numberFormat(metrics?.appointment[2].quartar1) : ""}</td>
                              <td>{(metrics?.appointment[2].quartar2 !== "") ? numberFormat(metrics?.appointment[2].quartar2) : ""}</td>
                              <td>{(metrics?.appointment[2].quartar3 !== "") ? numberFormat(metrics?.appointment[2].quartar3) : ""}</td>
                              <td>{(metrics?.appointment[2].quartar4 !== "") ? numberFormat(metrics?.appointment[2].quartar4) : ""}</td>
                            </tr>
                            <tr key={3}>
                              <td style={{paddingLeft: "15px", height: "20px"}}></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr key={4}>
                              <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[4].title}</td>
                              <td>{(metrics?.appointment[4].year !== "") ? numberFormat(metrics?.appointment[4].year) + "%" : ""}</td>
                              <td>{(metrics?.appointment[4].quartar1 !== "") ? numberFormat(metrics?.appointment[4].quartar1) + "%" : ""}</td>
                              <td>{(metrics?.appointment[4].quartar2 !== "") ? numberFormat(metrics?.appointment[4].quartar2) + "%" : ""}</td>
                              <td>{(metrics?.appointment[4].quartar3 !== "") ? numberFormat(metrics?.appointment[4].quartar3) + "%" : ""}</td>
                              <td>{(metrics?.appointment[4].quartar4 !== "") ? numberFormat(metrics?.appointment[4].quartar4) + "%" : ""}</td>
                            </tr>
                            <tr key={5}>
                              <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[5].title}</td>
                              <td>{(metrics?.appointment[5].year !== "") ? numberFormat(metrics?.appointment[5].year) + "%" : ""}</td>
                              <td>{(metrics?.appointment[5].quartar1 !== "") ? numberFormat(metrics?.appointment[5].quartar1) + "%" : ""}</td>
                              <td>{(metrics?.appointment[5].quartar2 !== "") ? numberFormat(metrics?.appointment[5].quartar2) + "%" : ""}</td>
                              <td>{(metrics?.appointment[5].quartar3 !== "") ? numberFormat(metrics?.appointment[5].quartar3) + "%" : ""}</td>
                              <td>{(metrics?.appointment[5].quartar4 !== "") ? numberFormat(metrics?.appointment[5].quartar4) + "%" : ""}</td>
                            </tr>
                            <tr key={6}>
                              <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[6].title}</td>
                              <td>{(metrics?.appointment[6].year !== "") ? numberFormat(metrics?.appointment[6].year) + "%" : ""}</td>
                              <td>{(metrics?.appointment[6].quartar1 !== "") ? numberFormat(metrics?.appointment[6].quartar1) + "%" : ""}</td>
                              <td>{(metrics?.appointment[6].quartar2 !== "") ? numberFormat(metrics?.appointment[6].quartar2) + "%" : ""}</td>
                              <td>{(metrics?.appointment[6].quartar3 !== "") ? numberFormat(metrics?.appointment[6].quartar3) + "%" : ""}</td>
                              <td>{(metrics?.appointment[6].quartar4 !== "") ? numberFormat(metrics?.appointment[6].quartar4) + "%" : ""}</td>
                            </tr>
                            <tr key={7}>
                              <td style={{paddingLeft: "15px", height: "20px"}}></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr key={8}>
                              <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[8].title}</td>
                              <td>{(metrics?.appointment[8].year !== "") ? currencyFormat(metrics?.appointment[8].year) : ""}</td>
                              <td>{(metrics?.appointment[8].quartar1 !== "") ? currencyFormat(metrics?.appointment[8].quartar1) : ""}</td>
                              <td>{(metrics?.appointment[8].quartar2 !== "") ? currencyFormat(metrics?.appointment[8].quartar2) : ""}</td>
                              <td>{(metrics?.appointment[8].quartar3 !== "") ? currencyFormat(metrics?.appointment[8].quartar3) : ""}</td>
                              <td>{(metrics?.appointment[8].quartar4 !== "") ? currencyFormat(metrics?.appointment[8].quartar4) : ""}</td>
                            </tr>
                            <tr key={9}>
                              <td style={{paddingLeft: "15px", height: "20px"}}></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr key={10}>
                              <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[10].title}</td>
                              <td>{(metrics?.appointment[10].year !== "") ? numberFormat(metrics?.appointment[10].year) : ""}</td>
                              <td>{(metrics?.appointment[10].quartar1 !== "") ? numberFormat(metrics?.appointment[10].quartar1) : ""}</td>
                              <td>{(metrics?.appointment[10].quartar2 !== "") ? numberFormat(metrics?.appointment[10].quartar2) : ""}</td>
                              <td>{(metrics?.appointment[10].quartar3 !== "") ? numberFormat(metrics?.appointment[10].quartar3) : ""}</td>
                              <td>{(metrics?.appointment[10].quartar4 !== "") ? numberFormat(metrics?.appointment[10].quartar4) : ""}</td>
                            </tr>
                            <tr>
                              <td className="parent-category" colSpan={6}>Revenue (Advisor Percentage)</td>
                            </tr>
                            { metrics?.revenue.map((item, index) =>
                              <tr key={index}>
                                <td style={{paddingLeft: "15px", height: "20px"}}>{item.title}</td>
                                {index % 4 === 2 ?
                                  <>
                                    <td>{(item.year !== "") ? numberFormat(item.year) + "%" : ""}</td>
                                    <td>{(item.quartar1 !== "") ? numberFormat(item.quartar1) + "%" : ""}</td>
                                    <td>{(item.quartar2 !== "") ? numberFormat(item.quartar2) + "%" : ""}</td>
                                    <td>{(item.quartar3 !== "") ? numberFormat(item.quartar3) + "%" : ""}</td>
                                    <td>{(item.quartar4 !== "") ? numberFormat(item.quartar4) + "%" : ""}</td>  
                                  </>
                                  :
                                  <>
                                    <td>{(item.year !== "") ? currencyFormat(item.year) : ""}</td>
                                    <td>{(item.quartar1 !== "") ? currencyFormat(item.quartar1) : ""}</td>
                                    <td>{(item.quartar2 !== "") ? currencyFormat(item.quartar2) : ""}</td>
                                    <td>{(item.quartar3 !== "") ? currencyFormat(item.quartar3) : ""}</td>
                                    <td>{(item.quartar4 !== "") ? currencyFormat(item.quartar4) : ""}</td>  
                                  </>
                                }
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdvisorDetailedMetrics;
