import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";

const api = new APIClient()
const serverUrl = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/csuite/revenues`

const fetchObjects = async () => await api.get(`${url}/all`, null);
const fetchEventObjects = async () => await api.get(`${url}/all_event`, null);
const fetchWeeklyRevenue = async (date) => await api.get(`${url}/weekly/${date}`, null);
const fetchYearRevenue = async (date) => await api.get(`${url}/yearly/${date}`, null);
const createObject = async (obj) => await api.create(url, obj);
const updateObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);

const key = 'revenues'
const messageKey = 'Revenue'

export const useFetchRevenue = () => {
    return useQuery([key], fetchObjects);
}

export const useFetchEventRevenue = () => {
  return useQuery([key], fetchEventObjects);
}

export const useFetchWeeklyRevenue = (current_date) => {
  return useQuery({
    queryKey: [key, current_date],
    queryFn: () => fetchWeeklyRevenue(current_date),
    onError: (error) => {
        errorToast(error);
    }
  })
}

export const useFetchYearlyRevenue = (current_date) => {
  return useQuery({
    queryKey: [key, 'yearly-' + current_date],
    queryFn: () => fetchYearRevenue(current_date),
    onError: (error) => {
        errorToast(error);
    }
  })
}

export const useCreateRevenue = () => {
    const queryClient = useQueryClient();
    return useMutation(createObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} created Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useUpdateRevenue = () => {
    const queryClient = useQueryClient();
    return useMutation(updateObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} updated Successfully`);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}
