import React, { useCallback, useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, ListGroup, ListGroupItem, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import PageHeader from 'Components/Common/PageHeader';
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { SmartCurrency } from "Components/Common/SmartCurrency";

import { useUpdateGoal, useFetchGoals } from 'api/CSuite/GoalApi';
import { useFirmUpdateGoal, useFetchFirmGoals } from 'api/CSuite/FirmGoalApi';
import { useCreateRange, useUpdateRange, useDeleteRange, useFetchRangeCount } from 'api/CSuite/AssetRangeApi';
import { currencyFormat } from 'api';

import { useSelector } from "react-redux";
import { getUserInfo } from '../../selectors';
import { SmartNumber } from 'Components/Common/SmartNumber';

export default function CsuiteFirmSetting () {
    document.title = "Csuite Firm Setting";

    const userInfo = useSelector(getUserInfo);

    const updateMutation = useUpdateGoal();
    const updateFirmGoalMutation = useFirmUpdateGoal();

    const createRange = useCreateRange();
    const updateRange = useUpdateRange();
    const deleteRange = useDeleteRange();

    const { data:goals } = useFetchGoals();
    const { data:firmGoals } = useFetchFirmGoals();
    const { data:rangeCounts } = useFetchRangeCount();

    const [modal, setModal] = useState<boolean>(false);

    const [firmGoal, setFirmGoal] = useState<any>(null);
    const [userGoals, setUserGoals] = useState<any>(null);

    const [advisorModal, setAdvisorModal] = useState<boolean>(false);

    const [rangeModal, setRangeModal] = useState<boolean>(false);

    const [delDodal, setDelDodal] = useState(false);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);

    const togglePercent = useCallback(() => {
        if (advisorModal) {
            setAdvisorModal(false);
        } else {
            setAdvisorModal(true);
        }
    }, [advisorModal]);

    const toggleRange = useCallback(() => {
        if (rangeModal) {
            setRangeModal(false);
        } else {
            setRangeModal(true);
        }
    }, [rangeModal]);

    const toggleDelRange = useCallback(() => {
        if (delDodal) {
            setDelDodal(false);
        } else {
            setDelDodal(true);
        }
    }, [delDodal]);

    const handleEdit = useCallback(
        (arg: any) => {
            toggle();
        },
        [toggle]
    );

    const handlePercentEdit = useCallback(
        (arg: any) => {
            togglePercent();
        },
        [togglePercent]
    );

    const handleRangeCreate = useCallback(
        () => {
            toggleRange();
        },
        [toggleRange]
    );

    const handleRangeEdit = useCallback(
        (arg: any) => {
            toggleRange();
        },
        [toggleRange]
    );

    const handleRangeDelete = useCallback(
        (arg: any) => {
            setDelDodal(true);
        },
        []
    );

    const [id, setId] = useState(0);
    const [item, setItem] = useState("");
    const [itemValue, setItemValue] = useState(0);
    const [isFirmGoal, setIsFirmGoal] = useState(false);

    const [rangeId, setRangeId] = useState(0);
    const [itemStart, setItemStart] = useState("");
    const [itemStartValue, setItemStartValue] = useState(0);
    const [itemEnd, setItemEnd] = useState("");
    const [itemEndValue, setItemEndValue] = useState(0);

    const initialValues = {
        itemValue: itemValue || "",
    };

    const initialRangeValues = {
        itemStartValue: itemStartValue || 0,
        itemEndValue: itemEndValue || 0,
    };

    useEffect(() => {
        if (firmGoals) {
            const firmGoal = firmGoals.filter((goal) => goal.firm?.id === userInfo.firm?.id)
            setFirmGoal(firmGoal[0]);
        }
    }, [firmGoals, userInfo]);

    useEffect(() => {
        if (goals) {
            // console.log(goals)
            const userGoals = goals.filter((goal) => goal.advisor.firm?.id === userInfo.firm?.id)
            setUserGoals(userGoals);
        }
    }, [goals, userInfo]);

    const handleSubmit = (values) => {
        let db_item;
        if (item === "New Assets Goal")
            db_item = "asset";
        else if (item === "Annuity Premium")
            db_item = "prenium";
        else if (item === "Revenue Goal(Gross)")
            db_item = "revenue";
        else if (item === "Annuity Revenue Goals")
            db_item = "annuityRevenue";
        else if (item === "AUM Revenue Goals")
            db_item = "aumRevenue";
        else if (item === "Misc Revenue Goals")
            db_item = "miscRevenue";
        else if (item === "Annuity Commission Percentage")
            db_item = "commission";
        else if (item === "AUM Fee Compensation Percentage")
            db_item = "compensation";

        if (isFirmGoal) {
            updateFirmGoalMutation.mutate({
                "id": firmGoal?.id,
                [db_item]: values.itemValue
            })
        } else {
            updateMutation.mutate({
                "id": id,
                [db_item]: values.itemValue
            });
        }
        
        toggle();
    };

    const handlePercentSubmit = (values) => {
        let db_item;
        if (item === "New Assets Goal")
            db_item = "asset";
        else if (item === "Annuity Premium")
            db_item = "prenium";
        else if (item === "Revenue Goal(Gross)")
            db_item = "revenue";
        else if (item === "Annuity Revenue Goals")
            db_item = "annuityRevenue";
        else if (item === "AUM Revenue Goals")
            db_item = "aumRevenue";
        else if (item === "Misc Revenue Goals")
            db_item = "miscRevenue";
        else if (item === "Annuity Commission Percentage")
            db_item = "commission";
        else if (item === "AUM Fee Compensation Percentage")
            db_item = "compensation";

        if (isFirmGoal) {
            updateFirmGoalMutation.mutate({
                "id": firmGoal?.id,
                [db_item]: values.itemValue
            })
        } else {
            updateMutation.mutate({
                "id": id,
                [db_item]: values.itemValue
            });
        }
        
        togglePercent();
    };

    const handleRangeSubmit = (values) => {
        if (rangeId) {
            updateRange.mutate({
                "id": rangeId,
                "firm": userInfo.firm,
                "start": values.itemStartValue,
                "end": values.itemEndValue,
            })
        } else {
            createRange.mutate({
                "firm": userInfo.firm,
                "start": values.itemStartValue,
                "end": values.itemEndValue,
            });
        }
        
        toggleRange();
    };

    const handleRangeDelSubmit = (values) => {
        if (rangeId) {
            deleteRange.mutate({
                "id": rangeId,
            })
        }
        
        toggleDelRange();
    };
    
    const validationSchema = Yup.object({
        itemValue: Yup.string().required("Please Enter Value"),
    });

    const validationRangeSchema = Yup.object({
        itemStartValue: Yup.number()
            .required("Please Enter From Value")
            .typeError("Value must be a number"),
        itemEndValue: Yup.number()
            .required("Please Enter To Value")
            .typeError("Value must be a number")
            .moreThan(Yup.ref('itemStartValue'), "To Value must be greater than From Value"),
    });

    function formatNumber(num) {
        // Billion
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
        }
        // Million
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        // Thousand
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return num.toString();
    }

    return (
        <div className="page-content">
            <Container fluid>
                <PageHeader title="Firm Setting" />
                <Row>
                    <Card className="mini-stats-wid w-max-content">
                        <CardBody>
                            <Row>
                                <Col sm={12} md={6} className="mb-xs-4 mb-sm-4 px-md-30">
                                    <h3 className='m-2 mb-4'>Firm Goals</h3>
                                    <ListGroup>
                                        <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                            <div>New Assets Goal</div>
                                            <div>
                                                <span className="me-3 fs-5 fw-bold">{currencyFormat(firmGoals && firmGoal?.asset)}</span>
                                                <Link
                                                    to="#"
                                                    className="btn btn-sm btn-soft-info"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEdit(item);
                                                        setIsFirmGoal(true);
                                                        setItem("New Assets Goal");
                                                        setItemValue(firmGoal?.asset);
                                                        setId(0);
                                                    }}
                                                    >
                                                    <i className="mdi mdi-pencil-outline" id="edittooltip-asset" />
                                                    <UncontrolledTooltip placement="top" target="edittooltip-asset">
                                                        Edit
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                            <div>Annuity Premium</div>
                                            <div>
                                                <span className="me-3 fs-5 fw-bold">{currencyFormat(firmGoals && firmGoal?.prenium)}</span>
                                                <Link
                                                    to="#"
                                                    className="btn btn-sm btn-soft-info"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEdit(item);
                                                        setIsFirmGoal(true);
                                                        setItem("Annuity Premium");
                                                        setItemValue(firmGoal?.prenium);
                                                        setId(0);
                                                    }}
                                                    >
                                                    <i className="mdi mdi-pencil-outline" id="edittooltip-prenium" />
                                                    <UncontrolledTooltip placement="top" target="edittooltip-prenium">
                                                        Edit
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                            <div>Revenue Goal(Gross)</div>
                                            <div>
                                                <span className="me-3 fs-5 fw-bold">{currencyFormat(firmGoals && firmGoal?.revenue)}</span>
                                                <Link
                                                    to="#"
                                                    className="btn btn-sm btn-soft-info"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEdit(item);
                                                        setIsFirmGoal(true);
                                                        setItem("Revenue Goal(Gross)");
                                                        setItemValue(firmGoal?.revenue);
                                                        setId(0);
                                                    }}
                                                    >
                                                    <i className="mdi mdi-pencil-outline" id="edittooltip-revenue" />
                                                    <UncontrolledTooltip placement="top" target="edittooltip-revenue">
                                                        Edit
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                            <div>Annuity Revenue Goals</div>
                                            <div>
                                                <span className="me-3 fs-5 fw-bold">{currencyFormat(firmGoals && firmGoal?.annuityRevenue)}</span>
                                                <Link
                                                    to="#"
                                                    className="btn btn-sm btn-soft-info"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEdit(item);
                                                        setIsFirmGoal(true);
                                                        setItem("Annuity Revenue Goals");
                                                        setItemValue(firmGoal?.annuityRevenue);
                                                        setId(0);
                                                    }}
                                                    >
                                                    <i className="mdi mdi-pencil-outline" id="edittooltip-annrevenue" />
                                                    <UncontrolledTooltip placement="top" target="edittooltip-annrevenue">
                                                        Edit
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                            <div>AUM Revenue Goals</div>
                                            <div>
                                                <span className="me-3 fs-5 fw-bold">{currencyFormat(firmGoals && firmGoal?.aumRevenue)}</span>
                                                <Link
                                                    to="#"
                                                    className="btn btn-sm btn-soft-info"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEdit(item);
                                                        setIsFirmGoal(true);
                                                        setItem("AUM Revenue Goals");
                                                        setItemValue(firmGoal?.aumRevenue);
                                                        setId(0);
                                                    }}
                                                    >
                                                    <i className="mdi mdi-pencil-outline" id="edittooltip-aumrevenue" />
                                                    <UncontrolledTooltip placement="top" target="edittooltip-aumrevenue">
                                                        Edit
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                            <div>Misc Revenue Goals</div>
                                            <div>
                                                <span className="me-3 fs-5 fw-bold">{currencyFormat(firmGoals && firmGoal?.miscRevenue)}</span>
                                                <Link
                                                    to="#"
                                                    className="btn btn-sm btn-soft-info"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEdit(item);
                                                        setIsFirmGoal(true);
                                                        setItem("Misc Revenue Goals");
                                                        setItemValue(firmGoal?.miscRevenue);
                                                        setId(0);
                                                    }}
                                                    >
                                                    <i className="mdi mdi-pencil-outline" id="edittooltip-miscrevenue" />
                                                    <UncontrolledTooltip placement="top" target="edittooltip-miscrevenue">
                                                        Edit
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>
                                <Col sm={12} md={6} className="mb-2 mb-xs-4 mb-sm-4 px-md-30">
                                    <h3 className="m-2 mb-4">Asset Ranges</h3>
                                    {/*<Chart options={pieChart1State.options as ApexOptions} series={pieChart1State.series} type="pie" height={200}/>*/}
                                    <ListGroup>
                                        <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                            <div className="col-md-4">From Value</div>
                                            <div className="col-md-4">To Value</div>
                                            {/* <div className="col-md-3">Count</div> */}
                                            <div className="col-md-2 text-end">
                                                <Link
                                                    to="#"
                                                    className="btn btn-sm btn-soft-info"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleRangeCreate();
                                                        setItemStart("From Value");
                                                        setItemStartValue(0);
                                                        setItemEnd("To Value");
                                                        setItemEndValue(0);
                                                        setRangeId(0);
                                                    }}
                                                    >
                                                    <i className="mdi mdi-plus-box-outline" id="addtooltip" />
                                                    <UncontrolledTooltip placement="top" target="addtooltip">
                                                        Add
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                        </ListGroupItem>
                                        {
                                            rangeCounts?.map((item, index) => (
                                                <ListGroupItem key={index} className='d-flex justify-content-between align-items-center'>
                                                    <div className="col-md-4">{(item.start > 0) ? formatNumber(item.start) : ""}</div>
                                                    <div className="col-md-4">{(item.end > 0) ? formatNumber(item.end) : ""}</div>
                                                    {/* <div className="col-md-3">{item.count}</div> */}
                                                    <div className="col-md-2 text-end">
                                                        <Link
                                                            to="#"
                                                            className="btn btn-sm btn-soft-info"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleRangeEdit(item);
                                                                setItemStart("From Value");
                                                                setItemStartValue(item.start);
                                                                setItemEnd("To Value");
                                                                setItemEndValue(item.end);
                                                                setRangeId(item.id);
                                                            }}
                                                            >
                                                            <i className="mdi mdi-pencil-outline" id={`edittooltip-${index}`} />
                                                            <UncontrolledTooltip placement="top" target={`edittooltip-${index}`}>
                                                                Edit
                                                            </UncontrolledTooltip>
                                                        </Link>&nbsp;
                                                        <Link
                                                            to="#"
                                                            className="btn btn-sm btn-soft-info"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleRangeDelete(item);
                                                                setRangeId(item.id);
                                                                setItemStartValue(item.start);
                                                                setItemEndValue(item.end);
                                                            }}
                                                            >
                                                            <i className="mdi mdi-window-close" id={`deletetooltip-${index}`} />
                                                            <UncontrolledTooltip placement="top" target={`deletetooltip-${index}`}>
                                                                Delete
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </div>
                                                </ListGroupItem>
                                            ))
                                        }
                                    </ListGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <Row>
                    <Card className="mini-stats-wid w-max-content">
                        <CardBody>
                            <Row>
                                <Col sm={12} md={6} className="mb-2 mb-xs-4 mb-sm-4 px-md-30">
                                    <h3 className='m-2 mb-4'>Advisor Goals</h3>
                                    {
                                        userGoals?.map((item, index) => (
                                            <div key={index} className='ms-4'>
                                                <h5 className='mt-4'>{item.advisor?.fullName !== "null null" ? item.advisor?.fullName : item.advisor?.username}</h5>
                                                <ListGroup>
                                                    <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                                        <div>New Assets Goal</div>
                                                        <div className='d-flex align-items-center'>
                                                            <span className="me-3 fs-5">{currencyFormat(firmGoals && Number(firmGoal?.asset) * Number(item.asset) / 100)}</span>
                                                            <div className="me-3 fw-bold" style={{width: "40px"}}>{item.asset}%</div>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-info"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handlePercentEdit(item);
                                                                    setIsFirmGoal(false);
                                                                    setItem("New Assets Goal");
                                                                    setItemValue(item.asset);
                                                                    setId(item.id);
                                                                }}
                                                                >
                                                                <i className="mdi mdi-pencil-outline" id={`edittooltip-asset-${index}`} />
                                                                <UncontrolledTooltip placement="top" target={`edittooltip-asset-${index}`}>
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </div>
                                                    </ListGroupItem>
                                                    <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                                        <div>Annuity Premium</div>
                                                        <div className='d-flex align-items-center'>
                                                            <span className="me-3 fs-5">{currencyFormat(firmGoals && Number(firmGoal?.prenium) * Number(item.prenium) / 100)}</span>
                                                            <div className="me-3 fw-bold" style={{width: "40px"}}>{item.prenium}%</div>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-info"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handlePercentEdit(item);
                                                                    setIsFirmGoal(false);
                                                                    setItem("Annuity Premium");
                                                                    setItemValue(item.prenium);
                                                                    setId(item.id);
                                                                }}
                                                                >
                                                                <i className="mdi mdi-pencil-outline" id={`edittooltip-prenium-${index}`} />
                                                                <UncontrolledTooltip placement="top" target={`edittooltip-prenium-${index}`}>
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </div>
                                                    </ListGroupItem>
                                                    <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                                        <div>Revenue Goal(Gross)</div>
                                                        <div className='d-flex align-items-center'>
                                                            <span className="me-3 fs-5">{currencyFormat(firmGoals && Number(firmGoal?.revenue) * Number(item.revenue) / 100)}</span>
                                                            <div className="me-3 fw-bold" style={{width: "40px"}}>{item.revenue}%</div>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-info"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handlePercentEdit(item);
                                                                    setIsFirmGoal(false);
                                                                    setItem("Revenue Goal(Gross)");
                                                                    setItemValue(item.revenue);
                                                                    setId(item.id);
                                                                }}
                                                                >
                                                                <i className="mdi mdi-pencil-outline" id={`edittooltip-revenue-${index}`} />
                                                                <UncontrolledTooltip placement="top" target={`edittooltip-revenue-${index}`}>
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </div>
                                                    </ListGroupItem>
                                                    <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                                        <div>Annuity Revenue Goals</div>
                                                        <div className='d-flex align-items-center'>
                                                            <span className="me-3 fs-5">{currencyFormat(firmGoals && Number(firmGoal?.annuityRevenue) * Number(item.annuityRevenue) / 100)}</span>
                                                            <div className="me-3 fw-bold" style={{width: "40px"}}>{item.annuityRevenue}%</div>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-info"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handlePercentEdit(item);
                                                                    setIsFirmGoal(false);
                                                                    setItem("Annuity Revenue Goals");
                                                                    setItemValue(item.annuityRevenue);
                                                                    setId(item.id);
                                                                }}
                                                                >
                                                                <i className="mdi mdi-pencil-outline" id={`edittooltip-annrevenue-${index}`} />
                                                                <UncontrolledTooltip placement="top" target={`edittooltip-annrevenue-${index}`}>
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </div>
                                                    </ListGroupItem>
                                                    <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                                        <div>AUM Revenue Goals</div>
                                                        <div className='d-flex align-items-center'>
                                                            <span className="me-3 fs-5">{currencyFormat(firmGoals && Number(firmGoal?.aumRevenue) * Number(item.aumRevenue) / 100)}</span>
                                                            <div className="me-3 fw-bold" style={{width: "40px"}}>{item.aumRevenue}%</div>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-info"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handlePercentEdit(item);
                                                                    setIsFirmGoal(false);
                                                                    setItem("AUM Revenue Goals");
                                                                    setItemValue(item.aumRevenue);
                                                                    setId(item.id);
                                                                }}
                                                                >
                                                                <i className="mdi mdi-pencil-outline" id={`edittooltip-aumrevenue-${index}`} />
                                                                <UncontrolledTooltip placement="top" target={`edittooltip-aumrevenue-${index}`}>
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </div>
                                                    </ListGroupItem>
                                                    <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                                        <div>Misc Revenue Goals</div>
                                                        <div className='d-flex align-items-center'>
                                                            <span className="me-3 fs-5">{currencyFormat(firmGoals && Number(firmGoal?.miscRevenue) * Number(item.miscRevenue) / 100)}</span>
                                                            <div className="me-3 fw-bold" style={{width: "40px"}}>{item.miscRevenue}%</div>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-info"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handlePercentEdit(item);
                                                                    setIsFirmGoal(false);
                                                                    setItem("Misc Revenue Goals");
                                                                    setItemValue(item.miscRevenue);
                                                                    setId(item.id);
                                                                }}
                                                                >
                                                                <i className="mdi mdi-pencil-outline" id={`edittooltip-miscrevenue-${index}`} />
                                                                <UncontrolledTooltip placement="top" target={`edittooltip-miscrevenue-${index}`}>
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </div>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            </div>
                                        ))
                                    }
                                </Col>
                                <Col sm={12} md={6} className="mb-2 mb-xs-4 mb-sm-4 px-md-30">
                                    <h3 className='m-2 mb-4'>Advisor Compensation Percentage</h3>
                                    {
                                        userGoals?.map((item, index) => (
                                            <div key={index} className='ms-4'>
                                                <h5 className='mt-4'>{item.advisor?.fullName !== "null null" ? item.advisor?.fullName : item.advisor?.username}</h5>
                                                <ListGroup>
                                                    <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                                        <div>Annuity Commission Percentage</div>
                                                        <div>
                                                            <span className="me-3 fs-5">{item.commission}%</span>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-info"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handlePercentEdit(item);
                                                                    setIsFirmGoal(false);
                                                                    setItem("Annuity Commission Percentage");
                                                                    setItemValue(item.commission);
                                                                    setId(item.id);
                                                                }}
                                                                >
                                                                <i className="mdi mdi-pencil-outline" id={`edittooltip-comm-${index}`} />
                                                                <UncontrolledTooltip placement="top" target={`edittooltip-comm-${index}`}>
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </div>
                                                    </ListGroupItem>
                                                    <ListGroupItem className='d-flex justify-content-between align-items-center'>
                                                        <div>AUM Fee Compensation Percentage</div>
                                                        <div>
                                                            <span className="me-3 fs-5">{item.compensation}%</span>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-info"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handlePercentEdit(item);
                                                                    setIsFirmGoal(false);
                                                                    setItem("AUM Fee Compensation Percentage");
                                                                    setItemValue(item.compensation);
                                                                    setId(item.id);
                                                                }}
                                                                >
                                                                <i className="mdi mdi-pencil-outline" id={`edittooltip-comp-${index}`} />
                                                                <UncontrolledTooltip placement="top" target={`edittooltip-comp-${index}`}>
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </div>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            </div>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                        Edit Firm Goals
                    </ModalHeader>
                    <ModalBody>
                    <Formik
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values: any) => handleSubmit(values)}
                    >
                        <Form>
                        <SmartCurrency name="itemValue" label={item} />
                        <Row>
                            <Col>
                            <div className="text-end">
                                <Button type="submit" color="success" className="save-user">
                                <i className="bx bx-save"></i>{" "}
                                Save
                                </Button>
                            </div>
                            </Col>
                        </Row>
                        </Form>
                    </Formik>
                    </ModalBody>
                </Modal>
                <Modal isOpen={advisorModal} toggle={togglePercent}>
                    <ModalHeader toggle={togglePercent} tag="h4">
                        Edit Advisor Goals / Compensation Percentage
                    </ModalHeader>
                    <ModalBody>
                    <Formik
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values: any) => handlePercentSubmit(values)}
                    >
                        <Form>
                        <SmartNumber name="itemValue" label={item} format='%' />
                        <Row>
                            <Col>
                            <div className="text-end">
                                <Button type="submit" color="success" className="save-user">
                                <i className="bx bx-save"></i>{" "}
                                Save
                                </Button>
                            </div>
                            </Col>
                        </Row>
                        </Form>
                    </Formik>
                    </ModalBody>
                </Modal>
                <Modal isOpen={rangeModal} toggle={toggleRange}>
                    <ModalHeader toggle={toggleRange} tag="h4">
                        Asset Range
                    </ModalHeader>
                    <ModalBody>
                    <Formik
                        enableReinitialize
                        validationSchema={validationRangeSchema}
                        initialValues={initialRangeValues}
                        onSubmit={(values: any) => handleRangeSubmit(values)}
                    >
                        <Form>
                        <SmartCurrency name="itemStartValue" label={itemStart} />
                        <SmartCurrency name="itemEndValue" label={itemEnd} />
                        <Row>
                            <Col>
                            <div className="text-end">
                                <Button type="submit" color="success" className="save-user">
                                <i className="bx bx-save"></i>{" "}
                                Save
                                </Button>
                            </div>
                            </Col>
                        </Row>
                        </Form>
                    </Formik>
                    </ModalBody>
                </Modal>
                <Modal isOpen={delDodal} toggle={toggleDelRange}>
                    <ModalHeader toggle={toggleDelRange} tag="h4">
                        Are you sure you want to delete this range?
                    </ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-evenly">
                            <p className='fs-5 fw-bold'>Asset Range : {formatNumber(itemStartValue)} ~ {formatNumber(itemEndValue)}</p>
                            <Button color="success" className="save-user" onClick={handleRangeDelSubmit}>
                                <i className="bx bx-save"></i>{" "}
                                Yes
                            </Button>
                            <Button color="danger" className="save-user" onClick={toggleDelRange}>
                                <i className="bx bx-window-close"></i>{" "}
                                No
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        </div>
    );
}

