import React, { useState } from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

//import components
import Switch from "react-switch";

import {
  Col,
  Row,
  Card,
  CardBody,
  Label
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";

import { useFetchMarketingDashboardMetrics, currencyFormat, numberFormat } from "api";
import PageHeader from "Components/Common/PageHeader";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";

import { useFetchDetailedMetrics } from "api/CSuite/DetailedMetricsApi";
import { useFetchGoals } from 'api/CSuite/GoalApi';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

function quarterToString(quarter: number): string {
  const quarterMap: { [key: number]: string } = {
    1: '1st Quarter',
    2: '2nd Quarter',
    3: '3rd Quarter',
    4: '4th Quarter'
  };

  return quarterMap[quarter] || 'Unknown Quarter';
}

function CsuiteDetailedMetrics() {
  //meta title
  document.title = "Csuite All Prospects | Ridgeline Metrics Dashboard ";

  const [byDate, setByDate] = useState<boolean>(true);
  const { data } = useFetchMarketingDashboardMetrics();
  const { data: metrics, isLoading } = useFetchDetailedMetrics();
  const { data:objects } = useFetchGoals();

  const notificationData = [
    {
      title: 'Current Quarter',
      value: quarterToString(Math.floor(new Date().getMonth() / 3) + 1)
    },
    {
      title: 'Total New Assets',
      value: currencyFormat(data?.newAssets)
    },
    {
      title: 'Total Revenue',
      value: currencyFormat(data?.totalRevenue)
    },
    {
      title: 'Assets Under Management',
      value: currencyFormat(data?.assetUnderManage)
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <PageHeader title="Detailed Metrics" />
          <Row>
            {
              notificationData.map((data, index) => {
                return <NotificationMiniCard title={data.title} content={data.value} key={index} size={3} />
              })
            }
          </Row>
          <Row>
              <div className="input-group">
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  className="me-1 mb-sm-8 mb-2"
                  onColor="#02a499"
                  // {...field}
                  // {...props}
                  name=""
                  id=""
                  onChange={() => {
                    setByDate(!byDate);
                  }}
                  checked={byDate}
                />
                <Label className="ms-3 mt-1 fw-bold" htmlFor="">Display by {byDate ? "Date" : "Advisor"}</Label>
              </div>
          </Row>
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <Spinners />
              ) : (
                <Card>
                  <Row>
                    <Card>
                      <CardBody>
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th className="thead col-2"></th>
                              {
                                byDate ? 
                                <>
                                  <th className="col-2">Year to Date</th>
                                  <th className="col-2">1st Quarter</th>
                                  <th className="col-2">2nd Quarter</th>
                                  <th className="col-2">3rd Quarter</th>
                                  <th className="col-2">4th Quarter</th>  
                                </> :
                                <>
                                  <th className="col-2">Firm</th> 
                                  {objects.map((item, index) => (
                                    <th className="col-2" key={index}>{item.advisor?.fullName !== "null null" ? item.advisor?.fullName : item.advisor?.username}</th> 
                                  ))}
                                </>
                              }
                            </tr>
                          </thead>
                          <tbody>
                            { byDate ? 
                              <>
                                <tr>
                                  <td className="parent-category">Written Business</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                { metrics?.business.map((item, index) =>
                                  <tr key={index}>
                                    <td style={{paddingLeft: "15px", height: "20px"}}>{item.title}</td>
                                    { index % 4 === 2 && index < 8 ?
                                      <>
                                        <td>{(item.year !== "") ? numberFormat(item.year) + "%" : ""}</td>
                                        <td>{(item.quartar1 !== "") ? numberFormat(item.quartar1) + "%" : ""}</td>
                                        <td>{(item.quartar2 !== "") ? numberFormat(item.quartar2) + "%" : ""}</td>
                                        <td>{(item.quartar3 !== "") ? numberFormat(item.quartar3) + "%" : ""}</td>
                                        <td>{(item.quartar4 !== "") ? numberFormat(item.quartar4) + "%" : ""}</td>
                                      </>
                                      :
                                      <>
                                        <td>{(item.year !== "") ? currencyFormat(item.year) : ""}</td>
                                        <td>{(item.quartar1 !== "") ? currencyFormat(item.quartar1) : ""}</td>
                                        <td>{(item.quartar2 !== "") ? currencyFormat(item.quartar2) : ""}</td>
                                        <td>{(item.quartar3 !== "") ? currencyFormat(item.quartar3) : ""}</td>
                                        <td>{(item.quartar4 !== "") ? currencyFormat(item.quartar4) : ""}</td>  
                                      </>
                                    }                                
                                  </tr>
                                )}
                                <tr>
                                  <td className="parent-category">Appointments</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr key={0}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[0].title}</td>
                                  <td>{(metrics?.appointment[0].year !== "") ? numberFormat(metrics?.appointment[0].year) : ""}</td>
                                  <td>{(metrics?.appointment[0].quartar1 !== "") ? numberFormat(metrics?.appointment[0].quartar1) : ""}</td>
                                  <td>{(metrics?.appointment[0].quartar2 !== "") ? numberFormat(metrics?.appointment[0].quartar2) : ""}</td>
                                  <td>{(metrics?.appointment[0].quartar3 !== "") ? numberFormat(metrics?.appointment[0].quartar3) : ""}</td>
                                  <td>{(metrics?.appointment[0].quartar4 !== "") ? numberFormat(metrics?.appointment[0].quartar4) : ""}</td>
                                </tr>
                                <tr key={1}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[1].title}</td>
                                  <td>{(metrics?.appointment[1].year !== "") ? numberFormat(metrics?.appointment[1].year) : ""}</td>
                                  <td>{(metrics?.appointment[1].quartar1 !== "") ? numberFormat(metrics?.appointment[1].quartar1) : ""}</td>
                                  <td>{(metrics?.appointment[1].quartar2 !== "") ? numberFormat(metrics?.appointment[1].quartar2) : ""}</td>
                                  <td>{(metrics?.appointment[1].quartar3 !== "") ? numberFormat(metrics?.appointment[1].quartar3) : ""}</td>
                                  <td>{(metrics?.appointment[1].quartar4 !== "") ? numberFormat(metrics?.appointment[1].quartar4) : ""}</td>
                                </tr>
                                <tr key={2}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[2].title}</td>
                                  <td>{(metrics?.appointment[2].year !== "") ? numberFormat(metrics?.appointment[2].year) : ""}</td>
                                  <td>{(metrics?.appointment[2].quartar1 !== "") ? numberFormat(metrics?.appointment[2].quartar1) : ""}</td>
                                  <td>{(metrics?.appointment[2].quartar2 !== "") ? numberFormat(metrics?.appointment[2].quartar2) : ""}</td>
                                  <td>{(metrics?.appointment[2].quartar3 !== "") ? numberFormat(metrics?.appointment[2].quartar3) : ""}</td>
                                  <td>{(metrics?.appointment[2].quartar4 !== "") ? numberFormat(metrics?.appointment[2].quartar4) : ""}</td>
                                </tr>
                                <tr key={3}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr key={4}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[4].title}</td>
                                  <td>{(metrics?.appointment[4].year !== "") ? numberFormat(metrics?.appointment[4].year) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[4].quartar1 !== "") ? numberFormat(metrics?.appointment[4].quartar1) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[4].quartar2 !== "") ? numberFormat(metrics?.appointment[4].quartar2) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[4].quartar3 !== "") ? numberFormat(metrics?.appointment[4].quartar3) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[4].quartar4 !== "") ? numberFormat(metrics?.appointment[4].quartar4) + "%" : ""}</td>
                                </tr>
                                <tr key={5}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[5].title}</td>
                                  <td>{(metrics?.appointment[5].year !== "") ? numberFormat(metrics?.appointment[5].year) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[5].quartar1 !== "") ? numberFormat(metrics?.appointment[5].quartar1) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[5].quartar2 !== "") ? numberFormat(metrics?.appointment[5].quartar2) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[5].quartar3 !== "") ? numberFormat(metrics?.appointment[5].quartar3) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[5].quartar4 !== "") ? numberFormat(metrics?.appointment[5].quartar4) + "%" : ""}</td>
                                </tr>
                                <tr key={6}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[6].title}</td>
                                  <td>{(metrics?.appointment[6].year !== "") ? numberFormat(metrics?.appointment[6].year) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[6].quartar1 !== "") ? numberFormat(metrics?.appointment[6].quartar1) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[6].quartar2 !== "") ? numberFormat(metrics?.appointment[6].quartar2) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[6].quartar3 !== "") ? numberFormat(metrics?.appointment[6].quartar3) + "%" : ""}</td>
                                  <td>{(metrics?.appointment[6].quartar4 !== "") ? numberFormat(metrics?.appointment[6].quartar4) + "%" : ""}</td>
                                </tr>
                                <tr key={7}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr key={8}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[8].title}</td>
                                  <td>{(metrics?.appointment[8].year !== "") ? currencyFormat(metrics?.appointment[8].year) : ""}</td>
                                  <td>{(metrics?.appointment[8].quartar1 !== "") ? currencyFormat(metrics?.appointment[8].quartar1) : ""}</td>
                                  <td>{(metrics?.appointment[8].quartar2 !== "") ? currencyFormat(metrics?.appointment[8].quartar2) : ""}</td>
                                  <td>{(metrics?.appointment[8].quartar3 !== "") ? currencyFormat(metrics?.appointment[8].quartar3) : ""}</td>
                                  <td>{(metrics?.appointment[8].quartar4 !== "") ? currencyFormat(metrics?.appointment[8].quartar4) : ""}</td>
                                </tr>
                                <tr key={9}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr key={10}>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>{metrics?.appointment[10].title}</td>
                                  <td>{(metrics?.appointment[10].year !== "") ? numberFormat(metrics?.appointment[10].year) : ""}</td>
                                  <td>{(metrics?.appointment[10].quartar1 !== "") ? numberFormat(metrics?.appointment[10].quartar1) : ""}</td>
                                  <td>{(metrics?.appointment[10].quartar2 !== "") ? numberFormat(metrics?.appointment[10].quartar2) : ""}</td>
                                  <td>{(metrics?.appointment[10].quartar3 !== "") ? numberFormat(metrics?.appointment[10].quartar3) : ""}</td>
                                  <td>{(metrics?.appointment[10].quartar4 !== "") ? numberFormat(metrics?.appointment[10].quartar4) : ""}</td>
                                </tr>
                                <tr>
                                  <td className="parent-category">Revenue (Gross)</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                { metrics?.revenue.map((item, index) =>
                                  <tr key={index}>
                                    <td style={{paddingLeft: "15px", height: "20px"}}>{item.title}</td>
                                    { index % 4 === 2 ?
                                      <>
                                        <td>{(item.year !== "") ? numberFormat(item.year) + "%" : ""}</td>
                                        <td>{(item.quartar1 !== "") ? numberFormat(item.quartar1) + "%" : ""}</td>
                                        <td>{(item.quartar2 !== "") ? numberFormat(item.quartar2) + "%" : ""}</td>
                                        <td>{(item.quartar3 !== "") ? numberFormat(item.quartar3) + "%" : ""}</td>
                                        <td>{(item.quartar4 !== "") ? numberFormat(item.quartar4) + "%" : ""}</td>  
                                      </>
                                      :
                                      <>
                                        <td>{(item.year !== "") ? currencyFormat(item.year) : ""}</td>
                                        <td>{(item.quartar1 !== "") ? currencyFormat(item.quartar1) : ""}</td>
                                        <td>{(item.quartar2 !== "") ? currencyFormat(item.quartar2) : ""}</td>
                                        <td>{(item.quartar3 !== "") ? currencyFormat(item.quartar3) : ""}</td>
                                        <td>{(item.quartar4 !== "") ? currencyFormat(item.quartar4) : ""}</td>  
                                      </>
                                    }
                                  </tr>
                                )}
                              </>
                              :
                              <>
                                <tr>
                                  <td className="parent-category">Written Business</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>New Assets Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.newAssetGoal !== "") ? currencyFormat(item.newAssetGoal) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>New Assets Signed</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.newAssetSigned !== "") ? currencyFormat(item.newAssetSigned) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>New Assets % to Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.newAssetToGoal !== "") ? numberFormat(item.newAssetToGoal) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Annuity Premium Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.annuPremiumGoal !== "") ? currencyFormat(item.annuPremiumGoal) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Annuity Premium Signed</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.annuPremiumSigned !== "") ? currencyFormat(item.annuPremiumSigned) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Annuity Premium % to Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.annuPremiumToGoal !== "") ? numberFormat(item.annuPremiumToGoal) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>AUM Beginning</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.aumBeginning !== "") ? currencyFormat(item.aumBeginning) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>AUM Current</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.aumCurrent !== "") ? currencyFormat(item.aumCurrent) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Difference</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.difference !== "") ? currencyFormat(item.difference) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td className="parent-category">Appointments</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Total 1st Meeting</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.total1stMeeting !== "") ? numberFormat(item.total1stMeeting) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Total 2nd meeting</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.total2ndMeeting !== "") ? numberFormat(item.total2ndMeeting) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Total Signing Meeting</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.totalSignMeeting !== "") ? numberFormat(item.totalSignMeeting) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>1st to 2nd %</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.fstToSnd !== "") ? numberFormat(item.fstToSnd) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>2nd to Signing %</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.sndToSign !== "") ? numberFormat(item.sndToSign) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>1st to Signing %(Close Rate)</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.fstToSign !== "") ? numberFormat(item.fstToSign) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Average New Assets Per Client</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.avgNewAsset !== "") ? currencyFormat(item.avgNewAsset) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Total Current Client Meetings</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.totalCurrClient !== "") ? numberFormat(item.totalCurrClient) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td className="parent-category">Revenue (Gross)</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Total Revenue Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.totalRevenueGoal !== "") ? currencyFormat(item.totalRevenueGoal) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Total Revenue Paid</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.totalRevenuePaid !== "") ? currencyFormat(item.totalRevenuePaid) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Revenue % to Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.revenueToGoal !== "") ? numberFormat(item.revenueToGoal) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Annuity Revenue Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.annuRevenueGoal !== "") ? currencyFormat(item.annuRevenueGoal) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Annuity Revenue Paid</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.annuRevenuePaid !== "") ? currencyFormat(item.annuRevenuePaid) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Revenue % to Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.annuRevenueToGoal !== "") ? numberFormat(item.annuRevenueToGoal) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>AUM Revenue Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.aumRevenueGoal !== "") ? currencyFormat(item.aumRevenueGoal) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>AUM Revenue Paid</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.aumRevenuePaid !== "") ? currencyFormat(item.aumRevenuePaid) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Revenue % to Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.aumRevenueToGoal !== "") ? numberFormat(item.aumRevenueToGoal) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}></td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Misc Revenue Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.miscRevenueGoal !== "") ? currencyFormat(item.miscRevenueGoal) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>AUM Revenue Paid</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.miscRevenue !== "") ? currencyFormat(item.miscRevenue) : ""}</td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{paddingLeft: "15px", height: "20px"}}>Revenue % to Goal</td>
                                  { metrics?.advisor.map((item, index) =>
                                    <>
                                      <td>{(item.miscRevenueToGoal !== "") ? numberFormat(item.miscRevenueToGoal) + "%" : ""}</td>
                                    </>
                                  )}
                                </tr>
                              </>
                            }
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CsuiteDetailedMetrics;
