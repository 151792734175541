import React from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import {
  Col,
  Row,
  Button,
} from "reactstrap";

import { SmartInput } from "Components/Common/SmartInput";

import { useCreateReferralSource, useUpdateReferralSource } from "api";

function ReferralSourceForm({current}) {
  const createMutation = useCreateReferralSource();
  const updateMutation = useUpdateReferralSource();

  const initialValues = {
    name: (current && current.name) || "",
  };

  const handleSubmit = (values) => {
    if (current) {
      values.id = current.id
      updateMutation.mutate(values);
    } else {
      createMutation.mutate(values);
    }
    // toggle();
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Name"),
  });



  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        <Form>
          <SmartInput name="name" label="Name" />
          <Row>
            <Col>
              <div className="text-end">
                <Button type="submit" color="success" className="save-user">
                  {" "}
                  <i className="bx bx-save"></i>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Formik>
    </React.Fragment>
  );
}

export default ReferralSourceForm;
