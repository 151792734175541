import React from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Col, Row, Button, Label, FormGroup } from "reactstrap";

import { SmartInput } from "Components/Common/SmartInput";
import { SmartCurrency } from "Components/Common/SmartCurrency";
import { useCreateProspectResult, useFetchMeetings } from "api";
import { SmartCheck } from "Components/Common/SmartCheck";
import { SmartDatePicker } from "Components/Common/SmartDatePicker";
import { SmartSelect } from "Components/Common/SmartSelect";

export const MeetingResultForm = ({ current: prospect, rtoggle: toggle }) => {
  const createProspectResultMutation = useCreateProspectResult();
  const { data: meetings, isLoading: meetings_loading } = useFetchMeetings();

  const initialValues = {
    meetingStatus: "",
    meetingDate: prospect.meetingDate,
    meeting: prospect.meeting,
    newMoney: prospect.newMoney,
    qualified: false,
    tud: false,
    wtd: false,
    remarks: "",
  };

  const handleSubmit = (values) => {
    if (values.tud) {
      values.status = "TUD";
    } else if (values.wtd) {
      values.status = "WTD";
    } else {
      values.status = "CURRENT_CLIENT";
    }
    values.prospect = prospect;
    createProspectResultMutation.mutate(values);
    toggle();
  };
  const validationSchema = Yup.object({
    meetingStatus: Yup.string().required("Please Select Meeting Status"),
    // qualified: Yup.boolean(),
    tud: Yup.boolean().test(
      'tud-or-wtd',
      'Either TUD or WTD must be selected',
      function(value) {
        const { wtd } = this.parent;
        if (wtd && value) {
          return false;
        }
        return true;
      }
    ),
    wtd: Yup.boolean().test(
      'tud-or-wtd',
      'Either TUD or WTD must be selected',
      function(value) {
        const { tud } = this.parent;
        // console.log(value);
        if (tud && value) {
          return false;
        }
        return true;
      }
    ),
    // meetingDate: Yup.date().nullable().when(["tud", "wtd"], {
    //   is: (tud, wtd) => tud === false && wtd === false,
    //   then: Yup.date().required("Meeting Date or TUD or WTD is Required"),
    //   otherwise: Yup.date().nullable().notRequired(),
    // }),
    newMoney: Yup.number(),
    remarks: Yup.string(),
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <FormGroup>
                <Label>Meeting Result</Label>
                <select
                  name="meetingStatus"
                  className="form-control form-select"
                  onChange={(e) => {
                    setFieldValue("meetingStatus", e.target.value);
                    
                    if (e.target.value === "KEPT") {
                      setFieldValue("meetingDate", "");
                    } if (e.target.value === "CANCELLED") {
                      setFieldValue("meetingDate", prospect.meetingDate);
                    } if (e.target.value === "RESCHEDULED") {
                      setFieldValue("meetingDate", "");
                    }
                  }}
                >
                  <option value="">Please Select Meeting Result</option>
                  <option value="KEPT">Kept</option>
                  <option value="CANCELLED">Cancelled</option>
                  <option value="RESCHEDULED">Rescheduled</option>
                </select>
              </FormGroup>
              {(values.meetingStatus === "KEPT" || values.meetingStatus === "RESCHEDULED") && <SmartDatePicker name="meetingDate" label="Meeting Date" />}
              {(values.meetingStatus === "KEPT" || values.meetingStatus === "RESCHEDULED") && 
                <SmartSelect
                  isDisabled={true}
                  isLoading={meetings_loading}
                  options={meetings}
                  label="Meeting"
                  name="meeting"
                />
              }
              {values.meetingStatus === "KEPT" && <SmartCurrency name="newMoney" label="New Assets" />}
              {/* {values.meetingStatus === "KEPT" && <SmartCheck name="qualified" label="Qualified" />} */}
              {((values.meetingStatus === "KEPT") || (prospect.status !== "FIRST_MEETING" && values.meetingStatus === "CANCELLED")) && <SmartCheck name="tud" label="TUD" />}
              {((values.meetingStatus === "KEPT") || (prospect.status !== "FIRST_MEETING" && values.meetingStatus === "CANCELLED")) && <SmartCheck name="wtd" label="WTD" />}
              <SmartInput name="remarks" as="textarea" label="Remarks" />
              <Row>
                <Col>
                  <div className="text-end">
                    <Button type="submit" color="success" className="save-user">
                      {" "}
                      <i className="bx bx-save"></i>
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
