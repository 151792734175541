import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import Chart from "react-apexcharts"


import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  Button
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
import MarketEventForm from "./EventForm";
import { ApexOptions } from "apexcharts";
import PageHeader from "Components/Common/PageHeader";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";
import DashboardTooltip from "Components/Common/DashboardTooltip";
import { currencyFormat, numberDecimalFormat, useFetchEventDashboardMetrics, useFetchEvents, useFetchEventProspects, useFetchProspectResults, useFetchEventRevenue } from "api";
function EventDashboard() {
  //meta title
  document.title = "Marketing Event Dashboard | Ridgeline Metrics Dashboard ";

  const { data: query, isLoading: event_loading } = useFetchEvents()

  const navigate = useNavigate()

  const [modal, setModal] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(null);
  const { data: metrics } = useFetchEventDashboardMetrics();
  const { data: prospects } = useFetchEventProspects();
  const { data: results } = useFetchProspectResults();
  const { data: revenues } = useFetchEventRevenue();
  const [yes, setYes] = useState(0);
  const [attend, setAttend] = useState(0);
  const [signed, setSigned] = useState(0);
  const [buRegistered, setBURegistered] = useState(0);
  const [buInOffice, setBUInOffice] = useState(0);
  const [events, setEvents] = useState<any[]>([]);

  const [outComeData, setOutComedata] = useState<any[]>([]);
  const [outComeDataType, setOutComedataType] = useState<any[]>([]);

  const [roiData, setRoiData] = useState<any[]>([]);
  const [roiDataType, setRoiDataType] = useState<any[]>([]);

  const notificationData = [
    {
      title: 'Total Events',
      value: metrics?.totalEvents
    },
    {
      title: 'Total Dinner Events',
      value: metrics?.dineerEvents
    },
    {
      title: 'Total Educational Events',
      value: metrics?.educationalEvents
    },
    {
      title: 'Total Registrants',
      value: metrics?.totalRegistrants
    }
  ];

  useEffect(() => {
    if (prospects) {
      const yesCount = (prospects?.filter((prospect: any) => prospect.event && prospect.attended === 'YES_AT_EVENT'))?.length;
      setYes(yesCount);
      const attendCount = (prospects?.filter((prospect: any) => prospect.event && ( prospect.attended === 'YES_AT_EVENT' || prospect.attended === 'NO_AT_EVENT' )))?.length;
      setAttend(attendCount);
      const signedCount = (prospects?.filter((prospect: any) => prospect.event && prospect.status === 'SIGNED'))?.length;
      setSigned(signedCount);
      const buRegistered = new Set(prospects?.filter(prospect => prospect.event && prospect.lastName && prospect.lastName.trim() !== '').map(prospect => prospect.lastName.trim())).size;
      setBURegistered(buRegistered);
      const buInOffice = (results?.filter((results: any) => results.prospect.event && results.status === "FIRST_MEETING" && results.meetingStatus === "KEPT"))?.length;
      setBUInOffice(buInOffice);

      if (query) {
        let basicEvents: { attend: any; client: any; roi: any }[] = [];
        Object.entries(query).forEach(([key, event]) => {
          if (typeof event === 'object' && event !== null) {
            const { mailerCost, venueCost, id: eventId } = event as {
              mailerCost: number;
              venueCost: number;
              id: number;
            };
            const totalCost = mailerCost + venueCost;

            let attend = prospects?.filter((prospect: any) => prospect.event && prospect.event.id === eventId && (prospect.attended === 'YES_AT_EVENT' || prospect.attended === 'NO_AT_EVENT')).length;
            let closeClient = prospects?.filter((prospect: any) => prospect.event && prospect.event.id === eventId && prospect.status === 'SIGNED').length;
            
            let revenueCost = revenues?.filter((revenue: any) => revenue.prospect?.event && revenue.prospect.event.id === eventId).reduce((sum, revenue) => sum + revenue.revenue, 0);
            let roi = totalCost > 0 ? (revenueCost / totalCost) : 0;

            basicEvents.push({
              ...event,
              attend,
              client: closeClient,
              roi: numberDecimalFormat(roi, 4),
            });

          }
        });
        setEvents(basicEvents);
      }
    }

    if (metrics) {
      let outComeType: string[] = [];
      let outCome: number[] = [];
      
      if ( metrics.assetsByType ) {
          Object.entries(metrics.assetsByType).forEach(([key, value]) => {
              outComeType.push(key);
              outCome.push(value as number);
          });
          setOutComedata([...outCome]);
          setOutComedataType([...outComeType]);
      }

      let roiType: string[] = [];
      let roi: number[] = [];

      if ( metrics.roiByType ) {
        Object.entries(metrics.roiByType).forEach(([key, value]) => {
          roiType.push(key);
          roi.push(value as number);
        });
        setRoiData([...roi]);
        setRoiDataType([...roiType]);
    }
    }
  }, [prospects, results, revenues, metrics, query]);

  const displayData = [
    {
      title: 'Households Registered',
      value: buRegistered || 0
    },
    {
      title: 'Attended',
      value: attend || 0
    },
    {
      title: 'Yes At Event',
      value: yes || 0
    },
    {
      title: 'Households In Office',
      value: buInOffice || 0
    },
    {
      title: 'Signed',
      value: signed || 0
    },
    {
      title: '',
      value: null
    },
    {
      title: '',
      value: null
    },
    {
      title: '',
      value: null
    },
    {
      title: '',
      value: null
    },
    {
      title: 'Yes Rate',
      value: (Number(yes * 100 / attend).toFixed(2).toString() || "0") + "%"
    },
    {
      title: 'Stick Rate',
      value: metrics?.stickRate + "%"
    },
    {
      title: 'Sign Rate',
      value: (Number(signed * 100 / attend).toFixed(2).toString() || 0) + "%"
    },
  ];

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrent(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleEdit = useCallback(
    (arg: any) => {
      const job = arg;
      setCurrent(job);
      toggle();
    },
    [toggle]
  );

  const handleCreate = () => {
    setCurrent(null);
    toggle();
  };

  const handleEventDetail = useCallback(
    (event: any) => {
      navigate(`/marketing/events/${event.id}/details`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Event Name",
        accessor: "name",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          return (
            <Link
              to="#"
              className="btn btn-sm btn-soft-info"
              onClick={(e) => {
                e.preventDefault();
                const row = cellProps.row.original;
                handleEventDetail(row);
              }}
            >
              {cellProps.row.original.name}
            </Link>
          )
        }
      },
      // {
      //   Header: "Event Date",
      //   accessor: "eventDate",
      //   Filter: false,
      //   filterable: true,
      // },
      {
        Header: "Marketing Source",
        accessor: "marketingSource.name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Event Type",
        accessor: "eventType.name",
        Filter: false,
        filterable: true,
      },
      // {
      //   Header: "Event Location",
      //   accessor: "location.name",
      //   Filter: false,
      //   filterable: true,
      // },
      {
        Header: "Presenter",
        accessor: "presenter.fullName",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          if (cellProps?.row.original.presenter?.fullName !== "null null")
            return <span>{cellProps?.row.original.presenter?.fullName}</span>;
          else
            return <span>{cellProps?.row.original.presenter?.username}</span>;
        },
      },
      {
        Header: "Households Attended",
        accessor: "attend",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Closed Client",
        accessor: "client",
        Filter: false,
        filterable: true,
      },
      {
        Header: "ROI",
        accessor: "roi",
        Filter: false,
        filterable: true,
      },
      // {
      //   Header: "Assign to Presenter",
      //   accessor: "assignAll",
      //   Filter: false,
      //   filterable: true,
      //   Cell: (cellProps: any) => {
      //     return (cellProps.row.original.assignAll) ? "Yes" : "No"
      //   }
      // },
      // {
      //   Header: "Marketing Cost",
      //   accessor: "marketingCost",
      //   Filter: false,
      //   filterable: true,
      // },
      // {
      //   Header: "Venue Cost",
      //   accessor: "venueCost",
      //   Filter: false,
      //   filterable: true,
      // },
      // {
      //   Header: "Mailer Cost",
      //   accessor: "mailerCost",
      //   Filter: false,
      //   filterable: true,
      // },
      // {
      //   Header: "No. of Mailers",
      //   accessor: "mailerCount",
      //   Filter: false,
      //   filterable: true,
      // },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const row = cellProps.row.original;
                    handleEdit(row);
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>

                <Button
                  className="btn btn-sm btn-soft-info ms-1"
                  onClick={() => {
                    const row = cellProps.row.original;
                    handleEventDetail(row);
                  }}
                >
                  <i className="bx bxs-dashboard" id="viewStattooltip" />
                  <UncontrolledTooltip placement="top" target="viewStattooltip">
                    Vew Details
                  </UncontrolledTooltip>
                </Button>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleEdit, handleEventDetail]
  );

  const columnChartState = {
    series: [
      {
        name: 'Dinner',
        group: 'actual',
        data: roiData
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: false,
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        formatter: (val) => {
          return val
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        categories: roiDataType
      },
      fill: {
        opacity: 1
      },
      colors: ['#3885f0', '#f0443b', '#fc9832', '#1da858', '#ff6e20', '#32bdc5'],
      yaxis: {
        labels: {
          formatter: (val) => {
            return val
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      }
    },
  };

  const pieChartState = {
    series: outComeData,
    options: {
      chart: {
        height: 480,
        type: 'pie',
      },
      labels: outComeDataType,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 300
          },
        }
      }],
      tooltip: {
        enabled: true,
        y: {
            formatter: function (value) {
                return currencyFormat(value);
            }
        }
      },
      legend: {
        position: 'bottom'
      },
      colors: ['#007bff', '#28a745', '#dc3545', '#fd7e14', '#6f42c1', '#17a2b8']
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <PageHeader title="Event Dashboard" />
          <Row>
            {
              notificationData.map((data, index) => {
                return <NotificationMiniCard title={data.title} content={data.value} key={index} size={3} />
              })
            }
          </Row>
          <Row>
            <Col sm={12} md={9}>
              <h3 className="mb-2">Event Outcomes</h3>
              <Row>
                <Col sm={12} md={6}>
                  <Card>
                    <CardBody>
                      <h5 className="text-center">New Money By Type</h5>
                      <Chart options={pieChartState.options as ApexOptions} series={pieChartState.series} type="pie" />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} md={6}>
                  <Card>
                    <CardBody>
                      <h5 className="text-center">ROI By Type</h5>
                      <Chart options={columnChartState.options as ApexOptions} series={columnChartState.series} type="bar" />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={3}>
              <DashboardTooltip title="Marketing Funnel" displayData={displayData} size={12} />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {event_loading ? (
                <Spinners />
              ) : (
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <CardTitle tag="h5" className="mb-0 flex-grow-1">
                        Events
                      </CardTitle>
                      {/* <div className="flex-shrink-0">
                        <Link
                          to="#"
                          onClick={() => handleCreate()}
                          className="btn btn-success me-1"
                        >
                          {" "}
                          <i className="bx bx-plus-circle"></i> Create
                        </Link>
                        <Link to="#" onClick={() => query.refetch()} className="btn btn-light me-1">
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                      </div> */}
                    </div>
                  </CardBody>
                  <CardBody>
                    <SmartTable
                      columns={columns}
                      data={events}
                      customPageSize={8}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isCustomFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      handleCreate={handleCreate}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    ></SmartTable>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!current ? "Edit Marketing Event" : "Add Marketing Event"}
            </ModalHeader>
            <ModalBody>
              <MarketEventForm current={current} toggle={toggle} />
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EventDashboard;
