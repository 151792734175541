import { useField } from "formik";
import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import Switch from "react-switch";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};


export const SmartCheck = ({ size = "12", label, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = React.useState(field.value || false);
  return (
    <Col md={size}>
      <FormGroup className="mb-3">
        <Row>
            <div
              className={
                meta.touched && meta.error
                  ? "input-group is-invalid"
                  : "input-group"
              }
            >
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8 mb-2"
                onColor="#02a499"
                {...field}
                {...props}
                name={props.name ?? props.label}
                id={props.id || props.name || label}
                onChange={() => {
                  helpers.setValue(!value);
                  setValue(!value);
                  helpers.setTouched(true);
                }}
                checked={value}
              />
              <Label className="ms-3 mt-1" htmlFor={props.id || props.name || label}>{label}</Label>
            </div>
        </Row>
      </FormGroup>
    </Col>
  );
};
