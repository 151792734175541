import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import NotificationMiniCard from "./NotificationMiniCard";

const DashboardNotificationCard = ({ user, notificationData }) => {
    return (
        <div>
            <Card className="mini-stats-wid w-max-content">
                <CardBody>
                    <div className='d-flex gap-4'>
                        <img
                            className="rounded-circle dashboard-profile-user"
                            src={user.avatar}
                            alt="Header Avatar"
                        />
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='text-center'>Hello, {user.name}</h4>
                            <h5 className='text-center'>Ready to jump back in?</h5>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Row>
                {
                    notificationData.map((data, index) => {
                        return <NotificationMiniCard title={data.title} content={data.content} key={index} size={3} />
                    })
                }
            </Row>
        </div>
    )
}

export default DashboardNotificationCard;