import React from "react";
import EventBy from "Components/Common/EventBy";
import { useFetchEventLocations } from "api";

const EventByLocation = () => {

    const { data: options } = useFetchEventLocations();

    return (

        <EventBy pageTitle="Event By Location" options={options} />
    )
}

export default EventByLocation;