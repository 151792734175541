import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "Components/Common/Breadcrumb";
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
  Button,
  Badge
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import Spinners from "Components/Common/Spinner";
import SmartTable from "Components/Common/SmartTable";
import UserForm from "./UserForm";
import { useFetchUsers, useDeleteUser, useActiveUser, useDeActiveUser } from "api";
import { useSelector } from "react-redux";
import { getUserInfo } from '../../../selectors';

function UserList() {
  //meta title
  document.title = "User List | Ridgeline Metrics Dashboard ";

  const userInfo = useSelector(getUserInfo);
  const bSuperPerm = userInfo?.roles?.some(role => role.name === "SUPER");

  const {data: objects, isLoading, refetch} = useFetchUsers()
  const activeUserMutation = useActiveUser();
  const deActiveUserMutation = useDeActiveUser();
  const deleteUserMutation = useDeleteUser();

  const [modal, setModal] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(null);

  const [userId, setUserId] = useState(0);
  const [delModal, setDelModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrent(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const toggleEnable = (values) => {
    activeUserMutation.mutate({ id: values.id});
  };

  const toggleDisable = (values) => {
    deActiveUserMutation.mutate({ id: values.id});
  };

  const toggleDel = useCallback(() => {
    if (delModal) {
        setDelModal(false);
    } else {
      setDelModal(true);
    }
  }, [delModal]);

  const handleEdit = useCallback(
    (arg: any) => {
      const job = arg;
      setCurrent(job);
      toggle();
    },
    [toggle]
  );

  const handleCreate = () => {
    setCurrent(null);
    toggle();
  };

  const handleUserDelSubmit = () => {
    if (userId) {
        deleteUserMutation.mutate({
            "id": userId,
        })
    }
    
    toggleDel();
  };

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Firm",
        accessor: "firm.name",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Username",
        accessor: "username",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        Filter: false,
        filterable: true,
      },
      {
        Header: "Roles",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              {cellProps.row.original.roles.map((role: any) => (
                <li key={role.id}>
                  <span className="badge bg-soft-success text-success">
                    {role.name}
                  </span>
                </li>
              ))}
            </ul>
          );
        }
      },
      {
        Header: "Active",
        accessor: "isActive",
        Filter: false,
        filterable: true,
        Cell: (props) => {
          return props.row.original.isActive ? <Badge color="success bg-opacity-50 px-2 fs-6">Active</Badge> : <Badge color="danger bg-opacity-50 px-2 fs-6">Closed</Badge>
        }
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          if (cellProps.row.original.id > 1) {
            if (cellProps.row.original.isActive) {
              return (
                <ul className="list-unstyled hstack gap-1 mb-0">
                  <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-info"
                      onClick={() => {
                        const row = cellProps.row.original;
                        handleEdit(row);
                      }}
                    >
                      <i className="mdi mdi-pencil-outline" id={`edittooltip-${cellProps.row.original.id}`} />
                      <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`}>
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-info"
                      onClick={() => {
                        const row = cellProps.row.original;
                        setUserId(cellProps.row.original.id);
                        toggleDisable(row);
                      }}
                    >
                      <i className="mdi mdi-close-circle-outline" id={`disabletooltip-${cellProps.row.original.id}`} />
                      <UncontrolledTooltip placement="top" target={`disabletooltip-${cellProps.row.original.id}`}>
                        Disable
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  {bSuperPerm && <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-info"
                      onClick={() => {
                        setUserId(cellProps.row.original.id);
                        toggleDel();
                      }}
                    >
                      <i className="mdi mdi-close" id={`deletetooltip-${cellProps.row.original.id}`} />
                      <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </li>}
                </ul>
              );
            } else {
              return (
                <ul className="list-unstyled hstack gap-1 mb-0">
                  <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-info"
                      onClick={() => {
                        const row = cellProps.row.original;
                        handleEdit(row);
                      }}
                    >
                      <i className="mdi mdi-pencil-outline" id={`edittooltip-${cellProps.row.original.id}`} />
                      <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`}>
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-info"
                      onClick={() => {
                        const row = cellProps.row.original;
                        setUserId(cellProps.row.original.id);
                        toggleEnable(row);
                      }}
                    >
                      <i className="mdi mdi-check-circle-outline" id={`enabletooltip-${cellProps.row.original.id}`} />
                      <UncontrolledTooltip placement="top" target={`enabletooltip-${cellProps.row.original.id}`}>
                        Enable
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  {bSuperPerm && <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-info"
                      onClick={() => {
                        setUserId(cellProps.row.original.id);
                        toggleDel();
                      }}
                    >
                      <i className="mdi mdi-close" id={`deletetooltip-${cellProps.row.original.id}`} />
                      <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </li>}
                </ul>
              );
            }
          } else {
            return (
              <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const row = cellProps.row.original;
                      handleEdit(row);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            );
          }
        },
      },
    ],
    [handleEdit, toggleEnable, toggleDisable, toggleDel]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="User List" />
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <Spinners  />
              ) : (
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <CardTitle tag="h5" className="mb-0 flex-grow-1">
                        User List
                      </CardTitle>
                      { bSuperPerm && <div className="flex-shrink-0">
                        <Link
                          to="#"
                          onClick={() => handleCreate()}
                          className="btn btn-success me-1"
                        >
                          {" "}
                          <i className="bx bx-plus-circle"></i> Create
                        </Link>
                        <Link onClick={() => refetch()} to="#" className="btn btn-light me-1">
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                      </div>}
                    </div>
                  </CardBody>
                  <CardBody>
                    <SmartTable
                      columns={columns}
                      data={objects || []}
                      customPageSize={8}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isCustomFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      handleCreate={handleCreate}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    ></SmartTable>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!current ? "Edit User" : "Add User"}
            </ModalHeader>
            <ModalBody>
            <UserForm current={current} toggle={toggle} />
            </ModalBody>
          </Modal>
          <Modal isOpen={delModal} toggle={toggleDel}>
              <ModalHeader toggle={toggleDel} tag="h4">
                  Are you sure you want to delete this User?
              </ModalHeader>
              <ModalBody>
                  <div className="d-flex justify-content-evenly">
                      <Button color="success" className="save-user" onClick={handleUserDelSubmit}>
                          <i className="bx bx-save"></i>{" "}
                          Yes
                      </Button>
                      <Button color="danger" className="save-user" onClick={toggleDel}>
                          <i className="bx bx-window-close"></i>{" "}
                          No
                      </Button>
                  </div>
              </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserList;