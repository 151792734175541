import React, { useState, useEffect } from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import {
  Col,
  Row,
  Button,
} from "reactstrap";

import { SmartInput } from "Components/Common/SmartInput";
import { SmartSelect } from "Components/Common/SmartSelect";

import { useCreateReferralType, useUpdateReferralType, useFetchFirms } from "api";

import { useSelector } from "react-redux";
import { getUserInfo } from '../../../selectors';

function ReferralTypeForm({current, toggle}) {
  const createMutation = useCreateReferralType();
  const updateMutation = useUpdateReferralType();

  const userInfo = useSelector(getUserInfo);
  const bSuperPerm = userInfo?.roles?.some(role => role.name === "SUPER");

  const {data: firms, isLoading: firm_loading} = useFetchFirms()
  const [userFirms, setUserFirms] = useState<any>([]);

  const initialValues = {
    firm: (current && current.firm) || "",
    name: (current && current.name) || "",
  };

  const handleSubmit = (values) => {
    if (current) {
      values.id = current.id
      updateMutation.mutate(values);
    } else {
      createMutation.mutate(values);
    }
    toggle();
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Name"),
  });

  useEffect(() => {
    if (firms) {
      if ( !bSuperPerm ) {
        const filteredFirms = firms.filter(item => item.id === userInfo.firm.id);
        setUserFirms(filteredFirms);
      } else {
        setUserFirms(firms);
      }
    }
  }, [firms]);

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        <Form>
          <SmartSelect name='firm' label='Firm' options={userFirms} isLoading={firm_loading} />
          <SmartInput name="name" label="Name" />
          <Row>
            <Col>
              <div className="text-end">
                <Button type="submit" color="success" className="save-user">
                  {" "}
                  <i className="bx bx-save"></i>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Formik>
    </React.Fragment>
  );
}

export default ReferralTypeForm;
