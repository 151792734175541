import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const NotificationMiniCard = ({ title, content, size, showChart = false, chartValue = 0 }) => {
    const pieChartState = {
        series: [chartValue],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#e7e7e7",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2
                        }
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: -2,
                            fontSize: '22px'
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.4,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 53, 91]
                },
            },
            labels: ['Average Results'],
        },
    };

    return (
        <Col sm={12} md={size}>
            <Card className="mini-stats-wid">
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <p className="text-muted fw-bold">{title}</p>
                            <h4 className="mb-0 text-center text-pink">{content}</h4>
                        </div>
                    </div>
                    {
                        showChart && (
                            <div className="mt-4">
                                <Chart
                                    options={pieChartState.options as ApexOptions}
                                    series={pieChartState.series}
                                    type="radialBar"
                                    height="200"
                                />
                            </div>
                        )
                    }
                </CardBody>
            </Card>
        </Col>
    )
}

export default NotificationMiniCard;