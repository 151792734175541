import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";
import withRouter from "../../Common/withRouter";

import { useSelector, shallowEqual } from "react-redux";
import { getUserInfo } from '../../../selectors';

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const userInfo = useSelector(getUserInfo);

  const bSuperPerm = userInfo?.roles?.some(role => role.name === "SUPER");
  const bCsuitePerm = userInfo?.roles?.some(role => role.name === "CSUITE");

  const defaultImage = "female.png";
  const imagePath = `/assets/${userInfo?.profileImage || defaultImage}`;

  const [menu, setMenu] = useState(false);
  const [settingMenu, setSettingMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  const { success } = useSelector((state: any) => ({
    success: state.Profile.success,
  }), shallowEqual);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser") || "");
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser") || "");
        setusername(obj.fullName);
      }
    }
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={imagePath}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          { bSuperPerm && <DropdownItem tag="a" href="/csuite/firm">
            <i className="bx bxs-bank font-size-16 align-middle me-1" />
            {props.t("Firm")}
          </DropdownItem> }
          { bCsuitePerm && <DropdownItem tag="a" href="/csuite/users">
            <i className="bx bxs-contact font-size-16 align-middle me-1" />
            {props.t("User")}
          </DropdownItem> }
          <DropdownItem tag="a" href="/profile">
            <i className="bx bx-user-circle font-size-16 align-middle me-1" />
            {props.t("Profile")}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem> */}
          
          <Dropdown
            isOpen={settingMenu}
            toggle={() => setSettingMenu(!settingMenu)}
            className="dropdown-menu-end">
            <DropdownToggle className="btn dropdown-item" tag="button">
              <i className="bx bx-wrench font-size-16 align-middle me-1" />
              {props.t("Settings")}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="/settings/event-types">{props.t("Event Types")}</DropdownItem>
              <DropdownItem href="/settings/event-sources">{props.t("Marketing Sources")}</DropdownItem>
              <DropdownItem href="/settings/event-locations">{props.t("Event Locations")}</DropdownItem>
              <DropdownItem href="/settings/refferal-types">{props.t("Referral Types")}</DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));