import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import EventProspectList from "./EventProspectList";
import { useParams } from "react-router-dom";
import { useFetchEventById, useFetchProspectsByEvent, useUploadProspectFile, useFetchEventMetrics, useUpdateEvent, currencyFormat, numberFormat, numberDecimalFormat } from "api";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import DashboardTooltip from "Components/Common/DashboardTooltip";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { SmartNumber } from "Components/Common/SmartNumber";
import { SmartCurrency } from "Components/Common/SmartCurrency";

export const EventDetails = () => {
  document.title = "Event Detail | Ridgeline Metrics Dashboard ";
  const { id } = useParams();
  const { data: event, isLoading: eventLoading } = useFetchEventById(
    id ? parseInt(id) : 0
  );

  const { data: metrics, refetch } = useFetchEventMetrics(
    id ? parseInt(id) : 0
  );

  const [objects, setObjects] = useState<any>([]);

  const [outComeData, setOutComedata] = useState<any>([]);
  const [outComeDataType, setOutComedataType] = useState<any>([]);

  const [modal, setModal] = React.useState(false);
  const [editEventModal, setEditEventModal] = useState(false);

  const [mailCost, setMailCost] = useState("");
  const [numberOfMailer, setNumberOfMailer] = useState("");
  const updateEventMutation = useUpdateEvent();

  const handleEditEvent = (values) => {
    setMailCost(values.mailCost);
    setNumberOfMailer(values.numberOfMailer);
      
    updateEventMutation.mutate({
      ...event,
      mailerCost: values.mailCost,
      mailerCount: values.numberOfMailer
    })
    toggleEditEventModal();
  }

  const { data: prospects, isLoading: prospectLoading } = useFetchProspectsByEvent(event && event.id ? event.id : 0);

  const pieChartState = {
    series: outComeData,
    options: {
      chart: {
        height: 380,
        type: 'pie',
      },
      labels: outComeDataType,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 350
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  };

  useEffect(() => {
    if (prospects) {
      const attended = prospects.filter((prospect: any) => prospect.attended === 'YES_AT_EVENT');
      setObjects(attended);
    }

    if (event) {
      setNumberOfMailer(event.mailerCount)
      setMailCost(event.mailerCost)
    }

    if (metrics) {
      if (metrics.outcome) {
        let outComeType: string[] = [];
        let outCome: number[] = [];
        
        Object.entries(metrics?.outcome).forEach(([key, value]) => {
            outComeType.push(key);
            outCome.push(value as number);
        });

        setOutComedata([...outCome]);
        setOutComedataType([...outComeType]);
      }
    }
  }, [event, prospects, metrics]);

  const toggle = () => {
    setFile(null);
    setModal(!modal);
  };

  const toggleEditEventModal = () => {
    setEditEventModal(!editEventModal);
  }

  const downloadTemplate = () => {
    const csvData = [
      ["LastName", "FirstName", "SpouseFirstName", "SpouseLastName", "Attended"],
      ["Phillips", "Mark", "Wendell", "Allen", "YES_AT_EVENT"],
      ["Kern", "Katherine", "Lorraine", "Casassa", "NO_AT_EVENT"],
      ["Sorensen", "Elizabeth", "Henry", "", "DID_NOT_ATTEND"],
    ];
  
    const csvContent = csvData.map(row => row.join(",")).join("\n");  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Sample Event Sheet.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const uploadFileMutation = useUploadProspectFile()

  const [file, setFile] = React.useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
    refetch();
  };

  const handleFileUpload = () => {
    if (file) {
      uploadFileMutation.mutate({ id: event?.id > 0 ? event?.id : 0, file: file })
      toggle();
    }

    refetch();
  }

  // const onAttendanceRateClick = (e) => {
  //   e.preventDefault();
  //   const attended = prospects.filter((prospect) => prospect.attended === true);
  //   setObjects(attended)
  // }

  // const onYesRateClick = (e) => {
  //   e.preventDefault();
  //   const yes = prospects.filter((prospect) => prospect.attended === true && prospect.attendStatus === 'YES_AT_EVENT');
  //   setObjects(yes)
  // }

  // const onNoShowClick = (e) => {
  //   e.preventDefault();
  //   const noShows = prospects.filter((prospect) => prospect.attended === false);
  //   setObjects(noShows)
  // }

  // const onNoAtEventClick = (e) => {
  //   e.preventDefault();
  //   const noAtEvent = prospects.filter((prospect) => prospect.attended === true && prospect.attendStatus === 'NO_AT_EVENT');
  //   setObjects(noAtEvent)
  // }

  const notificationData = [
    {
      title: 'Cost per First Meeting Kept',
      value: currencyFormat(metrics?.costInOfficeAtBat)
    },
    {
      title: 'New Assets',
      value: currencyFormat(metrics?.newMoney)
    },
    {
      title: 'ROI',
      value: numberDecimalFormat(metrics?.roi, 4)
    },
  ];

  const displayData = [
    {
      title: 'Registration Rate',
      value: isNaN(metrics?.registrationRate) ? 0 : (metrics?.registrationRate).toFixed(2) + "%"
    },
    {
      title: 'Households Attended',
      value: metrics?.eventAtBats
    },
    {
      title: 'Attendance Rate',
      value: isNaN(metrics?.attendanceRate) ? 0 : (metrics?.attendanceRate)?.toFixed(2) +'%'
    },
    {
      title: 'Meetings Requested',
      value: metrics?.firstMeetingsSet
    },
    {
      title: 'Yes Rate',
      value: isNaN(metrics?.yesRate) ? 0 : ( metrics?.yesRate)?.toFixed(2) + '%'
    },
    {
      title: 'First Meeting Dates Past',
      value: metrics?.firstMeetingDatesPast
    },
    {
      title: 'First Meeting Kept',
      value: metrics?.totalInOfficeOpportunities
    },
    {
      title: 'Stick Rate',
      value: (isNaN(metrics?.grassStickRate) ? 0 : (metrics?.grassStickRate)?.toFixed(2)) + '%'
    },
    // {
    //   title: 'Grass Stick Rate',
    //   value: isNaN(metrics?.grassStickRate) ? 0 : (metrics?.grassStickRate)?.toFixed(2) + '%'
    // },
    // {
    //   title: 'Qualified Stick Rate',
    //   value: isNaN(metrics?.qualifiedStickRate) ? 0 : (metrics?.qualifiedStickRate)?.toFixed(2) + '%'
    // },
    {
      title: 'Cost per First Meeting Kept',
      value: currencyFormat(metrics?.costInOfficeAtBat)
    },    
    {
      title: 'Total Signed',
      value: metrics?.totalSigned
    },
    {
      title: 'Close Rate',
      value: metrics?.closeRate + "%"
    },
    {
      title: 'New Assets',
      value: currencyFormat(metrics?.newMoney)
    },
    {
      title: 'Avg Investible Assets',
      value: currencyFormat(metrics?.avgIPA)
    }
  ];

  const totalSummaryData = [
    {
      data: [
        {
          title: 'Source',
          value: event?.marketingSource?.name
        },
        {
          title: 'Type',
          value: event?.eventType?.name
        }
      ]
    },
    {
      data: [
        {
          title: 'Location',
          value: event?.location?.name
        },
        {
          title: 'Presenter',
          value: event?.presenter?.fullName
        }
      ]
    },
    {
      data: [
        {
          title: 'Mail Cost',
          value: currencyFormat(event?.mailerCost)
        },
        {
          title: 'Number of Mailers',
          value: numberFormat(event?.mailerCount)
        }
      ]
    },
    {
      data: [
        {
          title: 'Venue Cost',
          value: currencyFormat(event?.venueCost)
        },
        {
          title: 'Total Cost',
          value: currencyFormat(event?.mailerCost + event?.venueCost)
        }
      ]
    }
  ];

  const initialValues = {
    mailCost: mailCost || 0,
    numberOfMailer: numberOfMailer || 0,
  };

  const validationSchema = Yup.object({
    mailCost: Yup.number()
      .required("Please Enter From Value")
      .typeError("Value must be a number"),
    numberOfMailer: Yup.number()
      .required("Please Enter To Value")
      .typeError("Value must be a number"),
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <h2 className="text-center mb-4">{event?.name}</h2>
          <div className="d-flex justify-content-center text-center position-relative">
            <h4 className="text-center mb-4 text-pink">{event?.eventDate}</h4>
            <Button color="info" className="position-absolute end-0 me-1" onClick={toggleEditEventModal}>
              {" "}
              Edit Event
            </Button>
          </div>
          <Card>
            <CardBody>
              <Row className="mb-4">
                {
                  totalSummaryData.map((summary, index) => {
                    return (
                      <Col sm="12" md="3" key={index}>
                        <Row className="h-100">
                          {
                            summary.data.map((data, i) => {
                              return (
                                <Col className="d-flex flex-xs-row flex-sm-row flex-md-column justify-content-between" sm="12" md="6" key={i}>
                                  <h6 className="fw-bold">{data.title}</h6>
                                  <h4 className="text-center text-pink">{data.value}</h4>
                                </Col>
                              )
                            })
                          }
                        </Row>
                      </Col>
                    )
                  })
                }
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col sm="12" md="4" lg="4">
              <Row>
                <Card>
                  <CardBody>
                    <h3>Event Results</h3>
                    <div className="d-flex flex h-100 justify-content-center align-items-center">
                      <Chart options={pieChartState.options as ApexOptions} series={pieChartState.series} type="pie" height={200} />
                    </div>
                  </CardBody>
                </Card>
              </Row>
              <Row className="mb-4 mb-xs-4 mb-sm-4">
                <DashboardTooltip title="Event Metrics" displayData={displayData} size={12} />
              </Row>
            </Col>
            <Col sm="12" md="8" lg="8">
              <Row className="mb-4">
                {
                  notificationData.map((data, index) => {
                    return <NotificationMiniCard title={data.title} content={data.value} key={index} size={4} />
                  })
                }
              </Row>
              <Row>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <h3>Prospects - Yes at Events</h3>
                  <div>
                    <Button color="info" className="ms-1" onClick={toggle}>
                      {" "}
                      Upload Prospect File
                    </Button>
                    <Button color="info" className="ms-1" onClick={downloadTemplate}>
                      {" "}
                      Download Sample File
                    </Button>
                  </div>
                </div>
                <EventProspectList
                  prospects={objects}
                  isLoading={prospectLoading || eventLoading || false}
                />
              </Row>
            </Col>
          </Row>
        </Container>

        <Modal toggle={toggle} isOpen={modal}>
          <ModalHeader toggle={toggle}> Choose Prospect File (.csv)</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Input type="file" placeholder="File Name" onChange={handleFileChange} accept=".csv" />
                </FormGroup>
              </Col>
              <Col sm="12" className="text-end">

                <Button
                  color="info"
                  className="ms-2"
                  onClick={handleFileUpload}
                >
                  Upload
                </Button>

                <Button
                  color="secondary"
                  className="ms-2"
                  onClick={toggle}
                >
                  Cancel
                </Button>

              </Col>
            </Row>
          </ModalBody>
        </Modal>
        
        <Modal toggle={toggleEditEventModal} isOpen={editEventModal}>
          <ModalHeader toggle={toggleEditEventModal}> Edit Event</ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={(values: any) => handleEditEvent(values)}
            >
              <Form>
                <Row>
                  <Col>
                      {/* Number of Mailer
                      <Input type="number" placeholder="Number of Mailer" className="mb-2" value={numberOfMailer} onChange={(e) => setNumberOfMailer(e.target.value)}/>
                      Mail Cost
                      <Input type="number" placeholder="Mail Cost" value={mailCost} onChange={(e) => setMailCost(e.target.value)}/> */}
                      <SmartNumber
                        type="number"
                        name="numberOfMailer"
                        label="Number of Mailer"
                        size="12"
                      />
                      <SmartCurrency
                        name="mailCost"
                        label="Mail Cost"
                        size="12"
                      />
                  </Col>
                  <Col sm="12" className="text-end">
                    <Button type="submit" color="info" className="ms-2">Save</Button>
                    <Button color="secondary" className="ms-2" onClick={toggleEditEventModal}>Cancel</Button>
                  </Col>
                </Row>
              </Form>
            </Formik>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};