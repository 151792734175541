import { Field, useField } from "formik";
import React from "react";
import { Button, Col, FormFeedback, FormGroup, Label } from "reactstrap";

export const SmartInput = ({
  handleButtonClick,
  buttonText,
  size = "12",
  label,
  type,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  return (
    <Col md={size}>
      <FormGroup className="mb-3">
        <Label htmlFor={props.id || props.name || label}>{label}</Label>
        <div className={
              meta.touched && meta.error
                ? "input-group is-invalid"
                : "input-group"
            }>
            {props.append && <span className="input-group-append"><span className="input-group-text">{props.append}</span></span>}

          <Field
            {...field}
            {...props}
            {...(props.as ? { as: props.as } : { type: type })}
            name={props.name ?? props.label}
            placeholder={props.placeholder || `Please enter ${label}`}
            className={
              meta.touched && meta.error
                ? "form-control is-invalid"
                : "form-control"
            }
            id={props.id || props.name || label}
          />
          {handleButtonClick && (
            <Button onClick={handleButtonClick} color="primary" id={`btn_${props.id || props.name}`}>
              {buttonText || <i className="fa fa-plus-circle"></i>}
            </Button>
          )}
        </div>

        {meta.touched && meta.error ? (
          <FormFeedback type="invalid">{meta.error}</FormFeedback>
        ) : null}
      </FormGroup>
    </Col>
  );
};
