import DashboardNotificationCard from "Components/Common/DashboardNotificationCard";
import PageHeader from "Components/Common/PageHeader";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import DashboardTooltip from "Components/Common/DashboardTooltip";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";
import SmartTable from "Components/Common/SmartTable";
import { useFetchAdvisorProspects, useFetchAdvisorDashboardMetrics, useFetchYearlyProspects, currencyFormat } from "api";
import { useFetchFirmGoals } from 'api/CSuite/FirmGoalApi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getUserInfo } from '../../selectors';

const AdvisorDashboard = () => {
    document.title = "Advisor Dashboard | Ridgeline Metrics Dashboard ";

    const userInfo = useSelector(getUserInfo);
    const { advisor } = useParams();
    const navigate = useNavigate()

    const defaultImage = "female.png";
    const imagePath = `/assets/${userInfo?.profileImage || defaultImage}`;

    const { data: prospects } = useFetchAdvisorProspects(advisor);
    const { data: yearlyProspects } = useFetchYearlyProspects(advisor);
    const [activeProspects, setActiveProspects] = useState<any>([]);
    const [userName, setUserName] = useState<string>('');
    const { data: firmGoals } = useFetchFirmGoals();
    const { data: metricsData } = useFetchAdvisorDashboardMetrics(advisor);
    const [yearlyClientCount, setYearlyClientCount] = useState();

    const [outComeData, setOutComedata] = useState<any>([]);
    const [outComeDataType, setOutComedataType] = useState<any>([]);

    useEffect(() => {

        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser") || "");
            setUserName(obj.firstName + ' ' + obj.lastName);
        }

        if (prospects) {
            const activeProspects = prospects.filter((prospect: any) => prospect.attended === "YES_AT_EVENT" && prospect.isActive);
            setActiveProspects(activeProspects);
        }

        if (yearlyProspects) {
            const yearlyMadeClientCount = yearlyProspects.filter((prospect: any) => prospect.status === "SIGNED").length;

            setYearlyClientCount(yearlyMadeClientCount);
        }

        if (metricsData) {
            let outComeType: string[] = [];
            let outCome: number[] = [];
            
            if ( metricsData.assetsByType ) {
                Object.entries(metricsData.assetsByType).forEach(([key, value]) => {
                    outComeType.push(key);
                    outCome.push(value as number);
                });

                setOutComedata([...outCome]);
                setOutComedataType([...outComeType]);
            }
        }
    }, [prospects, yearlyProspects, metricsData]);

    const userData = {
        name: userName,
        avatar: imagePath
    };

    const notificationData = [
        {
            title: 'Active Prospects',
            content: activeProspects?.length || 0
        },
        {
            title: 'Total Clients Made YTD',
            content: yearlyClientCount || 0
        },
        {
            title: 'Average Investible Assets',
            content: currencyFormat(metricsData?.avgIPA)
        },
        {
            title: 'AUM Reported',
            content: currencyFormat(metricsData?.aum)
        }
    ];

    const displayData = [
        {
            title: 'Yes Rate',
            value: (metricsData?.yesRate || 0) + '%'
        },
        {
            title: 'Stick Rate',
            value: (metricsData?.stickRate || 0) + '%'
        },
        {
            title: 'Close Rate',
            value: (metricsData?.closeRate || 0) + '%'
        }
    ];

    const pieChartState = {
        series: outComeData,
        options: {
            chart: {
                height: 480,
                type: 'pie',
            },
            labels: outComeDataType,
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (value) {
                        return currencyFormat(value);
                    }
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 450
                    },
                }
            }],
            legend: {
                position: 'bottom' // Positions the legend at the bottom
            },
            colors: ['#007bff', '#28a745', '#dc3545', '#fd7e14', '#6f42c1', '#17a2b8']
        },
    };

    let advisor_asset;
    let advisor_premium;
    let advisor_revenue;

    if (metricsData?.goal?.asset !== undefined && firmGoals && firmGoals.length > 0) {
        advisor_asset = metricsData?.goal?.asset * firmGoals[0]?.asset / 100;
        advisor_premium = metricsData?.goal?.prenium * firmGoals[0]?.prenium / 100;
        advisor_revenue = metricsData?.goal?.revenue * firmGoals[0]?.revenue / 100;
    } else {
        advisor_asset = 0;
        advisor_premium = 0;
        advisor_revenue = 0;
    }
      
    const minNotificationData = [
        {
            title: 'New Asset',
            value: currencyFormat(metricsData?.newMoney),
            chart: advisor_asset > 0 ? Number((metricsData?.newMoney / advisor_asset * 100).toFixed(2)) || 0 : 0,
        },
        {
            title: 'Annuity Premium',
            value: currencyFormat(metricsData?.annuity),
            chart: advisor_premium > 0 ? Number((metricsData?.annuity / advisor_premium * 100).toFixed(2)) || 0 : 0,
        },
        {
            title: 'Revenue',
            value: currencyFormat(metricsData?.revenue),
            chart: advisor_revenue > 0 ? Number((metricsData?.revenue / advisor_revenue * 100).toFixed(2)) || 0 : 0,
        },
    ];

    const handleProspectDetail = useCallback(
        (prospect: any) => {
            navigate(`/advisor/prospect/${prospect.id}/detail`);
        },
        [navigate]
    );

    const columns = useMemo(
        () => [
            {
                Header: "Last Name",
                accessor: "lastName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    return (
                        <Link
                            to="#"
                            className="btn btn-sm btn-soft-info"
                            onClick={(e) => {
                                e.preventDefault();
                                const row = cellProps.row.original;
                                handleProspectDetail(row);
                            }}
                        >
                            {cellProps.row.original.lastName}
                        </Link>
                    )
                }
            },
            {
                Header: "First Name",
                accessor: "firstName",
                Filter: false,
                filterable: true,
            },
            {
                Header: "Type",
                accessor: "eventType.name",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    if (cellProps?.row.original.event !== null)
                        return <span>{cellProps?.row.original.event.eventType?.name}</span>;
                    else
                        return <span>{cellProps?.row.original.eventType?.name}</span>;
                },
            },
            {
                Header: "Advisor",
                accessor: "advisor.fullName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    if (cellProps?.row.original.advisor.fullName !== "null null")
                      return <span>{cellProps?.row.original.advisor.fullName}</span>;
                    else
                      return <span>{cellProps?.row.original.advisor.username}</span>;
                },
            },
            {
                Header: "Investible Assets",
                accessor: "newMoney",
                Filter: false,
                Cell: ({ value }) => {            
                    return <span>{currencyFormat(value)}</span>;
                },
            },
            {
                Header: "Meeting Type",
                accessor: "meeting.name",
                Filter: false,
                filterable: true,
                Cell: (props) => {
                    return props.row.original.meeting?.name ? props.row.original.meeting?.name : <Badge color="soft-secondary">Pending</Badge>
                }
            },
            {
                Header: "Meeting Date",
                accessor: "meetingDate",
                Filter: false,
                filterable: true,
                Cell: (props) => {
                    return props.row.original.meetingDate ? props.row.original.meetingDate : <Badge color="soft-secondary">Pending</Badge>
                }
            }
        ],
        [handleProspectDetail]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <PageHeader title="Advisor Dashboard" />
                <DashboardNotificationCard user={userData} notificationData={notificationData} />
                <Row>
                    <Col sm={12} md={4}>
                        <Row className="h-100">
                            <Card>
                                <CardBody>
                                    <h3>New Asset By Source</h3>
                                    <div className="d-flex flex h-100 justify-content-center align-items-center">
                                        <Chart options={pieChartState.options as ApexOptions} series={pieChartState.series} type="pie" width={500} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Row>
                    </Col>
                    <Col sm={12} md={8}>
                        <Row>
                            {
                                minNotificationData.map((data, index) => {
                                    return <NotificationMiniCard title={data.title} content={data.value} key={index} size={4} showChart={true} chartValue={data.chart
                                    } />
                                })
                            }
                        </Row>
                        <Row className="m-2">
                            <DashboardTooltip title="Marketing Metrics" displayData={displayData} size={12} />
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Card>
                        <CardBody>
                            <h2>Active Prospect</h2>
                            <SmartTable
                                columns={columns}
                                data={activeProspects || []}
                                customPageSize={30}
                                isGlobalFilter={true}
                                isPagination={true}
                                isShowingPageLength={true}
                                customPageSizeOption={true}
                                tableClass="table-bordered align-middle nowrap mt-2"
                                paginationDiv="col-12"
                                pagination="pagination justify-content-end pagination-rounded"
                            ></SmartTable>
                        </CardBody>
                    </Card>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default AdvisorDashboard;