import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { Form, Formik } from "formik";

//redux
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import withRouter from "Components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "Components/Common/Breadcrumb";
import { editUser } from "slices/auth/login/reducer";
import { SmartInput } from "Components/Common/SmartInput";
import { useUpdateUser, useUploadProfileImage } from "api";

const UserProfile = () => {

  //meta title
  document.title = "Profile ";

  const updateUerMutation = useUpdateUser()
  const uploadImageMutation = useUploadProfileImage();
  const dispatch = useDispatch<any>();

  const { error, success, profile } = useSelector((state: any) => ({
    profile: state.Login.user,
    error: state.Login.error,
    success: state.Login.success,
  }), shallowEqual);

  const defaultImage = "female.png";
  const imagePath = `/assets/${profile?.profileImage || defaultImage}`;

  const [previewSource, setPreviewSource] = useState<string | null>(null);

  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   const obj = JSON.parse(localStorage.getItem("authUser") || "");
    //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     setidx(obj.userId);
    //   } else if (
    //     process.env.REACT_APP_DEFAULTAUTH === "fake" ||
    //     process.env.REACT_APP_DEFAULTAUTH === "jwt"
    //   ) {
    //     setidx(obj.userId);
    //   }
    //   console.log(profile)
      
    //   setTimeout(() => {
    //     dispatch(resetProfileFlagChange());
    //   }, 3000);
    // }
  }, [dispatch, success]);

  const initialValues = {
    lastName: profile?.lastName || "",
    firstName: profile?.firstName || "",
    username: profile?.username || "",
    email: profile?.email || "",
    // password: profile?.password || "",
    roles: profile?.roles,
    profileImage: profile?.profileImage || "",
    id: profile?.id
  };

  const validationSchema = Yup.object({
    lastName: Yup.string().required("Please Input Last Name"),
    firstName: Yup.string().required("Please Input First Name"),
    username: Yup.string().required("Please Input Username"),
    email: Yup.string().required("Please Input Email Address")
  });

  const handleSubmit = (values) => {
    // const rolesArray = profile?.roles.split(",").map(role => role.trim());
    // const newValues = { ...values, roles: profile?.roles };
    // updateUerMutation.mutate(newValues);
    updateUerMutation.mutate(values);
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Show preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewSource(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
      const imageId = uploadImageMutation.mutate({ id: profile?.id, file: file });
      console.log(imageId)
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="RMD" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={previewSource || imagePath || ''}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{}</h5>
                        <p className="mb-1">{profile?.email}</p>
                        <p className="mb-0">Id no: #{profile?.id}</p>
                      </div>
                    </div>
                    <div className="align-self-center">
                      <input id="user-image-input" className="hidden-file-input" type="file" onChange={handleImageChange} />
                      <Button type="button" color="success" className="save-user" onClick={() => {document.getElementById('user-image-input')?.click();}}>
                        <i className="bx bx-save"></i>
                        Upload Image
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Profile</h4>

          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values: any) => handleSubmit(values)}
              >
                <Form>
                    <Row>
                        <SmartInput
                            type="text"
                            name="lastName"
                            label="Last Name"
                            size="6"
                        />
                        <SmartInput
                            type="text"
                            name="firstName"
                            label="First Name"
                            size="6"
                        />
                    </Row>
                    <Row>
                        <SmartInput
                            disabled={true}
                            type="text"
                            name="username"
                            label="Username"
                            size="6"
                        />
                        <SmartInput
                            type="text"
                            name="email"
                            label="Email"
                            size="6"
                        />
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <Button type="submit" color="success" className="save-user">
                                    <i className="bx bx-save"></i>{" "}
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);