import axios from "axios";

// default
axios.defaults.baseURL = "";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
// content type
// let authUser: any = (localStorage.getItem("authUser"));

axios.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    let authUser: any = localStorage.getItem("authUser");
    if (authUser) {
      config.headers.Authorization = `Bearer ${JSON.parse(authUser).token}`;
    }
    return config;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message: any = error?.response?.data?.message
    message = message ? message : error?.message
    
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url: any, params: any) => {
    let response: any;

    let paramKeys: any = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url: any, data: any) => {
    data = extractIds(data);
    return axios.post(url, data);
  };

  upload = (url: any, data: any) => {
    return axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  post = (url: any, data: any) => {
    data = extractIds(data);
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: any, data: any) => {
    data = extractIds(data);

    return axios.patch(url, data);
  };

  put = (url: any, data: any) => {
    data = extractIds(data);

    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url: any, config: any) => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

function extractIds(obj) {
  const result = {};

  for (const key in obj) {
    if (obj[key] && obj[key].id) {
      result[key] = { id: obj[key].id };
    } else {
      result[key] = obj[key];
    }
  }

  return result;
}

export { APIClient, setAuthorization, getLoggedinUser };