import React from "react";
import { Button, Col, Row } from "reactstrap";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { SmartDatePicker } from "Components/Common/SmartDatePicker";
import { SmartInput } from "Components/Common/SmartInput";
import { SmartNumber } from "Components/Common/SmartNumber";
import { SmartCurrency } from "Components/Common/SmartCurrency";
import { SmartCheck } from "Components/Common/SmartCheck";

import {
    useCreateEvent,
    useFetchEnableEventTypes,
    useFetchEnableEventLocations,
    useFetchEnableMarketingSource,
    useFetchPresenters
} from "api";
import { SmartCreatable } from "Components/Common/SmartCreatable";
import { SmartSelect } from "Components/Common/SmartSelect";
import PageHeader from "Components/Common/PageHeader";
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../selectors';

function MarketCreateEvent() {
    const createEventMutation = useCreateEvent();

    const userInfo = useSelector(getUserInfo);

    const { data: marketing_sources, isLoading: market_source_loading } =
        useFetchEnableMarketingSource();
    const { data: event_types, isLoading: event_type_loading } =
        useFetchEnableEventTypes();
    const { data: event_locations, isLoading: event_location_loading } =
        useFetchEnableEventLocations();

    const { isLoading: advisors_loading, data: advisors } = useFetchPresenters();

    const initialValues = {
        name: "",
        eventDate: "",
        marketingSource: null,
        eventType: null,
        location: null,
        presenter: null,
        assignAll: false,
        marketingCost: 0,
        mailerCount: 0,
        mailerCost: 0,
        venueCost: 0
    };

    const validationSchema = Yup.object({
        name: Yup.string().nullable().required("Please Enter An Event Name"),
        eventDate: Yup.date().nullable().required("Please Select Event Date"),
        marketingSource: Yup.object().nullable().notRequired(),
        eventType: Yup.object().nullable().notRequired(),
        location: Yup.object().nullable().notRequired(),
        presenter: Yup.object().nullable().notRequired(),
        assignAll: Yup.boolean().default(false),
        marketingCost: Yup.number().nullable().notRequired().default(0),
        mailerCount: Yup.number().nullable().notRequired().default(0),
        mailerCost: Yup.number().nullable().notRequired().default(0),
        venueCost: Yup.number().nullable().notRequired().default(0),
    });

    const handleSubmit = (values) => {
        // console.log(values);
        values.firm = userInfo.firm;
        createEventMutation.mutate(values);
        // navigate(`/marketing/events`);
    };

    return (<React.Fragment>
        <div className="page-content">
            <PageHeader title="Add New Event" />
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values: any) => handleSubmit(values)}
            >
                <Form>
                    <Row>
                        <SmartInput
                            type="text"
                            name="name"
                            label="Event Name"
                            size="12"
                        />
                        <Col sm="12" md="6">
                            <SmartDatePicker name="eventDate" label="Event Date" />
                        </Col>
                        <Col sm="12" md="6">
                            <SmartCreatable
                                isDisabled={market_source_loading}
                                isLoading={market_source_loading}
                                isClearable={true}
                                options={marketing_sources}
                                label="Marketing Source"
                                name="marketingSource"
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <SmartCreatable
                                isDisabled={event_type_loading}
                                isLoading={event_type_loading}
                                isClearable={true}
                                options={event_types}
                                label="Event Type"
                                name="eventType"
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <SmartCreatable
                                isDisabled={event_location_loading}
                                isLoading={event_location_loading}
                                isClearable={true}
                                options={event_locations}
                                label="Event Location"
                                name="location"
                            />
                        </Col>
                        <SmartSelect
                            isDisabled={advisors_loading}
                            isLoading={advisors_loading}
                            isClearable={true}
                            options={advisors}
                            label="Presenter"
                            name="presenter"
                            optionLabelFn={(option) => option.fullName !== "null null" ? option.fullName : option.username}
                        />
                        <SmartCheck label="Assign all to Presenter" name="assignAll" />
                        {/* <SmartInput
                            type="number"
                            name="marketingCost"
                            label="Marketing Cost"
                            size="6"
                        /> */}
                        <SmartCurrency
                            name="mailerCost"
                            label="Mailer Cost"
                            size="6"
                        />
                        <SmartNumber
                            type="number"
                            name="mailerCount"
                            label="No. of Mailers"
                            size="6"
                        />                        
                        <SmartCurrency
                            name="venueCost"
                            label="Venue Cost"
                            size="6"
                        />
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <Button type="submit" color="success" className="save-user">
                                    {" "}
                                    <i className="bx bx-save"></i>
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </div>
    </React.Fragment>)
}

export default MarketCreateEvent;